import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Spin, Alert, message, Col, Row } from 'antd';
import { LockOutlined, CheckCircleTwoTone, MailOutlined } from '@ant-design/icons';
import { getAuth, checkActionCode, confirmPasswordReset, applyActionCode, signOut } from 'firebase/auth';
import zxcvbn from 'zxcvbn';
// import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthActions = () => {
  const [form] = Form.useForm();
  const auth = getAuth();
  const navigate = useNavigate();
  const [action, setAction] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [oobCode, setOobCode] = useState('');
  // const { user, currentUser, signOutOfFirebase} = useAuth();

  useEffect(() => {
    const handleSignOut = async () => {
      await axios.delete(process.env.REACT_APP_API_BASE_URL + `/api/v1/cookies`, { withCredentials: true });
      await signOut(auth);  
    };

    handleSignOut();
  }, [auth, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mode = urlParams.get('mode');
    const actionCode = urlParams.get('oobCode');

    // Determine what action to perform based on the mode.
    if (!mode || !actionCode) {
      setErrorMessage('The link is invalid or expired. Please try again.');
      setLoading(false);
      return;
    }

    setOobCode(actionCode);

    switch (mode) {
      case 'resetPassword':
        // Verify the password reset code is valid.
        checkActionCode(auth, actionCode)
          .then(() => {
            setAction('resetPassword');
          })
          .catch(() => {
            setErrorMessage('Invalid or expired password reset code.');
          });
        break;
      case 'verifyEmail':
      case 'recoverEmail':
        // Apply the email action code right away since there's no input from the user.
        applyActionCode(auth, actionCode)
          .then(() => {
            setAction(mode === 'verifyEmail' ? 'verifyEmail' : 'recoverEmail');
          })
          .catch(() => {
            setErrorMessage('Invalid or expired action code.');
          });
        break;
      default:
        setErrorMessage('This link is invalid for this action.');
        break;
    }

    setLoading(false);
  }, [auth]);

  const handleNavigate = () => {
    navigate('/sign_in');
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    getPasswordStrength(newPassword);
  };
  const getPasswordStrength = (newPassword) => {
    const strength = zxcvbn(newPassword);
    setPasswordStrength(strength);
  };

  const onFinish = () => {
    if (action === 'resetPassword' && oobCode) {
      confirmPasswordReset(auth, oobCode, form.getFieldValue('password'))
        .then(() => {
          message.success('Your password has been reset successfully.');
        })
        .catch(() => {
          setErrorMessage('Failed to reset password. Please try again.');
        });
    }
  };

  const MIN_SCORE = 4;
  const passwordIsNotStrongEnough = (passwordStrength && passwordStrength.score < MIN_SCORE)

  return (
    <>
      <Card title="Authentication Action" style={{ width: 400, margin: 'auto', marginTop: 50 }}>
        <Spin spinning={loading}>
          {errorMessage && <Alert message={errorMessage} type="error" />}
          {action === 'resetPassword' && (
            <Form form={form} onFinish={onFinish}>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your new password!' }]}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="New Password"
                  onChange={handlePasswordChange}
                />
              </Form.Item>
              {passwordStrength && (
                <div>
                  <p>Password strength: {passwordStrength.score} / 4</p>
                  <p>{passwordStrength.feedback.warning}</p>
                  {passwordStrength.feedback.suggestions.map((suggestion, index) => (
                    <p key={index}>{suggestion}</p>
                  ))}
                </div>
              )}
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading || passwordIsNotStrongEnough || !form.getFieldValue('password')}>
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          )}
          {action === 'verifyEmail' && (
            <Alert
              message="Your email has been verified successfully."
              type="success"
              showIcon
            />
          )}
          {action === 'recoverEmail' && (
            <Alert
              message="Your email address has been successfully reverted."
              type="success"
              showIcon
            />
          )}
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: 'center', paddingTop: 10 }}>
              <Button type="link" onClick={handleNavigate}>
                Go to Sign In
              </Button>
            </Col>
          </Row>
        </Spin>
      </Card>
      
    </>
  );
  // return (
  //   <Card title="Reset Your Password" style={{ maxWidth: 300, margin: "auto", marginTop: 50 }}>
  //     {isVerifying ? (
  //       <p>Verifying code...</p>
  //     ) : (
  //       <Form form={form} onFinish={onFinish}>
  //         <Form.Item
  //           name="password"
  //           rules={[{ required: true, message: 'Please input your new password!' }]}
  //         >
  //           <Input
  //             prefix={<LockOutlined className="site-form-item-icon" />}
  //             type="password"
  //             placeholder="New Password"
  //             value={password}
  //             onChange={handlePasswordChange}
  //           />
  //         </Form.Item>
  //         {passwordStrength && (
  //           <div>
  //             <p>Password strength: {passwordStrength.score} / 4
  //               {passwordStrength.score === 4 && <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} />}
  //             </p>
  //             {passwordStrength.feedback.warning && <p>{passwordStrength.feedback.warning}</p>}
  //             {passwordStrength.feedback.suggestions.length > 0 && (
  //               <ul>
  //                 {passwordStrength.feedback.suggestions.map((suggestion, index) => (
  //                   <li key={index}>{suggestion}</li>
  //                 ))}
  //               </ul>
  //             )}
  //           </div>
  //         )}
  //         <Form.Item>
  //           <Button type="primary" htmlType="submit" disabled={passwordStrength && passwordStrength.score < 3}>
  //             Reset Password
  //           </Button>
  //         </Form.Item>
  //       </Form>
  //     )}
  //   </Card>
  // );
};

export default AuthActions;
