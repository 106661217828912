import React from "react";
import { Progress, Table, Button, Space, Tag } from "antd";
import { LinkOutlined, ExportOutlined } from "@ant-design/icons";
import { stringToColor } from "./colorUtils";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Column } = Table;

const SuggestionsTable = ({ suggestions, onAdd }) => {
  const screens = useBreakpoint();

  const renderNameAndDomain = (text, record) => {
    const { name } = record;
    return (
      <Space>
        <Button type="link" onClick={() => onAdd(record.id)}>{name}</Button>
      </Space>
    );
  };

  const renderCategories = (categoryString) => {
    if (categoryString === null) {
      return null;
    }
    const categories = categoryString.split(",");
    return categories.map((category) => (
      <Tag key={category}>
        {category}
      </Tag>
    ));
  };

  return (
    <>
      { screens.md ? (
        <Table
          dataSource={suggestions}
          defaultSortOrder="descend" // Set the default sorting order to descend (highest likelihood first)
          defaultSortField="likelihood_of_subscription" // Set the default sorting field to "likelihood_of_subscription"
          sortDirections={["descend", "ascend"]}
          showSorterTooltip={false}
          rowKey="id"
        >
          <Column
            title="Service"
            dataIndex="name"
            key="name"
            render={renderNameAndDomain}
          />
          {/* <Column
            title="Likelihood"
            dataIndex="likelihood_of_subscription"
            key="likelihood_of_subscription"
            sorter={(a, b) =>
              a.likelihood_of_subscription - b.likelihood_of_subscription
            }
            sortDirections={["descend", "ascend"]}
            render={(probability) => <Progress percent={probability * 100} />}
          /> */}
          <Column
            title="Categories"
            dataIndex="category_of_subscription"
            key="category_of_subscription"
            render={renderCategories}
          />
          <Column
            title="Reason"
            dataIndex="reason"
            key="reason"
          />
          <Column
            title="Track"
            key="add"
            render={(text, record) => (
              <Button type="primary" onClick={() => onAdd(record.id)}>
                Track
              </Button>
            )}
          />
        </Table>
      ):(
        <Table
          dataSource={suggestions}
          defaultSortOrder="descend" // Set the default sorting order to descend (highest likelihood first)
          defaultSortField="likelihood_of_subscription" // Set the default sorting field to "likelihood_of_subscription"
          sortDirections={["descend", "ascend"]}
          showSorterTooltip={false}
          rowKey="id"
        >
          <Column
            title="Service"
            dataIndex="name"
            key="name"
            render = {(text, record) => {
              const { name, category_of_subscription } = record;
              if (category_of_subscription === null) {
                return null;
              }
              const categories = category_of_subscription.split(",");

              return (
                <>
                  <Button type="link" onClick={() => onAdd(record.id)}>{name}</Button>
                  { categories.map((category) => (
                      <Tag key={category}>
                        {category}
                      </Tag>
                    ))
                  }
                </>
              );
            }}
          />
          <Column
            title="Track"
            key="add"
            render={(text, record) => (
              <Button type="primary" onClick={() => onAdd(record.id)}>
                Track
              </Button>
            )}
          />
        </Table>
      )}
    </>
  );
};

export default SuggestionsTable;
