import React, { useState, useEffect } from "react";
import {
  Table,
  Switch,
  Row,
  Col,
  Button,
  Card,
  Timeline,
  Statistic,
  Space,
  Progress,
  Tag,
  Tooltip,
  Select,
  Input,
  message,
  Skeleton,
  Typography,
  Empty,
} from "antd";
import { Link } from "react-router-dom";
import {
  EyeInvisibleOutlined,
  CheckOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
  MessageOutlined,
  UpCircleTwoTone,
  DownCircleTwoTone,
  PauseCircleOutlined,
  PlayCircleOutlined,
  PauseCircleTwoTone,
  PlayCircleTwoTone,
  BugOutlined,
} from "@ant-design/icons";
import TimeSince from "./TimeSince";
import { stringToColor } from "./colorUtils";
import axios from 'axios';
import ConfirmSwitch from "./ConfirmSwitch";
import Notes from "./Notes";
import SubsDetailBillingRelatedContent from "./SubsDetailBillingRelatedContent";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Countdown } = Statistic;
const { Column } = Table;
const { Option } = Select;
const { Text, Title } = Typography;
const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Dayjs is also OK

const SubscriptionsOverviewTable = ({
  subscriptions,
  transactions,
  onToggle,
  onRemove,
  onRowExpand,
  users,
  onAssignUser,
  hideCardNameColumn,
}) => {
  const screens = useBreakpoint();
  const [filteredCategory, setFilteredCategory] = React.useState(null);
  const [subsSearch, setSubsSearch] = useState('');
  const [filteredSubscriptions, setFilteredSubscriptions] = useState(subscriptions);

  useEffect(() => {
    if (subsSearch) {
      setFilteredSubscriptions(
        subscriptions.filter(sub =>
          sub.name.toLowerCase().includes(subsSearch.toLowerCase())
        )
      );
    } else {
      setFilteredSubscriptions(subscriptions);
    }
  }, [subsSearch, subscriptions]);

  const paginationConfig = {
    pageSize: 100,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    // Other pagination options go here
    // ...
  };
  
  const formatLink = (link) => {
    if (link === null || link === undefined) {
      return ''; // or some default value
    }
    
    if (link.startsWith("http://") || link.startsWith("https://")) {
      return new URL(link).origin;
    } else if (link.startsWith("www.")) {
      return new URL(`https://${link}`).origin;
    } else {
      return link;
    }
  };

  const renderAssignUser = (text, record) => {
    const handleChange = (value) => {
      if (value === "") {
        value = null;
      }
      onAssignUser(record, value);
    };
  
    return (
      <Select 
        defaultValue={record.user?.name} 
        style={{ width: 120 }} 
        onChange={handleChange}
        placeholder=""
      >
        <Option key="unset" value="">
        </Option>
        {users.map(user => (
          <Option key={user.id} value={user.id}>
            {user.name || user.preferred_name || user.email}
          </Option>
        ))}
      </Select>
    );
  };

  const renderCategories = (categoryString) => {
    if (categoryString === null) {
      return null;
    }
    const categories = categoryString.split(",");
    return categories.map((category) => (
      <Tag key={category}>
        {category}
      </Tag>
    ));
  };

  const renderNameAndDomain = (text, record) => {
    const { name, domain } = record;
    return (
      <Space>
        <Text>{name}</Text>
      </Space>
    );
  };

  const renderPaymentControl = (text, record) => {
    // Check if the card is programmable
    if (record.card.is_programmable !== true) {
      // If the card is not programmable, show a tooltip on a disabled text
      return (
        <Tooltip
          title={
            <p>
              Blocking future card payments is only available for programmable cards.
              <a href="/account/organization/cardholders"> Go to Cardholders</a>
            </p>
          }
        >
          <p style={{ cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.25)' }}>
            Payments cannot be paused or enabled.
          </p>
        </Tooltip>
      );
    } else {
      // If the card is programmable, show the ConfirmSwitch with appropriate label
      return (
        <ConfirmSwitch record={record} onToggle={onToggle} />
      );
    }
  };
  

  const renderPaymentControlStatus = (text, record) => {
    return(
    <Tooltip
      title={ 
        record.card.is_programmable !== true ? (
          <p>
            Blocking future card payments is only available for programmable cards.
            <a href="/account/organization/cardholders"> Go to Cardholders</a>
          </p>
        ) : (
          record.active 
            ? "Click to block future payments to this service. You're in control."
            : "You are blocking future card payments to this service. Please note that you are still required to cancel the subscription agreement with the provider directly."
        )
      }
    >
      { 
        (!record.active) ? (
          <Tag icon={<PauseCircleOutlined />} color="red">
            Paused
          </Tag>
        ) : ( 
          <Tag icon={<PlayCircleOutlined />} color="green">
            Active
          </Tag>
        )
      }
    </Tooltip>
  )};

  const handleFeedbackClick = async (record) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/feedbacks`, {
        feedback: {
          message: `User Reported Issue: Subscription ID ${record.subscription_id}, Business ID ${record.id}`,
          rating: -1,
        }
      }, {
        withCredentials: true,
      });
  
      if (response.status === 201) {
        message.success("Thank you for your feedback.");
      } else {
        message.error("Failed to submit feedback.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      message.error("An error occurred while submitting feedback.");
    }
  };

  const manipulateRecordToFit = (record) => {
    return record;
  }

  return (
    <>
      
      { screens.md ? (
        <>
          <div style={{ alignSelf: "center", width: "40%" }}>
            <Input
              placeholder="Search"
              value={subsSearch}
              onChange={(e) => setSubsSearch(e.target.value)}
            />
          </div>
          <br />
          <Table
            dataSource={filteredSubscriptions}
            pagination={paginationConfig} 
            rowKey="subscription_id"
            rowClassName={(record) =>
              record.active === false ? "inactive-subscription" : ""
            }
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpCircleTwoTone onClick={e => onExpand(record, e)} style={{ fontSize: '18px' }} />
              ) : (
                <DownCircleTwoTone onClick={e => onExpand(record, e)} style={{ fontSize: '18px' }} />
              ),
              expandedRowRender: (record) => {
                return record.transactions && record.transactions.length >= 0 ? (
                  <>
                    <Row>
                      <Col className="gutter-row" span={13}>
                        <Card>
                          <Row>
                            <Col span={8}>
                              <Statistic
                                title="Number of payments"
                                value={record.metadata.count}
                              />
                            </Col>
                            <Col span={8}>
                              <Statistic
                                title="Total Spend"
                                value={record.metadata.total}
                              />
                            </Col>
                            <Col span={8}>
                              <Statistic
                                title="Average Monthly Spend"
                                value={record.metadata.average_monthly_spend}
                              />
                            </Col>
                          </Row>
                          <Row>
                            {/* <TimeSince date={new Date(record.transactions[0].transaction_date)} /> */}
                            <Statistic
                              title="Estimated Frequency"
                              value={
                                record.metadata.frequency
                                  ? `${record.metadata.frequency} days`
                                  : "Unknown"
                              }
                            />
                          </Row>
                          <Row>
                          <Col span={12}>
                              <Statistic
                                title="Last payment date"
                                value={record.metadata.last_payment_date || "Not yet billed"}
                              />
                            </Col>
                            <Col span={12}>
                              <Countdown
                                title="Estimated time until next payment run"
                                value={
                                  new Date(record.metadata.estimated_next_payment_date)
                                }
                                format="D . HH . mm . ss"
                              />
                            </Col>
                          </Row>
                        </Card>
                        <Card>
                          { record.transactions.length == 0 ? (
                              <div style={{ textAlign: 'center', padding: '20px' }}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
                                <Text type="secondary" style={{ fontSize: '16px', marginTop: '16px' }}>
                                  Your card transactions will appear here when you're billed
                                </Text>
                              </div>
                            ):(
                              <Timeline
                                mode="left"
                                items={record.transactions.map((transaction) => ({
                                  children: `${transaction.transaction_date} : ${transaction.amount_formatted} : ${transaction.description}`,
                                }))}
                              />
                            ) 
                          }
                          <div style={{ textAlign: "right", marginTop: "10px" }}>
                            <Button
                              type="link"
                              onClick={() => handleFeedbackClick(record)}
                              icon={<BugOutlined />}
                              size="small"
                            >
                              Report issue with the listed transactions
                            </Button>
                          </div>
                        </Card>
                      </Col>
                      <Col className="gutter-row" span={11}>
                        <Card>
                          <div style={{ display: 'flex', alignItems: 'center' }}> {/* Use flexbox for side-by-side layout */}
                            <div style={{ flex: 1, paddingRight: '20px', borderRight: '1px solid #ccc' }}> {/* Right border as vertical line */}
                              {renderPaymentControl("", record)}
                            </div>
                            <div style={{ flex: 1, paddingLeft: '20px' }}>
                              <Tooltip title="You can always start tracking this again">
                                <Button
                                  type="dashed" // Change to default to allow for outline style
                                  danger
                                  icon={<EyeInvisibleOutlined />}
                                  onClick={() => onRemove(record)}
                                  style={{ borderColor: 'red', color: 'red' }} // Red outline for the hide button
                                >
                                  Hide from tracker
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                          <SubsDetailBillingRelatedContent record={manipulateRecordToFit(record)} selectedCard={record.card} />
                        </Card>
                        <Notes subscriptionId={record.subscription_id} />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Skeleton active paragraph={{ rows: 4 }} />
                );
              },
              onExpand: onRowExpand,
            }}
          >
            <Column
              title={
                <Tooltip title="To cancel your subscription, navigate to the service provider from here">
                  <span>Service </span>
                  <QuestionCircleOutlined />
                </Tooltip>
              }
              dataIndex="name"
              key="name"
              sorter={(a, b) => a.name.localeCompare(b.name)}
              render={renderNameAndDomain}
            />
            {/* <Column
              title={
                <Tooltip title="from (unlikely) 0% to 100% (definitely) that this is a subscription on your card">
                  <span>Likelihood </span>
                  <QuestionCircleOutlined />
                </Tooltip>
              }
              key="likelihood"
              render={(text, record) =>
                renderLikelihood(record.likelihood_of_subscription)
              }
            /> */}
            <Column
              title={
                <Tooltip title="Tags we've applied to view the types of services you're subscribed to">
                  <span>Categories </span>
                  <QuestionCircleOutlined />
                </Tooltip>
              }
              dataIndex="category_of_subscription"
              key="category_of_subscription"
              filters={Array.from(new Set(
                Array.isArray(subscriptions) 
                ? subscriptions.flatMap(record => record.category_of_subscription.split(',')) 
                : []))
                .map(category => ({
                  text: category,
                  value: category,
                }))
              }      
              onFilter={(value, record) => record.category_of_subscription.split(',').includes(value)}
              render={renderCategories}
            />
            <Column
              title={
                <Tooltip title="Block or allow future payments to the provider. You're in control.">
                  <span>Payment Status </span>
                  <QuestionCircleOutlined />
                </Tooltip>
              }
              key="paymentControl"
              filters={[{text: "Paused", value: 1}, {text: "Active", value: 0}]}
              onFilter={(value, record) => record.active !== Boolean(value) }
              render={renderPaymentControlStatus}
            />
            <Column
              title="Name on Card"
              key="card_name"
              dataIndex="card_name"
              sorter={(a, b) => a.card_name.localeCompare(b.card_name)}
              filters={Array.from(new Set(subscriptions.map(record => record.card_name))).map(card_name => ({
                text: card_name,
                value: card_name,
              }))}
              onFilter={(value, record) => record.card_name === value}      
              render={(text, record) => (
                <Link to={`/subscriptions/${record.card_id}`}><span>{text}</span></Link>
              )}
              {...(hideCardNameColumn ? { className: 'hidden-column' } : {})}
            />
            <Column
              title="Teams"
              key="teams"
              dataIndex="teams"
              filters={Array.from(new Set(
                Array.isArray(subscriptions)
                ? subscriptions.flatMap(record => record.teams.map(team => team.name))
                : []))
                .map(teamName => ({
                  text: teamName,
                  value: teamName,
                }))
              }
              onFilter={(value, record) => record.teams.some(team => team.name === value)}      
              render={(teams, record) => (
                <>
                  {teams.map((team) => {
                    return (
                      <Tag
                        key={team.id}
                      >
                        {team.name}
                      </Tag>
                    );
                  })}
                </>
              )}
            />
      
            <Column
              title={
                <Tooltip title="If someone other than the cardholder manages this recurring spend, assign them so that they can manage the items.">
                  <span>Assign Owner </span>
                  <QuestionCircleOutlined />
                </Tooltip>
              }
              key="assignUser"
              render={renderAssignUser}
            />
            {/* <Column
              title=""
              key="remove"
              render={(text, record) => (
                <Tooltip title="Don't worry, you can always start tracking this again">
                  <Button type="link" danger onClick={() => onRemove(record)}>
                    Remove from tracker
                  </Button>
                </Tooltip>
              )}
            /> */}
          </Table>
        </>
        ) : (
          <>
            <div style={{ alignSelf: "center", width: "100%" }}>
              <Input
                placeholder="Search"
                value={subsSearch}
                onChange={(e) => setSubsSearch(e.target.value)}
              />
            </div>
            <br /> 
            <Table
              dataSource={filteredSubscriptions}
              pagination={paginationConfig} 
              rowKey="subscription_id"
              rowClassName={(record) =>
                record.active === false ? "inactive-subscription" : ""
              }
              expandable={{
                expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpCircleTwoTone onClick={e => onExpand(record, e)} style={{ fontSize: '18px' }} />
                ) : (
                  <DownCircleTwoTone onClick={e => onExpand(record, e)} style={{ fontSize: '18px' }} />
                ),
                expandedRowRender: (record) => {
                  return record.transactions && record.transactions.length >= 0 ? (
                    <>
                      <Row>
                        <Col className="gutter-row" span={24}>
                          <Card>
                            <Row>
                              <Col span={8}>
                                <Statistic
                                  title="Number of payments"
                                  value={record.metadata.count}
                                />
                              </Col>
                              <Col span={16}>
                                <Statistic
                                  title="Average Monthly Spend"
                                  value={record.metadata.average_monthly_spend}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={20}>
                                <Statistic
                                  title="Last payment date"
                                  value={record.metadata.last_payment_date || "Not yet billed"}
                                />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                        <Col className="gutter-row" span={24}>
                          <Card>
                            <div>
                              <Row>
                                {record.card.is_programmable ? (
                                  <ConfirmSwitch record={record} onToggle={onToggle} />
                                ):(
                                  <p style={{ cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.25)' }}>
                                    Payments cannot be paused or enabled.
                                  </p>
                                )}
                              </Row>
                              <Row>
                                <Button
                                  type="dashed" // Change to default to allow for outline style
                                  danger
                                  icon={<EyeInvisibleOutlined />}
                                  onClick={() => onRemove(record)}
                                  style={{ borderColor: 'red', color: 'red' }} // Red outline for the hide button
                                >
                                  Hide from tracker
                                </Button>
                              </Row>
                            </div>
                            <SubsDetailBillingRelatedContent record={manipulateRecordToFit(record)} selectedCard={record.card} />
                          </Card>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Skeleton active paragraph={{ rows: 4 }} />
                  );
                },
                onExpand: onRowExpand,
              }}
            >
              <Column
                title={
                  <span>Service </span>
                }
                dataIndex="name"
                key="name"
                render={(text, record) => {
                  const { name, domain } = record;
                  return (
                    <div>
                      {(!record.active) ? (
                        <PauseCircleTwoTone twoToneColor="red"/>
                      ) : ( 
                        <PlayCircleTwoTone twoToneColor="#52c41a"/>
                      )}
                      <Text> {name}</Text>
                    </div>
                  );
                }}
              />
            </Table>
          </>
        )
      }
    </>
  );
};

export default SubscriptionsOverviewTable;
