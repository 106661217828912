import React, { useEffect, useState } from "react";
import axios from "axios";
import SubscriptionsTable from "./components/SubscriptionsTable";
import SuggestionsTable from "./components/SuggestionsTable";
import Autocomplete from "./components/Autocomplete";
import { AutoComplete, Space, Typography } from "antd";
import { Row, Form, Input, Table, Button, Select, message, Tag, Skeleton, Card } from "antd";
import {
  MonitorOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  SettingOutlined,
  NotificationOutlined,
  SearchOutlined,
  // CloseCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import { useAuth } from "./AuthProvider";
import { useParams } from "react-router-dom";
import EventsTable from "./components/EventsTable";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Title, Text } = Typography;

function Subscriptions({
  hideTransactions = false,
  hideSearch = false,
  hideSuggestions = false,
  hideCardNameColumn = true,
}) {
  const screens = useBreakpoint();
  const [data, setData] = useState(null);
  const [cards, setCards] = useState([]);
  const [teams, setTeams] = useState([]);
  // const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [assignedCardholder, setAssignedCardholder] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [cardTransactionSearch, setCardTransactionSearch] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [businessDetails, setBusinessDetails] = useState("");
  const [tags, setTags] = useState("");
  const [loadCardTransactions, setLoadCardTransactions] = useState(false);
  const { user, currentUser, logout } = useAuth();
  const { card_id } = useParams();
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [subscriptionsFetched, setSubscriptionsFetched] = useState(false);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const exportToCSV = (csvData, fileName) => {
    const csvRows = [];
    const headers = Object.keys(csvData[0]);
    csvRows.push(headers.join(","));

    for (const row of csvData) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = async () => {
    // Here, you can handle your submission logic. For example, you can send selectedRows to the server.
    try {
      const safeBusinessDetails = businessDetails || {};
      const safeTags = tags || [];

      await axios.post(
        process.env.REACT_APP_API_BASE_URL +
          `/api/v1/cards/${selectedCard.id}/business_classifications`,
        {
          transactions: selectedRows,
          business_details: businessDetails,
          tags: tags,
        },
        { withCredentials: true }
      );
      message.success("Transactions submitted successfully.");
    } catch (error) {
      console.error(error);
      message.error("Unable to submit your transactions at this time.");
    }
  };

  const fetchSubscriptions = async (cardId) => {
    // if (subscriptionsFetched) return;
    setLoadingSubs(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL +
          `/api/v1/cards/${cardId}/subscriptions`,
        { withCredentials: true }
      );
      setData(response.data);
      setSubscriptionsFetched(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSubs(false);
    }
  };

  const [cardTransactions, setCardTransactions] = useState([]);

  const fetchTransactions = async (expanded, record) => {
    if (expanded) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${selectedCard.id}/transactions?business_id=${record.id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        setTransactions((prev) => ({
          ...prev,
          [record.id]: response.data.transactions,
        }));

        record["transactions"] = response.data.transactions;
        record["metadata"] = response.data.metadata;
      } catch (error) {
        message.error(
          "An error occured while finding transactions. Please try again later"
        );
      }
    }
  };

  const handleDeleteSubscription = async (record) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${selectedCard.id}/subscriptions/${record.subscription_id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      message.success("Subscription removed");
      // Update the data with the updated subscription
      setData((prevData) => {
        const updatedSubscriptions = prevData.subscriptions.filter(
          (subscription) =>
            subscription.subscription_id !== record.subscription_id
        );

        return {
          ...prevData,
          subscriptions: updatedSubscriptions,
        };
      });

      // Redirect to the login page or any other desired page after account deletion
    } catch (error) {
      console.error(error);
      message.error(
        "Failed to remove subscription tracking. Please try again later."
      );
    }
  };

  const handleToggleSubscription = async (record, active) => {
    
    const subscriptionId = record.subscription_id;
    // console.log(`Toggle subscription ${subscriptionId}`);

    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_BASE_URL +
          `/api/v1/cards/${selectedCard.id}/subscriptions/toggle`,
        {
          business_id: `${record.id}`, //handle strangeness in JS.
          active: active ? 0 : 1,
        },
        { withCredentials: true }
      );

      // Update the data with the updated subscription
      setData((prevData) => {
        const updatedSubscriptions = prevData.subscriptions.map(
          (subscription) => {
            if (subscription.subscription_id === subscriptionId) {
              subscription.active
                ? message.success(
                    "Card payments to " +
                      subscription.name +
                      " will be blocked. If you would like to cancel your subscription agreement, please contact the provider directly"
                  )
                : message.success(
                    "Card payments to " + subscription.name + " are active"
                  );
              return {
                ...subscription,
                active: !subscription.active,
              };
            }
            return subscription;
          }
        );

        const updatedSuggestions = prevData.suggestions.filter(
          (suggestion) => suggestion.id !== subscriptionId
        );

        return {
          ...prevData,
          subscriptions: updatedSubscriptions,
          suggestions: updatedSuggestions,
        };
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleAssignUser = async (subscriptionId, userId) => {
    try {
      // Make API call to update the subscription
      const response = await axios.patch(
        process.env.REACT_APP_API_BASE_URL +
          `/api/v1/cards/${selectedCard.id}/subscriptions/${subscriptionId}`,
        {
          user_id: userId,
        },
        { withCredentials: true }
      );

      message.success("Subscription updated successfully");
    } catch (error) {
      message.error("Error updating subscription");
    }
  };

  const handleAddSuggestion = async (id) => {
    // console.log(`Add suggestion ${id}`);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL +
          `/api/v1/cards/${selectedCard.id}/subscriptions`,
        { business_id: id },
        { withCredentials: true }
      );
      message.success("Subscription added");

      // Update the data state to include the newly added suggestion in the subscriptions array
      setData((prevData) => {
        const updatedSubscriptions = [...prevData.subscriptions, response.data];
        return {
          ...prevData,
          suggestions: prevData.suggestions.filter(
            (suggestion) => suggestion.id !== id
          ),
          subscriptions: updatedSubscriptions,
        };
      });
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  useEffect(() => {
    if (currentUser && currentUser.organization && currentUser.organization.id) {
      // setSubscriptionsFetched(false);
      // setData([]);
      const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members`;
      const teamsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`;
      const cardsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards`;
  
      Promise.all([
        axios.get(membersUrl, { withCredentials: true }),
        axios.get(teamsUrl, { withCredentials: true }),
        axios.get(cardsUrl, { withCredentials: true }),
      ])
      .then(([membersResponse, teamsResponse, cardsResponse]) => {
        const updatedCards = cardsResponse.data.cards.map((card) => {
          const matchingMember = membersResponse.data.members.find(
            (member) => member.id === card.user_id
          );
          return matchingMember ? { ...card, member: matchingMember } : card;
        });
  
        setMembers(membersResponse.data.members);
        setTeams(teamsResponse.data.teams);
        setCards(updatedCards);
  
        const specificCard = updatedCards.find((card) => card.id === Number(card_id));
        if (card_id && specificCard) {
          const assignedMember = membersResponse.data.members.find(
            (member) => member.id === specificCard.user_id
          );
          setSelectedCard(specificCard);
          setAssignedCardholder(assignedMember);

          fetchSubscriptions(specificCard.id);
        }
      })
      .catch((error) => {
        message.error("Failed to fetch data.");
      });
    }
  }, [currentUser, card_id]);

  useEffect(() => {
    // Example: When cardId changes, allow re-fetching of subscriptions
    // setSubscriptionsFetched(false);
    // setData([]);
    if (selectedCard && !subscriptionsFetched) {
      fetchSubscriptions(selectedCard.id);
    }
  }, [card_id]);

  useEffect(() => {
    // Fetch transactions for the selected card
    if (selectedCard && loadCardTransactions) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            `/api/v1/cards/${selectedCard.id}/transactions`,
          { withCredentials: true }
        )
        .then((response) => {
          setCardTransactions(response.data.transactions);
          // setFilteredTransactions(response.data.transactions);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
        });
    }
  }, [selectedCard, loadCardTransactions]);

  const filteredCardTransactions = cardTransactionSearch
    ? cardTransactions.filter((transaction) =>
        transaction.description
          .toLowerCase()
          .includes(cardTransactionSearch.toLowerCase())
      )
    : cardTransactions;

  const monitoredCards = cards.filter((card) => card.monitoring_active);

  const onDropdownChange = (value) => {
    // console.log(`selected ${value}`);
    const cardId = value;
    const selected = cards.find((card) => card.id === Number(cardId));
    setSelectedCard(selected);
  };

  const onDropdownSearch = (value) => {
    console.log("search:", value);
  };

  const getInitials = (name) => {
    const names = name.split(" ");
    return names
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  };

  if (error) {
    return <>An error occurred: {error}</>;
  }

  return (
    <>
      { screens.md ? (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Title level={2}>Subscription Tracking
            { selectedCard && ` for ${selectedCard.embossed_name}`
            }
          </Title>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "50px",
              }}
            >
              <div
                style={{
                  maxWidth: "400px",
                  padding: "20px",
                  backgroundColor: "#f8f8f8",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                }}
              >
                <div>
                  {selectedCard && (
                    <div>
                      {selectedCard &&
                      selectedCard.user_id !== null &&
                      assignedCardholder !== null ? (
                        <div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div
                              style={{
                                width: "32px",
                                height: "32px",
                                borderRadius: "50%",
                                marginRight: "8px",
                                backgroundColor: "black",
                                color: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {assignedCardholder &&
                              (assignedCardholder.preferred_photo_url || assignedCardholder.photo_url) ? (
                                <img
                                  src={
                                    assignedCardholder.preferred_photo_url ||
                                    assignedCardholder.photo_url
                                  }
                                  alt="Member Avatar"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                  onError={(e) => {
                                    e.target.style.display = "none"; // Hide the broken image
                                  }}
                                />
                              ) : (
                                getInitials(
                                  assignedCardholder.preferred_name ||
                                    assignedCardholder.name ||
                                    assignedCardholder.email
                                )
                              )}
                            </div>
                            <div>
                              <div>{assignedCardholder.preferred_name || assignedCardholder.name}</div>
                              <div style={{ fontSize: "smaller" }}>{assignedCardholder.email}</div>
                            </div>
                          </div>

                          <div style={{ marginTop: "8px" }}>
                            {assignedCardholder.teams.map((team) => (
                              !team.is_default ? ( <Tag key={team.id} color="blue">
                                  {team.name}
                                </Tag>
                              ) : <></>
                            ))}
                            { assignedCardholder.role === "admin" ? (
                              <Tag color="pink">
                                Admin
                              </Tag>
                            ):<></>}
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            <Link
                              to={"/account/organization/cardholders"}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "smaller" // Smaller font size
                              }}
                            >
                              <SettingOutlined style={{ marginRight: 8 }} />
                              Go to Card Settings
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <p>
                              Selected Card: {selectedCard.embossed_name} -{" "}
                              {selectedCard.card_number.slice(-4)}
                            </p>
                          </div>
                          <div style={{ marginTop: "8px" }}>
                            <Link
                              to={"/account/organization/cardholders"}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "smaller" // Smaller font size
                              }}
                            >
                              <SettingOutlined style={{ marginRight: 8 }} />
                              Go to Card Settings
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> 
      ):(
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Title level={2}>{ selectedCard && `${selectedCard.embossed_name}` }</Title>
        </div>
      )}

      {/*   <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a card"
          optionFilterProp="children"
          onChange={onDropdownChange}
          onSearch={onDropdownSearch}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          value={selectedCard ? selectedCard.id : "Select a card"}
          options={monitoredCards.map((card) => ({
            value: card.id,
            label: `${card.embossed_name} - ${card.card_number.slice(-4)}`,
          }))}
        />
      </div> */}
      {loadingSubs ? (
      // Render Skeleton while loading
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : data ? (
        <>        
          <h2>Your tracked subscriptions</h2>
          <SubscriptionsTable
            subscriptions={data.subscriptions}
            onToggle={handleToggleSubscription}
            onRemove={handleDeleteSubscription}
            onRowExpand={fetchTransactions}
            users={members}
            onAssignUser={handleAssignUser}
            selectedCard={selectedCard}
            hideCardNameColumn={hideCardNameColumn}
          />
          
          {!hideSuggestions && (
            <>
              <h3>✨ Suggested items to track</h3>
              <Text>Click <strong>'Track'</strong> to add these recurring payments to your tracker. They'll keep pulling from your card payment history.</Text>
              <SuggestionsTable
                suggestions={data.suggestions}
                onAdd={handleAddSuggestion}
              />
            </>
          )}

          {!hideSearch && (
            <Card style={{ padding: '24px', textAlign: 'center', backgroundColor: '#f0f2f5', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
              <h3 style={{ marginBottom: '8px', color: '#1890ff' }}>
                <SearchOutlined />
                &nbsp; Find and Track subscriptions before you're charged for them
              </h3>
              <Text type="secondary" style={{ display: 'block', marginBottom: '20px' }}>Useful for free trials that require a card at signup</Text>
              <Autocomplete
                onAdd={handleAddSuggestion}
                style={{ width: '100%' }}
                placeholder="Type to search for services..."
                autoFocus
              />
            </Card>
          
          )}
          {!hideSearch && (
            <Card>
              <h3><NotificationOutlined />&nbsp; Activity and Notifications</h3>
              <Text type="secondary" style={{ display: 'block', marginBottom: '20px' }}>Recent activity related to your card</Text>
              <EventsTable 
                actionType='show'
                cardId={card_id}
              />
            </Card>
          )}

          {!hideSuggestions && screens.md && (
            <>
              <h2>View your card transactions</h2>
              <div>
                <Button
                  type="primary"
                  onClick={() => setLoadCardTransactions(!loadCardTransactions)}
                  style={{ marginBottom: "10px" }}
                >
                  {loadCardTransactions ? "Hide" : "Load"} Transactions
                </Button>
              </div>
              {loadCardTransactions && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div style={{ alignSelf: "center", width: "70%" }}>
                      <Input
                        placeholder="Search transactions"
                        value={cardTransactionSearch}
                        onChange={(e) => setCardTransactionSearch(e.target.value)}
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>
                      <Button
                        type="primary"
                        onClick={() =>
                          exportToCSV(filteredCardTransactions, "transactions.csv")
                        }
                      >
                        Export to CSV
                      </Button>
                    </div>
                  </div>

                  <div>
                    <Table
                      rowKey="id"
                      dataSource={filteredCardTransactions}
                      // rowSelection={{
                      //   onChange: onSelectChange,
                      // }}
                      columns={[
                        {
                          title: "Description",
                          dataIndex: "description",
                          key: "description",
                        },
                        {
                          title: "Transaction Type",
                          dataIndex: "transaction_type",
                          key: "transaction_type",
                        },
                        {
                          title: "Card Number",
                          dataIndex: "card_number",
                          key: "card_number",
                        },
                        {
                          title: "Transaction Date",
                          dataIndex: "transaction_date",
                          key: "transaction_date",
                        },
                        {
                          title: "Amount",
                          dataIndex: "amount_formatted",
                          key: "amount_formatted",
                        },
                      ]}
                    />
                  </div>
                  {/* {selectedRows.length > 0 ? (
                    <Form layout="inline">
                      <Form.Item>
                        <Input
                          value={businessDetails}
                          onChange={(e) => setBusinessDetails(e.target.value)}
                          placeholder="Name this selection"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Input
                          value={tags}
                          key="tags"
                          onChange={(e) => setTags(e.target.value)}
                          placeholder="Tags"
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button type="primary" onClick={handleSubmit}>
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  ) : (
                    <Text>
                      Please select a card and transactions to start classifying
                      them into categories
                    </Text>
                  )} */}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>No Subscriptions Available</h2>
          <p>
            You currently don't have any subscriptions available for monitoring.
            Once you add subscriptions to your cards, they will appear here for
            management.
          </p>
          <Link to="/subscriptions">
            <Button type="primary">Go to All Recurring expenses</Button>
          </Link>
        </div>
      )}
    </>
  );
}

export default Subscriptions;
