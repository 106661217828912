import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Divider, Form, Input, List, Collapse } from "antd";
import { Space, Typography } from "antd";
import { notification, message } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SecurityScanOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import InvestecOAuthButton from "../InvestecOAuthButton";
const { Text, Link, Title } = Typography;

const CredentialsForm = ({ userId }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [formLayout, setFormLayout] = useState("horizontal");
  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } }
      : null;
      

  const buttonItemLayout =
    formLayout === "horizontal"
      ? { wrapperCol: { span: 14, offset: 4 } }
      : null;

  
  const fetchData = async () => {
    setFetchLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/credentials/check",
        { withCredentials: true }
      );
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setFetchLoading(false);
    }
  };
  
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/credentials', values, { withCredentials: true });
      // console.log(response.data);
      await fetchData();  // Rerun the check after successfully sending the credentials
      if(data && data.connection) {
        notification.success({
          message: 'Success',
          description: 'Your credentials have been successfully updated and connected.',
          duration: 3,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'There was an error updating your credentials. Please try again.',
          duration: 3,
        });  
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: 'There was an error updating your credentials. Please try again.',
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  if (error) {
    return <>An error occurred: {error}</>;
  }

  return (
    <>
      <Space>
        <Text>Connection Status: </Text>
        {data && !fetchLoading && !loading ? (
          data.connection ? (
            <><CheckCircleOutlined style={{ color: "green" }} /><Text type="success">Successfully connected!</Text></>
          ) : (
            <CloseCircleOutlined style={{ color: "red" }} />
          )
        ) : (
          <LoadingOutlined style={{ color: "blue" }} />
        )}
      </Space>
      <div>
        <div>
          <Text>Securely connect your Investec account to Subtrack</Text>
        </div>
        {userId ? <InvestecOAuthButton userId={userId} /> : <></>}
      </div>
      <Divider />
      <div style={{ marginTop: '16px' }}>
        <Collapse accordion items={[
            {
              key: '1',
              label: 'Developer? Manually enter your programmable banking API Credentials to connect to Investec',
              children: <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                initialValues={{ layout: formLayout }}
                style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
                onFinish={handleSubmit}
              >
                <Form.Item label="Client Id" name="client_id" rules={[{ required: true, message: 'Can\'t be blank' }]} >
                  <Input.Password placeholder="1q***************************M0" />
                </Form.Item>
                <Form.Item label="Client Secret" name="client_secret" rules={[{ required: true, message: 'Can\'t be blank' }]} >
                  <Input.Password placeholder="20************gZ" />
                </Form.Item>
                <Form.Item label="API key" name="client_api_key" rules={[{ required: true, message: 'Can\'t be blank' }]} >
                  <Input.Password placeholder="Qm*****...*****AP==" />
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                  <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
              </Form>,
            }
          ]}
        />
      </div>
    </>
  );
}

export default CredentialsForm;
