import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Space, Button, Popconfirm, message, Input, Modal, Form, Collapse, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';


const { Title, Paragraph } = Typography;
const { Panel } = Collapse;


const ManageApiKeys = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [newKeyName, setNewKeyName] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Expiry',
      dataIndex: 'expires_at',
      key: 'expires_at',
    },
    {
      title: 'Last Used',
      dataIndex: 'last_used',
      key: 'last_used',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure to delete this API Key?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const fetchApiKeys = useCallback( async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/api_keys`, { withCredentials: true });
      setApiKeys(response.data);
    } catch (error) {
      console.error('Error fetching API keys:', error);
    }
  }, []);

  useEffect(() => {
    fetchApiKeys();
  }, [fetchApiKeys]);

  const handleDelete = async (key) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/v1/api_keys/${key}`, { withCredentials: true });
      message.success('API Key deleted');
      fetchApiKeys(); // update the list
    } catch (error) {
      console.error('Error deleting API key:', error);
    }
  };

  const createNewKey = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + "/api/v1/api_keys", {
        name: newKeyName,
      },{ withCredentials: true });
      message.success('New API Key created');
      setModalContent(response.data);
      setIsModalVisible(true);
      fetchApiKeys();
    } catch (error) {
      console.error('Error creating API key:', error);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setModalContent(''); // clear the modal content
  };

  return (
    <>
      <Title level={2}>API Keys</Title>
      <p>Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them.</p>
      <p>Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, Subtrack may also automatically rotate any API key.</p>
      <Form layout="inline">
        <Form.Item>
            <Input
              value={newKeyName}
              onChange={(e) => setNewKeyName(e.target.value)}
              placeholder="Name for new API Key"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={createNewKey}>
              Create new Secret Key
            </Button>
          </Form.Item>
        </Form>
      <Table columns={columns} dataSource={apiKeys} rowKey="key" />
      <Modal title="New API Key" visible={isModalVisible} onOk={handleModalOk} onCancel={handleModalOk}>
        {
          modalContent &&
          <div>
            <p><strong>Name:</strong> {modalContent.name}</p>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={{margin: 0}}><strong>API Key:</strong> {modalContent.key}</p>
                <CopyToClipboard text={modalContent.key} onCopy={() => setIsCopied(true)}>
                    <Tooltip title={isCopied ? "Copied!" : "Copy to clipboard"}>
                        <button style={{marginLeft: '10px', border: 'none', background: 'none'}}>
                            <CopyOutlined />
                        </button>
                    </Tooltip>
                </CopyToClipboard>
            </div>
            <p><strong>Created At:</strong> {modalContent.created_at}</p>
            <p><strong>Expires At:</strong> {modalContent.expires_at}</p>
            <p><strong>Last Used:</strong> {modalContent.last_used || 'Not yet used'}</p>
          </div>
        }
      </Modal>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="How to use the API (Technical)" key="2">
          <Typography>
            <Title level={4}>POST /api/v1/classify</Title>
            <Paragraph>
              This endpoint allows you to classify transactions. It expects a list of transactions in the request body. Each transaction should include an `index`, `description`, `transaction_type`, `transaction_date`, and `amount`.
            </Paragraph>
            <Title level={4}>Example</Title>
            <SyntaxHighlighter language="bash" style={solarizedlight}>
              {`curl -X POST "https://api.subtrack.co.za/api/v1/classify" \\
              -H "Content-Type: application/json" \\
              -H "Authorization: Token token=<your_token>" \\
              -d '{
                "transactions": [
                    {
                        "index": 1,
                        "description": "TWITTER PAID FEATURES HTTPSWWW.TWIT US",
                        "transaction_type": "debit",
                        "transaction_date": "2023-07-02",
                        "amount": -100
                    },
                    {
                        "index": 2,
                        "description": "TYPEFORM, S.L. BARCELONA ES",
                        "transaction_type": "debit",
                        "transaction_date": "2023-07-05",
                        "amount": -200
                    }
                ]
              }' | jq`}
            </SyntaxHighlighter>
            <Paragraph>
              On success, you'll receive a JSON response with the `message` field indicating that transactions have been processed and a `result` field containing the processed transactions with added information about matched businesses.
              <br />
              On failure, you'll receive a JSON response with an `error` field indicating the error message.
            </Paragraph>
            <Title level={4}>Example Response</Title>
            <SyntaxHighlighter language="json" style={solarizedlight}>
              {`{
                "message": "Transactions processed",
                "result": [
                  {
                    "index": 1,
                    "description": "TWITTER PAID FEATURES HTTPSWWW.TWIT US",
                    "amount": -100,
                    "transaction_type": "debit",
                    "transaction_date": "2023-07-02",
                    "business": {
                      "id": 37,
                      "name": "Twitter",
                      "likelihood_of_subscription": "1.0",
                      "category_of_subscription": "Social Media,Paid Features",
                      "domain": "www.twitter.com",
                      "reason": "Twitter offers premium features on a subscription basis."
                    }
                  },
                  {
                    "index": 2,
                    "description": "TYPEFORM, S.L. BARCELONA ES",
                    "amount": -200,
                    "transaction_type": "debit",
                    "transaction_date": "2023-07-05",
                    "business": {
                      "id": 38,
                      "name": "Typeform S.L.",
                      "likelihood_of_subscription": "1.0",
                      "category_of_subscription": "Survey,Data Collection",
                      "domain": "www.typeform.com",
                      "reason": "Typeform S.L. is an online form builder and survey tool, typically offered on a subscription basis."
                    }
                  }
                ]
              }`}
            </SyntaxHighlighter>
          </Typography>
        </Panel>
      </Collapse>
    </>
  );
};

export default ManageApiKeys;
