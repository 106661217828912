import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Tag,
  Button,
  Switch,
  Select,
  Input,
  Icon,
  Tooltip,
  message,
  Typography,
  Modal,
  Tour,
  Row, Col,
} from "antd";
import {
  ArrowRightOutlined,
  SearchOutlined,
  EditOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import MemberAssignmentModal from "./components/MemberAssignmentModal";
import { useAuth } from "./AuthProvider";
import axios from "axios";
import { Link } from 'react-router-dom';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Option } = Select;
const { Title } = Typography;

const ManageCards = () => {
  const { user, currentUser, logout } = useAuth();
  const [cards, setCards] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isMemberAssignmentModalVisible, setMemberAssignmentModalVisible] =
    useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const [newTeamName, setNewTeamName] = useState("");
  const [searchedMember, setSearchedMember] = useState(null);
  const [members, setMembers] = useState([]);
  const [editedCardExpiry, setEditedCardExpiry] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(false);
  const screens = useBreakpoint();

  // >>TOUR
  const refRefresh = useRef(null);
  const refMMYY = useRef(null);
  const refReports = useRef(null);
  const refNotifications = useRef(null);
  const refAssignMember = useRef(null);
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([
    {
      title: 'Manually Refresh Cards',
      description: 'Click here to manually refresh cards from the bank.',
      target: () => refRefresh.current,
    },
    {
      title: 'Add Expiry Date',
      description: 'Add or edit the MM/YY expiry date here.',
      target: () => refMMYY.current,
    },
    {
      title: 'Toggle Real-time Notifications',
      description: 'Enable or disable real-time notifications.',
      target: () => refNotifications.current,
    },
    {
      title: 'Show in Reports',
      description: 'Toggle whether to show this card in reports.',
      target: () => refReports.current,
    },
    {
      title: 'Assign/Invite Member',
      description: 'Assign a cardholder to this card.',
      target: () => refAssignMember.current,
    },
  ]);
  // <<TOUR

  useEffect(() => {
    if (
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members`;
      const teamsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`;
      const cardsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards`;

      Promise.all([
        axios.get(membersUrl, { withCredentials: true }),
        axios.get(teamsUrl, { withCredentials: true }),
        axios.get(cardsUrl, { withCredentials: true }),
      ])
        .then(([membersResponse, teamsResponse, cardsResponse]) => {
          setMembers(membersResponse.data.members);
          setTeams(teamsResponse.data.teams);
          setCards(cardsResponse.data.cards);
        })
        .catch((error) => {
          console.log("error: Failed to fetch data.");
        });
    } else {
      // message.warning('currentUser or organization is not defined.');
    }
  }, [currentUser]);

  const paginationConfig = {
    pageSize: 50, // Number of records per page
    hideOnSinglePage: true, // Hide pagination when there's only one page
  };

  const getInitials = (name) => {
    const names = name.split(' ');
    return names.map((name) => name[0]).join('').toUpperCase();
  };

  const toggleProgrammability = async (cardId, shouldBeProgrammable) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId
          ? { ...card, is_programmable: shouldBeProgrammable }
          : card
      )
    );

    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${cardId}/programmability`,
        { should_be_programmable: shouldBeProgrammable }, 
        { withCredentials: true }
      );

      if (response.status === 200) {
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.id === cardId
              ? { ...card, is_programmable: response.data.is_programmable }
              : card
          )
        );
      }
    } catch (error) {
      // console.error(error);
      let errorMessage = 'Failed to update programmable features on card. Please try again later.';
      // If the server responded with a message, use that, otherwise use the default message.
      if (error.response && error.response.status === 422 && error.response.data.errors) {
        errorMessage = error.response.data.errors.join(', ') || errorMessage;
      }
      message.error(errorMessage);
      // Revert the change in UI if the request fails
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.id === cardId
            ? { ...card, is_programmable: !shouldBeProgrammable }
            : card
        )
      );
    }
  };

  const handleInputChange = (e) => {
    // Automatically insert the forward slash (/) after entering the month
    let inputValue = e.target.value;
  
    // Extract month and year from the input
    const [month, year] = inputValue.split("/");
  
    // Validate month and year
    const isValidMonth = /^(0[1-9]|1[0-2])$/.test(month);
    const isValidYear = /^(2[3-9]|3[0-9]|40)$/.test(year);
  
    if (isValidMonth && isValidYear) {
      // Check if the month is not higher than 12
      const monthValue = parseInt(month, 10);
      if (monthValue <= 12) {
        // Concatenate month and year with a forward slash (/)
        inputValue = inputValue.length <= 5 ? inputValue : inputValue.slice(0, 5);
      }
    } else if (inputValue.length === 2) {
      if ( isValidMonth ) {
        // Check if the month is 2 digits in length and in the range of 01 to 12
        const monthValue = parseInt(month, 10);
        if (monthValue >= 1 && monthValue <= 12 && month.length === 2) {
          // Automatically add the forward slash (/) even if the year is not valid
          inputValue = inputValue + "/";
        }
      } else {
        inputValue = ""
      } 
    } else if (inputValue.length === 5) {
      if ( isValidYear ) {

      } else {
        inputValue = `${month}/`
      }
    }
  
    // Update the state with the formatted input value
    setEditedCardExpiry(inputValue);
  };  

  let columns = [];
  if(screens.md) {
    columns = [
      {
        title: "Card No. & Expiry Date",
        dataIndex: "card_number",
        key: "card_number",
        render: (cardNumber, record, index) => {
          // Extract the expiry date from the record (assuming it's in the format "YY/MM")
          const { id } = record;
          const expiryDate = record.expiry_date || "MM/YY";
          const handleSave = async () => {
            // Validate the edited expiry date here (YY/MM format)
            // You can add validation logic and display an error message if needed
            // Call the handleCardEdit function to save the edited expiry date
            handleCardEdit(id, { expiryDate: editedCardExpiry });
            setEditedCardExpiry(null);
            setEditingRecord(null);
          };
          const isEditing = editingRecord === id;

          // Calculate the number of months remaining until the expiry date
          const currentDate = new Date();
          const expiryParts = expiryDate.split("/");
          const expiryYear = parseInt("20" + expiryParts[1], 10);
          const expiryMonth = parseInt(expiryParts[0], 10) - 1; // Months are 0-based
          const expiry = new Date(expiryYear, expiryMonth);

          const monthsRemaining = Math.floor(
            (expiry - currentDate) / (30 * 24 * 60 * 60 * 1000)
          );

          // Determine the warning tag color based on the remaining months
          let statusColor = "";
          let statusText = "";

          if (record.status === "Active") {
            statusColor = "green";
            statusText = "Active";
          } else if (record.status === "SoftBlock") {
            statusColor = "red";
            statusText = "Soft Blocked";
          } else {
            statusColor = "grey";
            statusText = record.status;
          }

          // Determine the warning tag color based on the remaining months
          let warningColor = "";
          let warningText = "";

          if (monthsRemaining < -1) {
            warningColor = "grey"; // Expired
            warningText = "Expired";
          } else if (monthsRemaining <= 1) {
            warningColor = "red"; // Expiring within 1 month
            warningText = "Expiring within 1 month";
          } else if (monthsRemaining <= 3) {
            warningColor = "yellow"; // Expiring within 3 months
            warningText = "Expiring soon";
          }

          return (
            <div ref={index === 0 ? refMMYY : null}>
              <span style={{ fontFamily: "monospace" }}>
                {cardNumber.substring(0, 4)}&nbsp;****&nbsp;****&nbsp;
                {cardNumber.substring(12)}
              </span>
              <br />
              {isEditing ? (
                <div>
                  <Input
                    value={editedCardExpiry}
                    // onChange={(e) => setEditedCardExpiry(e.target.value)}
                    onChange={handleInputChange}
                    style={{ width: "100px" }}
                    placeholder="MM/YY"
                  />
                  <Button type="primary" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              ) : (
                <>
                  <span>
                    {expiryDate}
                    
                  </span>
                  {
                    currentUser.role === "admin" || currentUser.id === record.user_id ? (
                      <span>
                        <>
                          <EditOutlined
                            onClick={() => setEditingRecord(id)}
                            style={{ cursor: "pointer", marginLeft: "8px" }}
                          />
                        </>
                        <>
                          {warningColor && (
                            <Tag color={warningColor} style={{ marginLeft: "8px" }}>
                              {warningText}
                            </Tag>
                          )}
                          {statusColor && (
                            <Tag color={statusColor} style={{ marginLeft: "8px" }}>
                              {statusText}
                            </Tag>
                          )}
                        </>
                      </span>
                    ) : (
                      <>
                        {warningColor && (
                          <Tag color={warningColor} style={{ marginLeft: "8px" }}>
                            {warningText}
                          </Tag>
                        )}
                        {statusColor && (
                          <Tag color={statusColor} style={{ marginLeft: "8px" }}>
                            {statusText}
                          </Tag>
                        )}
                      </>
                    )
                  }
                </>
              )}
            </div>
          );
        },
        sorter: (a, b) => {
          // Convert expiry dates to Date objects for comparison, handling null values
          const aExpiry = a.expiry_date ? new Date(`20${a.expiryDate}`) : null;
          const bExpiry = b.expiry_date ? new Date(`20${b.expiryDate}`) : null;

          // Handle cases where either aExpiry or bExpiry is null
          if (aExpiry === null && bExpiry === null) {
            return 0; // Both dates are null, consider them equal
          } else if (aExpiry === null) {
            return 1; // aExpiry is null, so bExpiry comes first
          } else if (bExpiry === null) {
            return -1; // bExpiry is null, so aExpiry comes first
          }

          // Both dates are valid, perform the comparison
          return aExpiry - bExpiry;
        },
      },
      {
        title: (
          <span>
            Realtime Notifications and Payment Pausing
            {(
              <Tooltip
                title={
                  <>
                    To enable more features like payment control and real-time alerts,
                    please enable card programmability.
                  </>
                }
              >
                <InfoCircleOutlined style={{ marginLeft: '8px', color: 'grey' }} />
              </Tooltip>
            )}
          </span>
        ),
        key: "card_programmability",
        render: (record, _, index) => (
          <span ref={index === 0 ? refNotifications : null}>
            <Switch
              checked={record.is_programmable}
              onChange={() => {
                if (record.is_programmable) {
                  Modal.confirm({
                    title: 'Disable programmability?',
                    content: 'Are you sure you want to disable programmable card features? Payment Control and transaction notifications will no longer work.',
                    onOk: () => toggleProgrammability(record.id, !record.is_programmable),
                  });
                } else {
                  toggleProgrammability(record.id, !record.is_programmable);
                }
              }}
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
            />
          </span>
        ),
        filters: [
          { text: "Enabled", value: true },
          { text: "Disabled", value: null },
        ],
        onFilter: (value, record) => record.is_programmable === value,
      },
      {
        title: "Show in Reports",
        dataIndex: "monitoring_active",
        key: "monitoring_active",
        filters: [
          { text: "Showing", value: true },
          { text: "Hidden", value: false },
        ],
        onFilter: (value, record) => {
          // If value is false, treat null as equivalent to false
          if (value === false) {
            return record.monitoring_active === value;
          }
          // Otherwise, use strict equality
          return record.monitoring_active === value;
        },      
        render: (showInReport, record, index) => (
          <Switch
            ref={index === 0 ? refReports : null}
            checked={record.monitoring_active}
            onChange={(checked) => {
              handleToggleMonitoring(record.id, checked);
            }}
          />
        ),
      },
      {
        title: "Embossed Name",
        dataIndex: "embossed_name",
        key: "embossed_name",
        sorter: (a, b) => a.embossed_name.localeCompare(b.embossed_name),
        filters: cards.map((c) => ({
          text: c.embossed_name,
          value: c.embossed_name,
        })),
        onFilter: (value, record) => record.embossed_name === value,
        render: (text, record) => (
          <Link to={`/subscriptions/${record.id}`}>
            {text}
          </Link>
        ),
      },
      {
        title: "Assigned Member Details",
        key: "assigned_member_details",
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder="Search by name, email, or team"
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => confirm()}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters()}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        ),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            const searchInput = document.getElementById("search-input");
            if (searchInput) {
              setTimeout(() => searchInput.select(), 100);
            }
          }
        },
        render: (record) => {
          const assignedMember = members.find(
            (member) => member.id === record.user_id
          );
          if (assignedMember) {
            return (
              <div>
                <div>
                  <span>
                    <div
                      style={{
                        width: "32px", // Set the desired width
                        height: "32px", // Set the desired height
                        borderRadius: "50%", // Make it a circular avatar
                        marginRight: "8px", // Add some spacing to the right
                        float: "left", // Float the avatar to the left
                        backgroundColor: "black", // Set the background color to black
                        color: "white", // Set text color to white
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {assignedMember.preferred_photo_url || assignedMember.photo_url ? (
                        <img
                          src={assignedMember.preferred_photo_url || assignedMember.photo_url}
                          alt="Member Avatar"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          onError={(e) => {
                            e.target.style.display = "none"; // Hide the broken image
                          }}
                        />
                      ) : (
                        getInitials(
                          assignedMember.preferred_name || assignedMember.name || assignedMember.email
                        )
                      )}
                    </div>
                    {assignedMember.preferred_name || assignedMember.name }
                    {(currentUser && currentUser.role === "admin") && (<CloseCircleOutlined
                      onClick={() => assignMemberToCard(record.id, null)} // Add the unassign click handler
                      style={{ cursor: "pointer", color: "red", float: "right" }}
                    />)}
                  </span>
                </div>
                <div>{assignedMember.email}</div>
                <div>
                  {assignedMember.teams.map((team) => (
                    <Tag key={team.id} color="blue">
                      {team.name}
                    </Tag>
                  ))}
                </div>
              </div>
            );
          }
          return null; // Return null if no assigned member found
        },
        onFilter: (value, record) => {
          const assignedMember = members.find(
            (member) => member.id === record.user_id
          );
          if (assignedMember) {
            const searchValue = value.toLowerCase();
            const memberName = (
              assignedMember.name || assignedMember.preferred_name
            ).toLowerCase();
            const memberEmail = assignedMember.email.toLowerCase();
            const teamNames = assignedMember.teams.map((team) =>
              team.name.toLowerCase()
            );

            return (
              memberName.includes(searchValue) ||
              memberEmail.includes(searchValue) ||
              teamNames.some((teamName) => teamName.includes(searchValue))
            );
          }
          return false;
        },
      },
      {
        title: "Assign/Invite Member",
        key: "assign_invite_member",
        render: (record, _, index) => (
          <div ref={index === 0 ? refAssignMember : null}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Search and Select Member or Invite New"
              value={searchedMember}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              disabled={(currentUser && currentUser.role !== "admin")}
              onChange={(value, options) => {
                if (value === "invite") {
                  // Handle invite logic here
                  setMemberAssignmentModalVisible(true);
                  setSelectedMember(null); // No selected member for invite
                } else {
                  // Handle assignment logic here
                  const assignedMember = members.find(
                    (member) => member.id === +options.key
                  );
                  if (assignedMember) {
                    // Update the card's holder, name, preferred_name, email, and teams fields
                    const updatedCards = cards.map((card) => {
                      if (card.id === record.id) {
                        // Assuming 'id' is the unique identifier for cards
                        return {
                          ...card,
                          user_id: assignedMember.id,
                        };
                      }
                      return card;
                    });
                    setCards(updatedCards);
                    assignMemberToCard(record.id, assignedMember.id);
                  }
                }
              }}
            >
              {members.map((member) => (
                <Option key={member.id} value={member.name ||  member.email}>
                  {member.name || member.email}
                </Option>
              ))}
              <Option value="invite">Invite New Member</Option>
            </Select>
            {searchedMember === "invite" && (
              <Button
                type="default"
                onClick={() => {
                  setMemberAssignmentModalVisible(true);
                  setSelectedMember(null); // No selected member for invite
                }}
              >
                Invite New Member
              </Button>
            )}
          </div>
        ),
      },
    ];
  } else {
    columns = [
      
      {
        title: "Card Details",
        dataIndex: "embossed_name",
        key: "embossed_name",
        render: (text, record) => (
          <>
            <Link to={`/subscriptions/${record.id}`}>
              {text}
            </Link>
            <div>
              <span style={{ fontFamily: "monospace" }}>
                {record.card_number.substring(0, 4)}&nbsp;****&nbsp;****&nbsp;
                {record.card_number.substring(12)}
              </span>
            </div>
          </>
        ),
      },
    ];
  }

  const fetchCards = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards`,
        { withCredentials: true }
      );
      setCards(response.data.cards);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardEdit = async (cardId, options = {}) => {
    try {
      const { expiryDate, _otherField } = options; // Destructure optional parameters

      const payload = {
        card: {
          ...(expiryDate && { expiry_date: expiryDate }),
        },
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${cardId}`,
        payload,
        { withCredentials: true }
      );

      // Handle the API response as needed
      if (response.status === 200) {
        // Update the local state or perform any necessary actions
        // ...

        // Fetch updated card data (optional)
        fetchCards();
      } else {
        // Handle API error
        // ...
      }
    } catch (error) {
      console.error(error);
    }
  };

  const assignMemberToCard = (cardId, memberId) => {
    const payload = {
      card: {
        user_id: memberId,
        role: "cardholder"
      },
    };

    axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${cardId}`,
        payload, { withCredentials: true }
      )
      .then((response) => {
        // fetchCards();
        const updatedCards = cards.map((card) => {
          if (card.id === cardId) {
            // Update the card's holder and any other relevant fields
            return {
              ...card,
              user_id: memberId, // Assign the member to the card
              // Update other fields as needed
            };
          }
          return card;
        });

        // Update the local variable (cards)
        setCards(updatedCards);
      })
      .catch((error) => {
        // Handle errors
      });
  };

  const handleToggleMonitoring = async (cardId, monitoring_active) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === cardId
          ? { ...card, monitoring_active: monitoring_active }
          : card
      )
    );

    setTimeout(async () => {
      try {
        const response = await axios.patch(
          process.env.REACT_APP_API_BASE_URL + `/api/v1/cards/${cardId}/toggle`,
          {},
          { withCredentials: true }
        );

        // console.log(response.data);

        // Update the cards state based on the API response
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.id === cardId
              ? { ...card, monitoring_active: response.data.monitoring_active }
              : card
          )
        );
      } catch (error) {
        console.error(error);
      }
    }, 0);
  };

  const refreshCardsFromBank = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/cards/refresh",
        null,
        { withCredentials: true }
      );
      // console.log(response.data.cards);
      // Refresh the cards by assigning the returned data list
      message.success("Cards data refreshed from bank.");
      setCards(response.data.cards);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col flex="auto">
          <Title level={2}>Manage your Cards and Cardholders</Title>
        </Col>
        {screens.md && (
          <Col>
            <Button 
              type="text" 
              onClick={() => setOpen(true)} 
              style={{ zIndex: 1000 }}
            >
              <em>Learn how to use this page</em>
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]} justify="start">
        <Col>
          <Button type="primary" onClick={refreshCardsFromBank} ref={refRefresh}>
            Refresh Cards from Bank
          </Button>
        </Col>
      </Row>
      <br></br>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            dataSource={cards}
            columns={columns}
            defaultSortOrder="ascend"
            rowKey="id"
            pagination={paginationConfig}
          />
        </Col>
      </Row>
      <MemberAssignmentModal
        currentUser={currentUser}
        visible={isMemberAssignmentModalVisible}
        onCancel={() => setMemberAssignmentModalVisible(false)}
        onMemberAssigned={() => {
          fetchCards();
          setMemberAssignmentModalVisible(false);
        }}
      />
      {screens.md && (
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          steps={steps}
        />
      )}
    </div>
  );
};

export default ManageCards;
