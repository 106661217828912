import React, { useEffect, useState, createContext, useContext, useRef } from "react";
import { getAuth, onIdTokenChanged, signOut } from "@firebase/auth";
import axios from "axios";
import { BrowserRouter as Router, useNavigate, useLocation } from "react-router-dom";
import { message } from "antd";

export const AuthContext = createContext();

export const useUser = () => {
  const { user } = useAuth(); // assuming user info is stored here
  return user;
};
export const useCurrentUser = () => {
  
  const { currentUser } = useAuth(); // assuming user info is stored here
  return currentUser;
};
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [currentUser, setStaticUserData] = useState(null);
  const timerRef = useRef(null);
  const location = useLocation();

  const logout = async () => {
    try {
      await axios.delete(process.env.REACT_APP_API_BASE_URL + `/api/v1/logout`, { withCredentials: true });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Received 401 response during logout. Proceeding...");
        // Proceed with clearing state and navigating to the sign-in page
      } else {
        console.error("An unexpected error occurred:", error);
        // Optionally handle other types of errors
      }
    }
    await signOut(auth);
    setIsAuthenticated(false);
    setUser(null);
    setStaticUserData(null);
    navigate("/sign_in");
  };

  const signOutOfFirebase = async () => {
    await signOut(auth);
    setIsAuthenticated(false);
    setUser(null);
    setStaticUserData(null);
    navigate("/sign_in");
  };

  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear existing timer
    }
    timerRef.current = setTimeout(() => {
      logout(); // Trigger logout function after 30 minutes of inactivity
    }, 1800000); // 30 minutes in milliseconds
  };

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        if(location.pathname === '/sign_in' || location.pathname === '/sign_up' || location.pathname === '/subscriptions') {
          // debugger;
          const token = await user.getIdToken(true);
          try {
            const response = await axios.post(
              process.env.REACT_APP_API_BASE_URL + `/api/v1/login`,
              {  },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                withCredentials: true
              }
            );
            const csrfToken = response.data.csrf_token;
            axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
            // message.success("Successfully signed in.");
          } catch (error) {
            console.error(error);
            // message.error("Authentication error.");
          }
        }

        setIsAuthenticated(true);
        setUser(user);
        if (currentUser === null){
          try {
            const the_current_user = await axios.get(
              process.env.REACT_APP_API_BASE_URL + `/api/v1/current_user`,
              { withCredentials: true }
            );
            setStaticUserData(the_current_user.data.user)
          } catch (error) {
            console.error(error);
          }
        }
        
      } else if (user && !user.emailVerified) {
        setIsAuthenticated(true);
        setUser(user);

        navigate("/verify-email");
      } 
      else {
        setIsAuthenticated(false);
        setUser(null);
      }
    }, error => {
      console.error(error);
    });

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keydown", resetTimer);

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error.response.status)
        if (error.response && error.response.status === 401) {
          console.log(error.response.status)
          navigate("/sign_in");
        }
        return Promise.reject(error);
      }
    );

    resetTimer(); 

    return () => {
      unsubscribe();
      axios.interceptors.response.eject(interceptor);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, [auth, navigate]);

  return (
    <AuthContext.Provider value={{isAuthenticated, user, currentUser, setStaticUserData, logout, signOutOfFirebase}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
