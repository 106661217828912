import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Pagination, Select, Typography, message } from "antd";
import { useAuth } from "./AuthProvider";
import moment from 'moment';

const { Option } = Select;
const { Title } = Typography;

const AuditLogs = () => {
  const [members, setMembers] = useState([]);
  const [data, setData] = useState([]);
const [page, setPage] = useState(1);
const [total, setTotal] = useState(0);
const [userFilter, setUserFilter] = useState(null);
const { user, currentUser, logout } = useAuth();

useEffect(() => {
  if (
    currentUser &&
    currentUser.organization &&
    currentUser.organization.id
  ) {
    const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members`;
    const auditLogsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/audit_logs`;

    Promise.all([
      axios.get(membersUrl, { withCredentials: true }),
      axios.get(auditLogsUrl, {
        withCredentials: true,
        params: { page, actioned_by: userFilter }
      }),
    ])
      .then(([membersResponse, auditLogsResponse]) => {
        setMembers(membersResponse.data.members);
        setData(auditLogsResponse.data.logs);
        setTotal(auditLogsResponse.data.total);
      })
      .catch((error) => {
        message.error("Failed to fetch data.");
      });
  } else {
    // message.warning('currentUser or organization is not defined.');
  }
}, [currentUser, page, userFilter]);

const columns = [
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Actioned By",
    dataIndex: "actioned_by",
    key: "user",
    render: (actioned_by) => actioned_by,  // Assuming the user object has a name field
  },
  {
    title: "Created At",
    dataIndex: "created_at",
    key: "created_at",
    render: (date) => moment(date).format('MMMM Do YYYY, h:mm:ss a'),
    sorter: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    defaultSortOrder: 'descend',
  }
];

  return (
    <>
      <Title level={1}>Audit Logs</Title>

      <Select
        placeholder="Filter by User"
        onChange={(value) => setUserFilter(value)}
        allowClear
      >
        {members.map((member) => (
          <Option key={member.email} value={member.email}>
            {member.email}
          </Option>
        ))}
      </Select>

      <Table
        dataSource={data}
        columns={columns}
      />

      <Pagination
        current={page}
        total={total}
        pageSize={50}
        onChange={(newPage) => setPage(newPage)}
      />
    </>
  );
};

export default AuditLogs;
