import React, { useState, useEffect } from "react";
import { Button, Card, Checkbox, Divider, Form, Input, Row, Col , message} from "antd";
import { CheckCircleOutlined, LockOutlined, UserOutlined, GoogleOutlined, WindowsOutlined } from "@ant-design/icons";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  OAuthProvider,
} from "firebase/auth";
import { Typography } from "antd";
import zxcvbn from 'zxcvbn';
import { useNavigate } from 'react-router-dom';

const MIN_SCORE = 4;

function formatErrorCode(code) {
  let humanReadableMessage;
  switch(code) {
    case 'auth/wrong-password':
      humanReadableMessage = 'The username or password you entered is incorrect. Or did you mean to Sign In instead?';
      break;
    case 'auth/web-storage-unsupported':
      humanReadableMessage = 'You cannot authenticate in Private Browsing or Incognito mode.';
      break;
    case 'auth/account-exists-with-different-credential':
      humanReadableMessage = 'Please use the provider that you originally signed up with (Google/Microsoft/Email+Password).';
      break;
    case 'auth/email-already-in-use':
      humanReadableMessage = 'The username or password you entered is incorrect. Or did you mean to Sign In instead?';
      break;
    case 'auth/weak-password':
      humanReadableMessage = 'Please create a stronger password.';
      break;
    default:
      humanReadableMessage = 'An unknown error occurred. Please try again.';
  }

  return humanReadableMessage;
}

const { Link } = Typography;

const SignUp = () => {
  const auth = getAuth();
  const [showEmailPasswordFields, setShowEmailPasswordFields] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // This function checks if there's a redirect result available
    // when the component mounts. This would occur if a user is coming back
    // from the Google sign-in page.
    const checkRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result && result.user) {
          // console.log('Signed up with:' , result.user.providerData[0].providerId);
          message.success("Signing up with " + result.user.providerData[0].providerId);
          navigate('/subscriptions');
        }
      } catch (error) {
        // console.log('Error with sign up:', error.message);
        message.error(formatErrorCode(error.code));
      }
    };    

    checkRedirectResult();
  }, [auth]);

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    const strength = zxcvbn(newPassword);
    setPasswordStrength(strength);
  };

  const onFinish = async (values) => {
    if (passwordStrength && passwordStrength.score < MIN_SCORE) {
      message.error('Your password is not strong enough. Please try a stronger password.');
      return;
    }

    const { email, password } = values;
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/subscriptions');
      // user signed in
    } catch (error) {
      // handle error
      message.error(formatErrorCode(error.code));
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithRedirect(auth, provider);
      // user signed in with Google
    } catch (error) {
      // handle error
      console.log(error.message);
    }
  };

  const handleMicrosoftSignUp = async () => {
    const provider = new OAuthProvider('microsoft.com');
    provider.addScope('email');
    provider.addScope('User.Read');
    provider.addScope('profile');
    provider.setCustomParameters({
      'prompt': 'consent',
      'tenant': 'common',
    });
    
    try {
      await signInWithRedirect(auth, provider);
    } catch (error) {
      console.log("Error with Microsoft sign up:", error.message);
    }
  };

  const handleSignUpWithEmailClick = () => {
    setShowEmailPasswordFields(true);
  };

  const isSignUpDisabled = (passwordStrength && passwordStrength.score < MIN_SCORE);

  return (
    <Row 
      justify="center" // This ensures the form is centered
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      style={{ marginTop: '5%', marginBottom: '5%' }} // Optional: Add some margin to the top and bottom for better layout
    >
      <Col 
        xs={{ span: 22 }}  // On extra small screens, it takes up almost the full width
        sm={{ span: 20 }}  // On small screens, it takes up most of the width
        md={{ span: 18 }}  // On medium screens, slightly less
        lg={{ span: 12 }}  // On large screens, half the width
        xl={{ span: 8 }}   // On extra-large screens, reduce further
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ maxWidth: '500px', margin: '0 auto' }} // Optional: Limit max width for very large screens and center it
        >
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Button 
                type="default"
                onClick={handleGoogleSignUp} 
                className="login-form-button" 
                style={{ width: '100%', marginBottom: '10px' }}
              >
                <GoogleOutlined /> Sign Up with Google
              </Button>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Button 
                type="default"
                onClick={handleMicrosoftSignUp} 
                className="login-form-button" 
                style={{ width: '100%', marginBottom: '10px' }}
              >
                <WindowsOutlined />Sign Up with Microsoft
              </Button>
            </Col>
          </Row>
          {/* <Divider orientation="centre" plain>or</Divider>
          {showEmailPasswordFields && (
            <Card>
              <Form.Item
                name="email"
                rules={[
                  { required: true, type: "email", message: "Please input a valid email!" },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  onChange={handlePasswordChange}
                />
              </Form.Item>
              {passwordStrength && (
                <div>
                  <p>
                    Password strength: {passwordStrength.score} / 4
                    {passwordStrength.score === 4 && <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} />}
                  </p>
                  <p>{passwordStrength.feedback.warning}</p>
                  {passwordStrength.feedback.suggestions.map((suggestion, index) => (
                    <p key={index}>{suggestion}</p>
                  ))}
                </div>
              )}
              <Form.Item
                name="privacy"
                valuePropName="checked"  
                wrapperCol={{ offset: 0, span: 24 }}
                rules={[
                  { 
                    validator: (_, value) =>
                      value ? Promise.resolve() : Promise.reject(new Error('Please accept to continue')),
                  },
                ]}
              >
                <Checkbox>
                  I accept the{" "}
                  <Link href="/privacy" target="_blank">
                    Privacy Policy
                  </Link>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  disabled={isSignUpDisabled || isSignUpDisabled === null}
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Card> 
          )}

          {!showEmailPasswordFields && (
            <Button
              onClick={handleSignUpWithEmailClick}
              style={{ width: '100%', marginBottom: '10px' }}
            >
              Sign Up with Email
            </Button>
          )}
          */}
        </Form>
      </Col>
    </Row>
  );
};

export default SignUp;
