import React, { useState, useCallback } from 'react';
import { InputNumber, message } from 'antd';
import debounce from 'lodash/debounce';
import axios from 'axios';

// Example custom input component for billing frequency
const SubsDetailNumericInput = ({ initialValue, subscriptionId, cardId, fieldName, options, label}) => {
  const [value, setValue] = useState(initialValue);

  const handleSave = async (newValue) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${cardId}/subscriptions/${subscriptionId}`,
        { [fieldName]: newValue },
        { withCredentials: true }
      );
      message.success("Subscription updated successfully");
    } catch (error) {
      message.error("Error updating subscription");
    }
  };

  // Debounce the save function
  const debouncedSave = useCallback(debounce(handleSave, 1000), []);

  const handleChange = (newValue) => {
    setValue(newValue);
    debouncedSave(newValue);
  };

  return (
    <div>
      <InputNumber
        value={value || undefined}
        placeholder={label}
        onChange={handleChange}
        style={{ width: '100%' }} // Ensure the input takes the full width
      />
    </div>
  );
};

export default SubsDetailNumericInput;
