import firebase from "firebase/app";
import "firebase/auth";
import { sendEmailVerification } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Modal, Button, message, Typography } from "antd";
import { useAuth } from "../AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";

const { Link } = Typography;

const EmailVerificationModal = ({ visible, setVisible }) => {
  // const [visible, setVisible] = React.useState(false);
  const { user, signOutOfFirebase} = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    // Check if email is unverified
    if (user && !user.emailVerified) {
      setVisible(true);
    }
  }, [user]);

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(user);
      message.success({
        content: "Verification email sent successfully!",
      });
    } catch (error) {
      message.error({
        content: error.message,
      });
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const handleSignOut = async () => {
    await signOutOfFirebase();
    setVisible(false);
  };

  return (
    <Modal
      title="Email Verification"
      open={visible}
      footer={[
        <Button type="link" key="reload" onClick={reloadPage}>
          I've verified, reload page
        </Button>,
        <Button type="primary" key="send" onClick={sendVerificationEmail}>
          Send verification email
        </Button>,
      ]}
      closable={false}
      maskClosable={false}
    >
      <p>
        Your email is not verified. Please <strong>verify it to continue</strong> or {' '}
        <Link onClick={handleSignOut}>
          sign out.
        </Link>
      </p>
    </Modal>
  );
};

export default EmailVerificationModal;
