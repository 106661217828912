import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Space, Typography, Select, Table,message} from "antd";
import { useAuth } from "./AuthProvider";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

const { Title} = Typography;
const { Option } = Select;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

function CashPlan() {
  const [cards, setCards] = useState([]);
  const [expenses, setExpenses] = useState(null);
  const [aggregations, setAggregations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('daily');
  const [chartDataSets, setChartDataSets] = useState({
    all_expenses: false,
    card_expenses: true,
    non_card_expenses: false,
    non_card_incomes: false,
    account_balances: false,
  });
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const { user, currentUser, logout } = useAuth();
  const chartRef = useRef(null);

  const handleCheckboxChange = (event) => {
    setChartDataSets({
      ...chartDataSets,
      [event.target.name]: event.target.checked,
    });
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        position: 'top',
      },
    },  
    scales: {
      x: { // This assumes you want a category scale on the x-axis
        type: 'category',
        // ... other scale options
      },
      y: { // This assumes you want a linear scale on the y-axis
        type: 'linear',
        // ... other scale options
      }
    },
    // ... other chart options
  };

  const labels = ( (expenses && expenses.all_expenses) ? Object.keys(expenses.all_expenses) : [] )

  const chartData = (expenses && {
    labels,
    datasets: [
      {
        label: 'Total Expenses',
        data: expenses.all_expenses ? Object.values(expenses.all_expenses) : [],
        borderColor: 'blue',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        hidden: !chartDataSets.all_expenses,
      },
      {
        label: 'Card Expenses',
        data: expenses.card_expenses ? Object.values(expenses.card_expenses) : [],
        borderColor: 'green',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        hidden: !chartDataSets.card_expenses,
      },
      {
        label: 'Non-Card Expenses',
        data: expenses.non_card_expenses ? Object.values(expenses.non_card_expenses) : [],
        borderColor: 'red',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        hidden: !chartDataSets.non_card_expenses,
      },
      {
        label: 'Non-Card Incomes',
        data: expenses.non_card_incomes ? Object.values(expenses.non_card_incomes) : [],
        borderColor: 'purple',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        hidden: !chartDataSets.non_card_incomes,
      },
      {
        label: 'Account Balances',
        data: expenses.account_balances ? Object.values(expenses.account_balances) : [],
        borderColor: 'orange',
        backgroundColor: 'rgba(255, 159, 64, 0.5)',
        hidden: !chartDataSets.account_balances,
      },
    ]
  })

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-ZA', {
      style: 'currency',
      currency: 'ZAR',
    }).format(value);
  };

  const weeklyExpenseColumns = [
    {
      title: 'Week',
      dataIndex: 'week',
      key: 'week',
      sorter: (a, b) => new Date(a.week) - new Date(b.week),
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Card Expenses',
      dataIndex: 'cardExpenses',
      key: 'cardExpenses',
      render: (text) => formatCurrency(text),
    },
    {
      title: 'Non-Card Expenses',
      dataIndex: 'nonCardExpenses',
      key: 'nonCardExpenses',
      render: (text) => formatCurrency(text),
    },
    {
      title: 'Non-Card Income',
      dataIndex: 'nonCardIncomes',
      key: 'nonCardIncomes',
      render: (text) => formatCurrency(text),
    },
    {
      title: 'Total Expenses',
      dataIndex: 'totalExpenses',
      key: 'totalExpenses',
      render: (text) => formatCurrency(text),
    },
    {
      title: 'Account Balances',
      dataIndex: 'accountBalances',
      key: 'accountBalances',
      render: (text) => formatCurrency(text),
    }
  ];

  useEffect(() => {
    if (
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members`;
      const teamsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`;
      const cardsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards`;
      const expensesUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/overviews/expenses`;
      const aggregationsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/overviews/expenses?period=weekly`;
  
      Promise.all([
        axios.get(membersUrl, { withCredentials: true }),
        axios.get(teamsUrl, { withCredentials: true }),
        axios.get(cardsUrl, { withCredentials: true }),
        axios.get(expensesUrl, { withCredentials: true }),
        axios.get(aggregationsUrl, { withCredentials: true }),
      ])
        .then(([membersResponse, teamsResponse, cardsResponse, expensesResponse, aggregationsResponse]) => {
          setMembers(membersResponse.data.members);
          setTeams(teamsResponse.data.teams);
          setExpenses(expensesResponse.data);

          const aggregatedData = Object.keys(aggregationsResponse.data.all_expenses).map((key) => {
            return {
              key: key,
              week: key,
              totalExpenses: aggregationsResponse.data.all_expenses[key],
              cardExpenses: aggregationsResponse.data.card_expenses[key],
              nonCardExpenses: aggregationsResponse.data.non_card_expenses[key],
              nonCardIncomes: aggregationsResponse.data.non_card_incomes[key],
              accountBalances: aggregationsResponse.data.account_balances[key],
            };
          });
          setAggregations(aggregatedData);

          const updatedCards = cardsResponse.data.cards.map((card) => {
            const matchingMember = members.find((member) => member.id === card.user_id);
            if (matchingMember) {
              // Add member fields to the card
              return {
                ...card,
                member: matchingMember,
              };
            }
            return card;
          });
          setCards(updatedCards);
        })
        .catch((error) => {
          message.error("Failed to fetch data.");
          setAggregations([]);
        });
    } else {
      // message.warning('currentUser or organization is not defined.');
    }

    return () => {
      if (chartRef.current) {
        // This will destroy the chart instance when the component unmounts
        chartRef.current.destroy();
      }
    };
  }, [currentUser]);

  const handlePeriodChange = (value) => {
    setPeriod(value);
  };

  useEffect(() => {
    if (currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      const expensesUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/overviews/expenses?period=${period}`;
  
      Promise.all([
        axios.get(expensesUrl, { withCredentials: true }),
      ])
        .then(([expensesResponse]) => {
          setExpenses(expensesResponse.data);
        })
        .catch((error) => {
          message.error("Failed to fetch data.");
        });
    } else {
    }
  }, [period]);

  return (
    <>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Title level={2}>All Recurring Costs</Title>
        <Select defaultValue="daily" style={{ width: 120 }} onChange={handlePeriodChange}>
          <Option value="daily">Daily</Option>
          <Option value="weekly">Weekly</Option>
          <Option value="monthly">Monthly</Option>
        </Select>

        { expenses && <Line options={chartOptions} data={chartData} /> }
        {Array.isArray(aggregations) && (
          <Table dataSource={aggregations} columns={weeklyExpenseColumns} pagination={false} />
        )}
      </Space>
    </>
  );
}

export default CashPlan;
