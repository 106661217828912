import React, { useState, useEffect } from 'react';
import { AutoComplete, Tag, Typography, Input } from 'antd';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';

const { Text } = Typography;

const Autocomplete = ({ useSearchEndpoint = false, onAdd }) => {
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');

  const fetchOptions = async (searchQuery) => {
    const endpoint = useSearchEndpoint ? '/search' : '/autocomplete';

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/subscriptions${endpoint}`, {
        params: { query: searchQuery },
        withCredentials: true,
      });
      setOptions(response.data);
    } catch (error) {
      console.error(`Error fetching ${useSearchEndpoint ? 'search' : 'autocomplete'} options:`, error);
    }
  };

  const debouncedFetchOptions = debounce(fetchOptions, 300);

  const handleSearch = (value) => {
    setQuery(value);
    if (value.length > 2) {
      debouncedFetchOptions(value);
    }
  };

  const handleSelect = (value, option) => {
    console.log('Selected:', value);
    console.log('Selected ID:', option.id);
    onAdd && onAdd(option.id);
  };

  return (
    <AutoComplete
      style={{ width: '100%' }}
      options={options.map((opt) => {
        const categories = opt.category_of_subscription.split(',').map((category) => category.trim());
        return {
          value: opt.name,
          label: (
            <div>
              <span><strong>{opt.name}</strong> ({opt.domain})</span>
              <div>
                <span style={{ whiteSpace: 'break-word' }}>
                  <Text italic>{opt.reason}</Text>
                </span>
              </div>
              <div>
                {categories.map((category, index) => (
                  <Tag key={index} style={{ margin: '4px' }}>
                    {category}
                  </Tag>
                ))}
              </div>
            </div>
          ),
          id: opt.id,
        };
      })}
      onSelect={handleSelect}
      onSearch={handleSearch}
    >
      <Input.Search
        allowClear
        onSearch={(value) => handleSearch(value)}
        placeholder="Find subscriptions before you're charged for them"
      />
    </AutoComplete>
  );
};

export default Autocomplete;
