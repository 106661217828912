import React, { useEffect, useState } from "react";
import { Card, List, Switch, Button, Tooltip, Empty, Divider } from "antd";
import { Space, Typography, Collapse } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Transactions from "./Transactions";
import creditCardImage from "./credit-card-image.png";
const { Text, Title } = Typography;
const { Panel } = Collapse;

const Cards = () => {
  const [cards, setCards] = useState([]);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  const [accounts, setAccounts] = useState(null);

  useEffect(() => {
    fetchData();
    fetchCards();
  }, []);

  if (error) {
    return <>An error occurred: {error}</>;
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/accounts", { withCredentials: true }
      );
      setAccounts(response.data.accounts);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchCards = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/cards", { withCredentials: true }
      );
      setCards(response.data.cards);
    } catch (error) {
      console.error(error);
    }
  };

  // also in Transactions.js
  const handleToggleMonitoring = async (cardId, monitoring_active) => {
    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_BASE_URL + `/api/v1/cards/${cardId}/toggle`,
        {},
        { withCredentials: true }
      );
      // console.log(response.data.cards);
      // Update the cards state to reflect the updated monitoring status
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.id === cardId
            ? { ...card, monitoring_active: !monitoring_active }
            : card
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefreshCards = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/cards/refresh",
        null,
        { withCredentials: true }
      );
      // console.log(response.data.cards);
      // Refresh the cards by assigning the returned data list
      setCards(response.data.cards);
    } catch (error) {
      console.error(error);
    }
  };

  // Custom sorting function to sort monitored cards first
  const sortCards = (a, b) => {
    if (a.monitoring_active && !b.monitoring_active) {
      return -1; // a comes before b
    } else if (!a.monitoring_active && b.monitoring_active) {
      return 1; // b comes before a
    } else {
      return 0; // no change in order
    }
  };

  // Sort the cards based on the custom sorting function
  const sortedCards = cards.slice().sort(sortCards);

  const emptyListCTA = (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{
          height: 60,
        }}
        description={
          <Text strong style={{ fontSize: "16px" }}>
            No accounts available.
          </Text>
        }
      >
        <Space>
          <Button
            type="primary"
            icon={<ArrowRightOutlined />}
            href="/account/organization/integrations"
          >
            Configure your bank account integration
          </Button>
        </Space>
      </Empty>
    </div>
  );

  if (error) {
    return <>An error occurred: {error}</>;
  }

  return (
    <>
      <Title level={2}>Sync'd data from your Investec account</Title>
      <Collapse defaultActiveKey={["2"]}>
        <Panel header="Your Accounts" key="1">
          <Title level={2}>Accounts</Title>
          {accounts ? (
            <List
              grid={{ gutter: 16, column: 2 }}
              dataSource={accounts}
              locale={{ emptyText: emptyListCTA }}
              renderItem={(account) => (
                <List.Item>
                  <Card>
                    <Title level={4}>{account.account_name}</Title>
                    <div>
                      <Text>Account Number: {account.account_number}</Text>
                    </div>
                    <div>
                      <Text>Product Name: {account.product_name}</Text>
                    </div>
                    <div>
                      <Text>Profile Name: {account.profile_name}</Text>
                    </div>
                    {account.kyc_compliant && <Text>KYC Compliant</Text>}
                  </Card>
                </List.Item>
              )}
            />
          ) : (
            <Text>Loading...</Text>
          )}
        </Panel>

        <Panel header="Select Cards to manage" key="2">
          <Title level={2}>Select a Card to Monitor</Title>
          <div>
            <Text>
              Select a card below to enable monitoring. Monitored cards will be
              tracked for subscriptions and transactions blocking.
            </Text>
          </div>
          <h2> </h2>
          <Button onClick={handleRefreshCards}>Refresh Cards</Button>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {cards.length > 0 ? (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {sortedCards.map((card) => (
                  <Card
                    key={card.id}
                    title={
                      <Space align="center">
                        {card.is_programmable ? (
                          <Tooltip title="Programmable Banking Enabled">
                            <CheckCircleOutlined style={{ color: "green" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Programmable Banking Disabled">
                            <CloseCircleOutlined style={{ color: "grey" }} />
                          </Tooltip>
                        )}
                        <Title level={3}>{card.embossed_name}</Title>
                      </Space>
                    }
                    style={{
                      width: 300,
                      margin: "16px",
                      backgroundColor: "white",
                      color: "black",
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)",
                      fontWeight: "bold",
                      position: "relative",
                      overflow: "hidden",
                    }}
                    cover={
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundImage: `url(${creditCardImage})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          opacity: 0.1, // Set the opacity here
                        }}
                      />
                    }
                    actions={[
                      <Switch
                        checked={card.monitoring_active}
                        onChange={() =>
                          handleToggleMonitoring(
                            card.id,
                            card.monitoring_active
                          )
                        }
                      />,
                    ]}
                  >
                    {card.card_number && (
                      <p>{`${card.card_number.slice(
                        0,
                        4
                      )} **** **** ${card.card_number.slice(-4)}`}</p>
                    )}
                  </Card>
                ))}
              </div>
            ) : (
              <div>No cards available</div>
            )}
          </div>
        </Panel>

        <Panel header="Transactions" key="3">
          <Title level={2}>Transactions</Title>
          <Transactions />
        </Panel>
      </Collapse>
    </>
  );
};

export default Cards;
