import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Select, Pagination, Row, Col } from 'antd';
import { Avatar, Tag, Skeleton } from 'antd';
import {
  CreditCardOutlined,
  AlertOutlined,
  LockOutlined,
  SettingOutlined,
  DollarOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const EventsTable = ({ actionType, cardId }) => {
  const [events, setEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEvents, setTotalEvents] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [eventType, setEventType] = useState('card_payment');
  const screens = useBreakpoint();

  const getEventTypeIcon = (eventType) => {
    switch (eventType) {
      case 'card':
        return <CreditCardOutlined />;
      case 'card_payment':
        return <DollarOutlined />;
      case 'non_card_payment':
        return <FileTextOutlined />;
      case 'access':
        return <LockOutlined />;
      case 'subscription':
        return <AlertOutlined />;
      case 'configuration':
        return <SettingOutlined />;
      case 'billing':
        return <FileTextOutlined />;
      default:
        return null;
    }
  };
  
  let columns = []
  if (screens.md) {
    columns = [
      {
        title: 'Event Type',
        dataIndex: 'event_type',
        key: 'event_type',
        render: (eventType) => (
          <span>
            {getEventTypeIcon(eventType)}
            <span style={{ marginLeft: 8 }}>{eventType}</span>
          </span>
        ),
      },
      {
        title: 'Message',
        dataIndex: 'payload',
        key: 'payload',
        render: (payload) => {
          const formattedMessage = payload.message.replace(/\n/g, '  \n');
          return (
            <div>
              <div><strong>{payload.action}</strong></div>
              <div className="blockquote-style">
                <ReactMarkdown>{formattedMessage}</ReactMarkdown>
              </div>
            </div>
          );
        },
      }
    ];
  } else {
    columns = [
      {
        title: 'Message',
        dataIndex: 'payload',
        key: 'payload',
        render: (payload) => {
          const formattedMessage = payload.message.replace(/\n/g, '  \n');
          return (
            <div>
              <div><strong>{payload.action}</strong></div>
              <div className="blockquote-style">
                <ReactMarkdown>{formattedMessage}</ReactMarkdown>
              </div>
            </div>
          );
        },
      }
    ];
  }

  const fetchEvents = async () => {
    setIsLoading(true);
  
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = actionType === 'show' ? `/api/v1/events/${cardId}` : '/api/v1/events';
    const params = {
      event_type: eventType,
      pagination: true,
      page: currentPage,
      per_page: 5, // Set the number of events per page
    };
  
    try {
      const response = await axios.get(`${baseUrl}${endpoint}`, {
        params: params,
        withCredentials: true
      });
      setEvents(response.data.events);
      setTotalEvents(response.data.total); // Update this if the API returns the total number of events
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setIsLoading(false);
    }
  };  

  useEffect(() => {
    fetchEvents();
  }, [actionType, cardId, eventType, currentPage]);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <Select
            placeholder="Filter by Event Type"
            onChange={(value) => setEventType(value)}
            value={eventType}
            style={{ width: '100%', marginBottom: 16 }}
          >
            { actionType == "show" ? (
                <>
                  <Select.Option value="card">Card</Select.Option>
                  <Select.Option value="card_payment">Card Payment</Select.Option>
                </>
              ) : (
                <>
                  <Select.Option value="card">Card</Select.Option>
                  <Select.Option value="card_payment">Card Payment</Select.Option>
                  <Select.Option value='non_card_payment'>Non Card Payment</Select.Option>
                  <Select.Option value='access'>Access</Select.Option>
                  <Select.Option value='subscription'>Subscription</Select.Option>
                  <Select.Option value='configuration'>Configuration</Select.Option>
                  <Select.Option value='billing'>Billing</Select.Option>
                </>
              )
            }
          </Select>
        </Col>
      </Row>
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <Table dataSource={events} columns={columns} rowKey="id" pagination={false} />
      )}
      <Pagination
        current={currentPage}
        total={totalEvents}
        onChange={(page) => setCurrentPage(page)}
        style={{ marginTop: '20px', textAlign: 'center' }}
      />
    </div>
  );
};

export default EventsTable;
