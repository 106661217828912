import React, { useEffect, useState } from "react";
import axios from "axios";
import SubscriptionsOverviewTable from "./components/SubscriptionsOverviewTable";
import SuggestionsTable from "./components/SuggestionsTable";
import Autocomplete from "./components/Autocomplete";
import { AutoComplete, Space, Typography } from "antd";
import { Form, Input, Table, Button, Select, message, Tag } from "antd";
import {
  MonitorOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  // CloseCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import { useAuth } from "./AuthProvider";
import { useParams } from "react-router-dom";

const { Title, Text } = Typography;

function AllSubscriptions({
  hideTransactions = false,
  hideSearch = false,
  hideSuggestions = false,
  hideCardNameColumn = true,
}) {
  const [data, setData] = useState(null);
  const [cards, setCards] = useState([]);
  const [teams, setTeams] = useState([]);
  // const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [assignedCardholder, setAssignedCardholder] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [error, setError] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [tags, setTags] = useState("");
  const { user, currentUser, logout } = useAuth();
  const { card_id } = useParams();


  const fetchTransactions = async (expanded, record) => {
    if (expanded) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${record.card_id}/transactions?business_id=${record.id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        setTransactions((prev) => ({
          ...prev,
          [record.id]: response.data.transactions,
        }));

        record["transactions"] = response.data.transactions;
        record["metadata"] = response.data.metadata;
      } catch (error) {
        message.error(
          "An error occured while finding transactions. Please try again later"
        );
      }
    }
  };

  const handleDeleteSubscription = async (record) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${record.card_id}/subscriptions/${record.subscription_id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      message.success("Subscription removed");
      // Update the data with the updated subscription
      setData((prevData) => {
        const updatedSubscriptions = prevData.subscriptions.filter(
          (subscription) =>
            subscription.subscription_id !== record.subscription_id
        );

        return {
          ...prevData,
          subscriptions: updatedSubscriptions,
        };
      });

      // Redirect to the login page or any other desired page after account deletion
    } catch (error) {
      console.error(error);
      message.error(
        "Failed to remove subscription tracking. Please try again later."
      );
    }
  };

  const handleToggleSubscription = async (record, active) => {
    try {
      const subscriptionId = record.subscription_id;
      console.log(`Toggle subscription ${subscriptionId}`);
      const response = await axios.patch(
        process.env.REACT_APP_API_BASE_URL +
          `/api/v1/cards/${record.card_id}/subscriptions/toggle`,
        {
          business_id: `${record.id}`, //handle strangeness in JS.
          active: active ? 0 : 1,
        },
        { withCredentials: true }
      );

      // Update the data with the updated subscription
      setData((prevData) => {
        const updatedSubscriptions = prevData.subscriptions.map(
          (subscription) => {
            if (subscription.subscription_id === record.subscription_id) {
              subscription.active
                ? message.success(
                    "Card payments to " +
                      subscription.name +
                      " will be blocked. If you would like to cancel your subscription agreement, please contact the provider directly"
                  )
                : message.success(
                    "Card payments to " + subscription.name + " are active"
                  );
              return {
                ...subscription,
                active: !subscription.active,
              };
            }
            return subscription;
          }
        );

        
        return {
          ...prevData,
          subscriptions: updatedSubscriptions,
        };
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleAssignUser = async (record, userId) => {
    try {
      // Make API call to update the subscription
      const subscriptionId = record.subscription_id
      const response = await axios.patch(
        process.env.REACT_APP_API_BASE_URL +
          `/api/v1/cards/${record.card_id}/subscriptions/${subscriptionId}`,
        {
          user_id: userId,
        },
        { withCredentials: true }
      );

      message.success("Subscription updated successfully");
    } catch (error) {
      message.error("Error updating subscription");
    }
  };

  useEffect(() => {
    if (
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members`;
      const teamsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`;
      const cardsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards`;
      const subscriptionsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/subscriptions`;

      Promise.all([
        axios.get(membersUrl, { withCredentials: true }),
        axios.get(teamsUrl, { withCredentials: true }),
        axios.get(cardsUrl, { withCredentials: true }),
        axios.get(subscriptionsUrl, { withCredentials: true }),
      ])
        .then(([membersResponse, teamsResponse, cardsResponse, subscriptionsResponse]) => {
          setMembers(membersResponse.data.members);
          setTeams(teamsResponse.data.teams);
          setData(subscriptionsResponse.data);
          const updatedCards = cardsResponse.data.cards.map((card) => {
            const matchingMember = members.find(
              (member) => member.id === card.user_id
            );
            if (matchingMember) {
              // Add member fields to the card
              return {
                ...card,
                member: matchingMember,
              };
            }
            return card;
          });
          setCards(updatedCards);
        })
        .catch((error) => {
          message.error("Failed to fetch data.");
        });
    } else {
      // message.warning('currentUser or organization is not defined.');
    }
  }, [currentUser]);

  if (error) {
    return <>An error occurred: {error}</>;
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Title level={2}>All tracked subscriptions</Title>
        <div style={{ maxWidth: '400px' }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "50px",
            }}
          >
          </div>
        </div>
      </div>
      
      {data ? (
        <>        
          <SubscriptionsOverviewTable
            subscriptions={data.subscriptions}
            onToggle={handleToggleSubscription}
            onRemove={handleDeleteSubscription}
            onRowExpand={fetchTransactions}
            users={members}
            onAssignUser={handleAssignUser}
            hideCardNameColumn={hideCardNameColumn}
          />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h2>No Subscriptions Available</h2>
          <p>
            You currently don't have any subscriptions available for monitoring.
            Once you add subscriptions to your cards, they will appear here for
            management.
          </p>
          <Link to="/account/organization/integrations">
            <Button type="primary">Go to Integrations</Button>
          </Link>
        </div>
      )}
    </>
  );
}

export default AllSubscriptions;
