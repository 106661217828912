import { useAuth } from "./AuthProvider";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Tag, Button, Modal, Tooltip , message, Typography} from "antd";
import { Sparklines, SparklinesLine } from 'react-sparklines';
import Papa from 'papaparse';
import { DownloadOutlined, LinkOutlined, CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard'; 
import { Link } from 'react-router-dom';

const {Text} = Typography;

const getLastSixMonths = () => {
  const months = [];
  const date = new Date();
  for (let i = 0; i < 6; i++) {
    date.setMonth(date.getMonth() - 1);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    months.unshift(`${year}-${month}`);
  }
  return months;
};
const sorter = (a, b, key) => {
  const aValue = a[key] ? parseFloat(a[key].replace(/[^0-9.-]/g, "")) : 0;
  const bValue = b[key] ? parseFloat(b[key].replace(/[^0-9.-]/g, "")) : 0;
  return aValue - bValue;
};

const TransactionsTable = () => {
  const [data, setData] = useState([]);
  const [cards, setCards] = useState([]);
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const months = getLastSixMonths();
  const [exportUrl, setExportUrl] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { user, currentUser, logout } = useAuth();

  const generateExportUrl = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/api/v1/transactions_and_subscriptions/export_url`, { withCredentials: true });
  
      setExportUrl(response.data.url);
      setModalVisible(true);
      setIsCopied(false);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const convertDataToCSV = (data) => {
    // Map the data to match the CSV format (exclude the 'trends' property and convert 'cards' array to string)
    const dataForCSV = data.map((item) => {
      const {trends, ...otherItems} = item;
      otherItems.cards = otherItems.cards.join(", "); // Convert 'cards' array to string
      
      // Add missing months with a default value
      months.forEach(month => {
        if (otherItems.hasOwnProperty(month)) {
          otherItems[month] = (otherItems[month] / 100).toFixed(2); // Convert from cents to currency
        } else {
          otherItems[month] = '';
        }
      });
      return otherItems;
    });
    const csv = Papa.unparse(dataForCSV);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'export.csv');
    tempLink.click();
  };

  const columns = [
    {
      title: "Business",
      dataIndex: "business_name",
      key: "business_name",
    },
    {
      title: "Trends",
      key: "trends",
      render: (_, record) => {
        // Generate an array of monthly values for this business
        const sparklineData = months.map(m => record[m] / 100).filter(Boolean);
  
        return (
          <Sparklines data={sparklineData} limit={sparklineData.length} width={100} height={20} margin={5}>
            <SparklinesLine color="blue" />
          </Sparklines>
        );
      }
    },
    {
      title: "Cards",
      dataIndex: "card_ids",
      key: "cards",
      render: (card_ids) => {
        return (
          <>
            {card_ids.map((card_id) => {
              const card = cards.find(c => c.id === card_id);
              return (
                card && (
                  <Tag color="blue" key={card.id}>
                    <Link to={`/subscriptions/${card.id}`} style={{ color: "inherit" }}>
                      {card.embossed_name}
                    </Link>
                  </Tag>
                )
              );
            })}
          </>
        );
      },
    },       
    {
      title: "Cost Owner",
      dataIndex: "owners",
      key: "owners",
      render: (cards) => (
        <>
          {cards.map((card) => (
            <Tag color="blue" key={card}>
              {card}
            </Tag>
          ))}
        </>
      ),
    },
    ...months.map((month, index) => ({
      title: month,
      dataIndex: month,
      key: month,
      sorter: (a, b) => sorter(a, b, month),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: index === months.length - 1 ? "descend" : undefined, // default sort for the last month
      render: (cents) => {
        if (!cents) return;
        const rands = cents / 100;
        const formattedRands = rands.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return `R${formattedRands}`;
      }
      
    })),
  ];

  useEffect(() => {
    if (
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members`;
      const teamsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`;
      const cardsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards`;
      const expensesUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/overviews/transactions`;
  
      Promise.all([
        axios.get(membersUrl, { withCredentials: true }),
        axios.get(teamsUrl, { withCredentials: true }),
        axios.get(cardsUrl, { withCredentials: true }),
        axios.get(expensesUrl, { withCredentials: true }),
      ])
        .then(([membersResponse, teamsResponse, cardsResponse, expensesResponse]) => {
          setMembers(membersResponse.data.members);
          setTeams(teamsResponse.data.teams);
          setData(expensesResponse.data);

          const updatedCards = cardsResponse.data.cards.map((card) => {
            const matchingMember = members.find((member) => member.id === card.user_id);
            if (matchingMember) {
              // Add member fields to the card
              return {
                ...card,
                member: matchingMember,
              };
            }
            return card;
          });
          setCards(updatedCards);
        })
        .catch((error) => {
          message.error("Failed to fetch data.");
        });
    } else {
      // message.warning('currentUser or organization is not defined.');
    }
  }, [currentUser]);

  return (
    <>
      <Button 
        type="primary" 
        icon={<DownloadOutlined />} 
        size="middle" 
        onClick={() => convertDataToCSV(data)}
        style={{ marginBottom: '20px', float: 'right' }}
      >
        Export summary
      </Button>
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        size="middle"
        onClick={generateExportUrl}
        style={{ marginBottom: '20px', marginRight: '10px', float: 'right' }}
      >
        Export all transactions
      </Button>
      <Modal
        title="Download your transaction history"
        visible={modalVisible}
        footer={[
          <a
            href={exportUrl}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <Button type="primary" key="download">
              Download
            </Button>
          </a>
        ]}
        onCancel={() => setModalVisible(false)}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Text italic>You are about to download sensitive information</Text>

          {/* <CopyToClipboard text={exportUrl} onCopy={() => setIsCopied(true)}>
            <Tooltip title={isCopied ? "Copied!" : "Copy to clipboard"}>
              <button style={{marginLeft: '10px', border: 'none', background: 'none'}}>
                <CopyOutlined />
              </button>
            </Tooltip>
          </CopyToClipboard> */}
        </div>
      </Modal>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="business_name"
        pagination={{ pageSize: 100 }}
        scroll={{ y: 540 }}
        sticky
      />
    </> 
  );
};

export default TransactionsTable;
