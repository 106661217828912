import React, { useState, useEffect, useRef } from "react";
import {
  Tag,
  Table,
  Button,
  Input,
  Popconfirm,
  message,
  Typography,
  Select,
  Tour,
} from "antd";
import { Row, Col } from "antd";
import {
  MailOutlined,
  DeleteOutlined,
  UserAddOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useAuth } from "./AuthProvider";
import MemberAssignmentModal from "./components/MemberAssignmentModal";
import { Modal } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Link, Text, Title } = Typography;
const { Option } = Select;

const AccountOrganizationMembers = () => {
  const { user, currentUser, logout } = useAuth();
  const [members, setMembers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [inviteEmail, setInviteEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [newTeamName, setNewTeamName] = useState("");
  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const [isMemberAssignmentModalVisible, setMemberAssignmentModalVisible] = useState(false);
  const [showAdminActions, setShowAdminActions] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState({});
  const screens = useBreakpoint();

  // >>TOUR
  const refInvite = useRef(null);
  const refAccess = useRef(null);
  const refTeams = useRef(null);
  const refRemove = useRef(null);
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([
    {
      title: 'Invite other people to join your account',
      description: 'Click here to invite team members to your Subtrack account. Typically invite anyone who is a cardholder or manages any expenses or financials within a department.',
      target: () => refInvite.current,
    },
    {
      title: 'Role Access',
      description: 'Admins have all access to all the financial data. Member access is limited to only the team finances that they are assign to.',
      target: () => refAccess.current,
    },
    {
      title: 'Assign Teams',
      description: 'Assign users to particular teams/departments to manage access and notifications. Everyone is in "All members" which is reserved for admins.',
      target: () => refTeams.current,
    },
    {
      title: 'Removing members from the account',
      description: 'Removing a member will revoke any access they have to your account. You can always re-invite them.',
      target: () => refRemove.current,
    },
  ]);
  // <<TOUR

  useEffect(() => {
    if (
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members`;
      const teamsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`;

      Promise.all([
        axios.get(membersUrl, { withCredentials: true }),
        axios.get(teamsUrl, { withCredentials: true }),
      ])
        .then(([membersResponse, teamsResponse]) => {
          setMembers(membersResponse.data.members);
          setTeams(teamsResponse.data.teams);
          setAllTeams(teamsResponse.data.teams);
        })
        .catch((error) => {
          message.error("Failed to fetch data.");
        });
    } else {
      // message.warning('currentUser or organization is not defined.');
    }

    if (
      currentUser &&
      currentUser.role == "admin"
    ) {
      setShowAdminActions(true);
    }
  }, [currentUser]);

  const getAvailableTeamsForMember = (member) => {
    return teams.filter(
      (team) => !member.teams.some((memberTeam) => memberTeam.id === team.id)
    );
  };

  const updateMemberTeams = (memberEmail, newTeam) => {
    setMembers((prevMembers) =>
      prevMembers.map((member) => {
        if (member.email === memberEmail) {
          return { ...member, teams: [...member.teams, newTeam] };
        }
        return member;
      })
    );
  };

  const addMemberToTeam = (memberId, teamId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/${teamId}/add_member`,
        { user_id: memberId },
        { withCredentials: true }
      )
      .then((response) => {
        message.success("Successfully added member to team.");

        setMembers((prevMembers) =>
          prevMembers.map((member) => {
            if (member.id === memberId) {
              return {
                ...member,
                teams: [...member.teams, response.data.team],
              };
            }
            return member;
          })
        );
      })
      .catch((error) => {
        // message.error("Failed to add member to team.");
      });
  };

  const showDoubleConfirmation = (memberEmail, onConfirm) => {
    Modal.confirm({
      title: "Are you sure you want to remove this member?",
      content: `You're about to remove ${memberEmail} from the organization.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        Modal.confirm({
          title: "This action is irreversible. Are you absolutely sure?",
          content: `Confirm again to remove ${memberEmail}.`,
          okText: "Yes, remove",
          okType: "danger",
          cancelText: "Cancel",
          onOk() {
            onConfirm();
          },
          onCancel() {},
        });
      },
      onCancel() {},
    });
  };

  const removeMemberFromOrg = (memberId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members/${memberId}`,
        { withCredentials: true }
      )
      .then((response) => {
        message.success("Successfully removed member.");
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member.id !== memberId)
        );
      })
      .catch((error) => {
        message.error("Failed to remove member.");
      });
  };

  const removeMemberFromAssignedTeam = (memberId, teamId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/${teamId}/remove_member/${memberId}`,
        { withCredentials: true }
      )
      .then((response) => {
        const updatedMembers = members.map((member) => {
          if (member.id === memberId) {
            return {
              ...member,
              teams: member.teams.filter((team) => team.id !== teamId),
            };
          }
          return member;
        });
        setMembers(updatedMembers);
        message.success("Assigned team updated.");
      })
      .catch((error) => {
        message.error("Failed to remove member from assigned team.");
      });
  };

  const handleInvite = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!inviteEmail || /\s/.test(inviteEmail)) {
      setErrorMessage('Invalid email: cannot be empty or contain whitespaces.');
      return;
    }
  
    if (!emailPattern.test(inviteEmail)) {
      setErrorMessage('Invalid email format.');
      return;
    }

    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/invites",
        {
          email: inviteEmail,
          // Add other fields as required
        },
        { withCredentials: true }
      )
      .then((response) => {
        setMembers([
          ...members,
          { id: response.data.invite.id, email: inviteEmail, name: "Pending invite", teams: [] },
        ]);
        setInviteEmail("");
        setErrorMessage('');
        message.success("Member invited.");
      })
      .catch((error) => {
        message.error("Failed to invite member.");
      });
  };

  const createNewTeam = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`,
        { name: newTeamName },
        { withCredentials: true }
      )
      .then((response) => {
        // Assuming the response contains an updated list of all teams
        setTeams(response.data.teams);
        setAllTeams(response.data.teams);
        message.success("New team created.");
      })
      .catch((error) => {
        message.error("Failed to create new team.");
      });
  };

  const deleteTeam = (teamId) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/${teamId}`, { withCredentials: true })
      .then((response) => {
        // Update the local state to remove the deleted team
        setTeams(teams.filter((team) => team.id !== teamId));
        setAllTeams(teams.filter((team) => team.id !== teamId));
        message.success("Team deleted successfully.");
      })
      .catch((error) => {
        message.error("Failed to delete team.");
      });
  };

  const handleChangeRole = (userId, newRole) => {
    axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${userId}/role`, 
      { role: newRole }, 
      { withCredentials: true })
      .then((response) => {
        const updatedUser = response.data.user;
        // Now update local state
        setMembers((prevMembers) => 
          prevMembers.map((member) =>
            member.id === userId ? { ...member, role: updatedUser.role } : member
          )
        );
        message.success("Role updated successfully.");
      })
      .catch((error) => {
        message.error("Failed to update role.");
      });
  };  

  const User = {
    Roles: { member: "member", admin: "admin" },
  };

  let columns = [];
  if(screens.md) {
    columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <Text>{text || record.preferred_name || record.email}</Text>
        ),
        responsive: ['sm'],
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        responsive: ['md'],
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (role, record, index) => (
          <div ref={index === 0 ? refAccess : null}>
            <Select
              value={role}
              style={{ width: 120 }}
              onChange={(newRole) => handleChangeRole(record.id, newRole)}
              disabled={record.id === currentUser.id || currentUser.role !== "admin"}
            >
              {Object.keys(User.Roles).map((roleKey) => (
                <Option key={roleKey} value={roleKey}>
                  {roleKey}
                </Option>
              ))}
            </Select>
          </div>
        ),
        responsive: ['md'],
      },    
      {
        title: "Teams",
        dataIndex: "teams",
        key: "teams",
        render: (teams, record, index) => {
          const memberTeams = record.teams.map((team) => team.id);

          const availableTeams = allTeams.filter(
            (team) => !memberTeams.includes(team.id)
          );

          return(
            <div ref={index === 0 ? refTeams : null}>
              {teams.map((team) => (
                <Tag
                  key={team.id}
                  color="blue"
                  closable={!team.is_default && currentUser.role === "admin"}
                  onClose={() => removeMemberFromAssignedTeam(record.id, team.id)}
                >
                  {team.name}
                </Tag>
              ))}

              {/* Toggle Dropdown Button */}
              <Button
                type="primary"
                icon={dropdownVisible[record.id] ? <CloseOutlined /> : <PlusOutlined />}
                size="small"
                disabled={!showAdminActions}
                style={{ marginLeft: 8 }}
                onClick={() => setDropdownVisible({ ...dropdownVisible, [record.id]: !dropdownVisible[record.id] })}
              />
        
              {/* Add Team Select Dropdown */}
              {dropdownVisible[record.id] && (
                <Select
                  style={{ width: 200 }}
                  placeholder="Select or create team"
                  // Handle the action when a team is selected
                  onChange={(selectedTeamId) => {
                    addMemberToTeam(record.id, selectedTeamId);
                    setSelectedTeam(undefined);
                  }}
                  disabled={!showAdminActions}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <div
                        style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={newTeamName}
                          onChange={(e) => setNewTeamName(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              createNewTeam();
                              e.preventDefault(); // Prevent default Enter key behavior
                            }
                          }}
                        />
                        <Button type="primary" onClick={createNewTeam}>
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {/* Render only the teams that the member is not already part of */}
                  {availableTeams.map((team) => (
                    <Option key={team.id} value={team.id}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span onClick={() => addMemberToTeam(record.id, team.id)}>
                          {team.name}
                        </span>
                        <Popconfirm
                          title="Are you sure you want to delete this team?"
                          onConfirm={(e) => {
                            e.stopPropagation(); // Prevent event from bubbling up
                            deleteTeam(team.id);
                          }}
                          onCancel={(e) => e.stopPropagation()} // Also prevent event from bubbling up on cancel
                          okText="Yes"
                          cancelText="No"
                        >
                          <a onClick={(e) => e.stopPropagation()}>
                            {" "}
                            {/* Stop propagation here too */}
                            <DeleteOutlined />
                          </a>
                        </Popconfirm>
                      </div>
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          )
        },
        responsive: ['lg'],
      },
      {
        title: "Action",
        key: "action",
        render: (text, record, index) => (
          <Button
            ref={index === 0 ? refRemove : null}
            type="dashed"
            danger
            onClick={() => showDoubleConfirmation(record.email, () => removeMemberFromOrg(record.id))}
            disabled={(currentUser.role == "admin" && record.id == currentUser.id) || currentUser.role !== "admin"}
          >
            {currentUser.email === record.email ? 'Leave' : 'Remove'}
          </Button>
        ),
        responsive: ['md'],
      },
    ];
  } else {
    columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <div>
            <Text>{text || record.preferred_name}</Text>
            <p style={{fontSize: 11}}>{record.email}</p>
          </div>
          
        ),
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role",
        render: (role, record, index) => (
          <div ref={index === 0 ? refAccess : null}>
            <Select
              value={role}
              style={{ width: 120 }}
              onChange={(newRole) => handleChangeRole(record.id, newRole)}
              disabled={record.id === currentUser.id || currentUser.role !== "admin"}
            >
              {Object.keys(User.Roles).map((roleKey) => (
                <Option key={roleKey} value={roleKey}>
                  {roleKey}
                </Option>
              ))}
            </Select>
          </div>
        ),
      }
    ]
  }

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col flex="auto">
          <Title level={2}>Members</Title>
        </Col>
        {screens.md && (
          <Col>
            <Button 
              type="text" 
              onClick={() => setOpen(true)} 
              style={{ zIndex: 1000 }}
            >
              <em>Learn how to use this page</em>
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col xs={24} md={16}>
          <Text>Invite your teammates, assign teams, and control access.</Text>
        </Col>
        <Col xs={24} md={8} style={{ textAlign: screens.md ? 'right' : 'left', marginTop: screens.md ? 0 : 16 }}>
          {/* <Input 
            placeholder="Enter email to invite" 
            value={inviteEmail} 
            onChange={(e) => setInviteEmail(e.target.value)} 
            style={{ marginBottom: 16, width: screens.md ? 'auto' : '100%' }}
          /> */}
          {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
          <Button
            ref={refInvite}
            type="primary"
            disabled={!showAdminActions}
            onClick={() => {
              // handleInvite()
              setMemberAssignmentModalVisible(true);
            }}
          >
            Invite Members
          </Button>
        </Col>
      </Row>
      <br></br>
      <Table dataSource={members} columns={columns} rowKey="email" scroll={{ x: 'max-content' }} />
      <MemberAssignmentModal
        currentUser={currentUser}
        visible={isMemberAssignmentModalVisible}
        onCancel={() => setMemberAssignmentModalVisible(false)}
        onMemberAssigned={() => {
          setMemberAssignmentModalVisible(false);
        }}
      />
      {screens.md && (
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          steps={steps}
        />
      )}
    </>
  );
};

export default AccountOrganizationMembers;
