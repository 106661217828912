import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Card, List, Typography, message, Row, Col, Button, Modal, Divider, Tour } from "antd";
import { Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useAuth } from "./AuthProvider";
import OrgSettingsForm from "./components/OrgSetup/OrgSettingsForm";
import { updateOrgName } from "./services/api";
import axios from "axios";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Title, Text, Paragraph } = Typography;

function OrgSetup() {
  const [newOrgName, setNewOrgName] = useState("");
  const [newOrgDomain, setNewOrgDomain] = useState("");
  const [isDeleteOrgModalVisible, setDeleteOrgModalVisible] = useState(false);
  const { currentUser, setStaticUserData } = useAuth();
  const navigate = useNavigate();
  const screens = useBreakpoint();

  // >>TOUR
  const ref1 = useRef(null);
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([
    {
      title: 'Set up organisation name and domain restrictions',
      description: 'Specify your domain to restrict invitations to users with @your-domain email addresses. See "Members" for details.',
      target: () => ref1.current,
    },
  ]);
  // <<TOUR

  useEffect(() => {
    if (currentUser?.organization?.id) {
      setNewOrgName(currentUser.organization.name);
      setNewOrgDomain(currentUser.organization.domain);
    }
  }, [currentUser]);

  const handleOrgNameAndDomainChange = async () => {
    const { success, error } = await updateOrgName(currentUser.organization.id, newOrgName, newOrgDomain);
    if (success) {
      setStaticUserData(prevState => ({
        ...prevState,
        organization: {
          ...prevState.organization,
          name: newOrgName,
          domain: newOrgDomain
        }
      }));
      message.success("Settings updated successfully");
    } else {
      message.error("Failed to update organisation settings");
    }
  };

  const handleDeleteOrganization = () => {
    setDeleteOrgModalVisible(true);
  };

  const handleConfirmDeleteOrganization = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}`, { withCredentials: true });
      message.success("Organization deleted successfully");
      window.location.href = "/";
      // Here you might want to navigate the user away from the org-specific screens
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the organization. Please try again later.");
    }
    setDeleteOrgModalVisible(false);
  };

  const handleCancelDeleteOrganization = () => {
    setDeleteOrgModalVisible(false);
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="space-between" align="middle">
        <Col flex="auto">
          <Title level={2}>Organisation Settings</Title>
        </Col>
        {screens.md && (
          <Col>
            <Button 
              type="text" 
              onClick={() => setOpen(true)} 
              style={{ zIndex: 1000 }}
            >
              <em>Learn how to use this page</em>
            </Button>
          </Col>
        )}
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Title level={3}>Name</Title>
          <div ref={ref1}>
            {currentUser?.organization?.id ? (
              <OrgSettingsForm
                orgName={currentUser.organization.name}
                newOrgName={newOrgName}
                setNewOrgName={setNewOrgName}
                orgDomain={currentUser.organization.domain}
                newOrgDomain={newOrgDomain}
                setNewOrgDomain={setNewOrgDomain}
                handleOrgNameAndDomainChange={handleOrgNameAndDomainChange}
              />
            ) : (
              <Text>Loading...</Text>
            )}
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col xs={24} md={12}>
          <Divider />
          <Card 
            bordered={false} 
            style={{ 
              border: '2px solid red',
              borderRadius: '8px',
              padding: '10px',
            }}
          >
            <Title level={3} type="danger">Danger Zone</Title>
            <Paragraph>
              Be careful – these actions can affect your organisation's data irreversibly.
            </Paragraph>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: '20px' }}>
              <div style={{ flex: '1 1 auto', minWidth: '200px' }}>
                <Paragraph strong style={{ marginBottom: '0' }}>
                  <ExclamationCircleOutlined style={{ color: 'red', marginRight: '10px' }} />
                  Delete your organization and all its data. Users will no longer be a part of the organization.
                </Paragraph>
              </div>
              <Button type="primary" danger onClick={handleDeleteOrganization} style={{ marginTop: '10px' }}>
                Delete Organization
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Are you absolutely sure?"
        visible={isDeleteOrgModalVisible}
        onCancel={handleCancelDeleteOrganization}
        footer={[
          <Button key="cancel" onClick={handleCancelDeleteOrganization}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            disabled={!(currentUser && currentUser.role === "admin")}
            danger
            onClick={handleConfirmDeleteOrganization}
          >
            Delete Organization
          </Button>,
        ]}
      >
        <p>
          <ExclamationCircleOutlined style={{ marginRight: "8px", color: "red" }} />
          Deleting this organization will disassociate all members and remove all associated data.
        </p>
        <p>Are you sure you want to proceed with the organization deletion?</p>
      </Modal>
      {screens.md && ( 
        <Tour
          open={open}
          onClose={() => setOpen(false)}
          steps={steps}
        />
      )}
    </>
  );
}

export default OrgSetup;
