import React, { useState, useEffect } from 'react';
import { Tooltip, Modal, Input, Button, message } from 'antd';
import axios from 'axios';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const { Option } = Select;

const MemberAssignmentModal = ({
  currentUser,
  visible,
  onCancel,
  onMemberAssigned,
  teams: propTeams,
}) => {
  const [members, setMembers] = useState([]);
  const [invitableMembers, setInvitableMembers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteTeamIds, setInviteTeamIds] = useState('');
  const [inviteRole, setInviteRole] = useState('');
  const [userName, setUserName] = useState('');  
  const [assignedTeams, setAssignedTeams] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  useEffect(() => {
    if (currentUser && currentUser.organization && currentUser.organization.id && invitableMembers.length === 0) {
      if (propTeams) {
        // Use the provided teams if available
        setTeams(propTeams);
      } else {
        const teamsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`;
        const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members?invitables=true`;

        Promise.all([
          axios.get(teamsUrl, { withCredentials: true }),
          axios.get(membersUrl, { withCredentials: true }),
        ])
          .then(([teamsResponse, membersResponse]) => {
            setTeams(teamsResponse.data.teams);
            setInvitableMembers(membersResponse.data.invitable_members);
          })
          .catch((error) => {
            message.error('Failed to fetch data.');
          });
        }
    } else {
      // Handle the case when currentUser or organization is not defined.
    }
  }, [currentUser, invitableMembers.length]);

  const handleInvite = () => {
    selectedEmails.forEach(selectedEmail => {
      const inviteEmail = typeof selectedEmail === 'string' ? selectedEmail : selectedEmail.value;

      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!inviteEmail || /\s/.test(inviteEmail)) {
        setErrorMessage('Invalid email: cannot be empty or contain whitespaces.');
        return;
      }

      if (!emailPattern.test(inviteEmail)) {
        setErrorMessage('Invalid email format.');
        return;
      }
      
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/invites`,
          {
            email: inviteEmail,
            role: inviteRole.value,
            team_id: selectedTeams.value,
          }, // { email: inviteEmail, name: userName, teams: assignedTeams, inactive_member: inactiveMember },
          { withCredentials: true }
        )
        .then((response) => {
          setMembers([
            ...members,
            { id: response.data.invite.id, email: inviteEmail, name: 'Pending invite', teams: teams.filter(team => selectedTeams.value === team.id) },
          ]);
          setInviteEmail('');
          setInviteRole(null);
          setErrorMessage('');
          message.success('Member invited.');
          onMemberAssigned(response.data.invite);
          setSelectedTeams([]);
        })
        .catch((error) => {
          message.error('Failed to invite member.');
        });
      setSelectedEmails([]);
      setInviteRole(null);
      setSelectedTeams([]);
    });
  };

  const options = invitableMembers.map(member => ({ value: member.email, label: member.email }));

  return (
    <Modal
      title="Invite Member"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="invite" type="primary" onClick={() => handleInvite()}>
          Invite Member
        </Button>,
      ]}
    >
      <div style={{ flexWrap: "nowrap", padding: "8px 0" }}>
        <CreatableSelect
          isMulti
          options={options}
          value={selectedEmails}
          onChange={setSelectedEmails}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Enter emails to invite"
          formatCreateLabel={userInput => `Invite ${userInput}`}
        />
      </div>
      <div style={{ flexWrap: 'nowrap', padding: '8px 0' }}>
        <Select
          mode="multiple"
          placeholder="Select teams"
          value={selectedTeams}
          options={teams.map((team) => ({
            value: team.id,
            label: team.name,
          }))}
          onChange={setSelectedTeams}
          style={{ width: '100%' }}
        />
      </div>
      <div style={{ flexWrap: 'nowrap', padding: '8px 0' }}>
        <Select
          mode="single"
          placeholder="Select Role"
          value={inviteRole}
          options={[
            { value: "admin", label: "Admin (full access all expenses and edit access)" },
            { value: "member", label: "Member (limited access to team information only)" },
          ]}
          onChange={setInviteRole}
          style={{ width: '100%' }}
        />
      </div>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </Modal>
  );
};

export default MemberAssignmentModal;
