import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Select, Table, Input, Form, Checkbox, message } from "antd";
import { Card, Switch, Button, Tooltip } from "antd";
import { Space, Typography } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const { Option } = Select;
const { Search } = Input;

const Transactions = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [cards, setCards] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [businessDetails, setBusinessDetails] = useState('');
  const [tags, setTags] = useState('');

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const handleSubmit = async () => {
    const details = {
      business_details: businessDetails,
      tags: tags,
      transactions: selectedRows,
    };
    try {
      // submit the details to the server
      await axios.post(process.env.REACT_APP_API_BASE_URL + `/api/v1/cards/${selectedCard.id}/business_classifications`, 
                        details,
                        { withCredentials: true }
                      );
      message.success("New subsciptions lookups created")
    } catch (error) {
      console.error(error);
      message.error("Unable to classify your transactions at this time")
    }
  };

  // also in Cards.js
  const handleToggleMonitoring = async (cardId) => {
    try {
      const response = await axios.patch(
        process.env.REACT_APP_API_BASE_URL + `/api/v1/cards/${cardId}`,
        {
        },
        { withCredentials: true }
      );
      
      // console.log(response.data);
      const monitoring_active = response.data.monitoring_active
      // Update the cards state to reflect the updated monitoring status
      setCards((prevCards) =>
        prevCards.map((card) =>
          card.id === cardId ? { ...card, monitoring_active: monitoring_active } : card
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch the list of cards and transactions
  useEffect(() => {
    // Fetch cards
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/api/v1/cards",{ withCredentials: true })
      .then((response) => {
        setCards(response.data.cards);
      })
      .catch((error) => {
        console.error("Error fetching cards:", error);
      });

    // Fetch transactions for the selected card
    if (selectedCard) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            `/api/v1/cards/${selectedCard.id}/transactions`,
            { withCredentials: true }
        )
        .then((response) => {
          setTransactions(response.data.transactions);
          setFilteredTransactions(response.data.transactions);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
        });
    }
  }, [selectedCard]);
  return (
    <>
      <div>
        <Row>
          <Col className="gutter-row" span={5}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a card"
              optionFilterProp="children"
              // onSelect={setSelectedCard}
              onSelect={(value) => {
                const card = cards.find((card) => card.id === value);
                setSelectedCard(card);
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cards.map((card) => (
                <Option key={card.id} value={card.id}>
                  {`${card.embossed_name} - ${card.card_number.slice(-4)}`}
                </Option>
              ))}
            </Select>
          </Col>
          <Col className="gutter-row" span={5}>
            {selectedCard && (
              <Search
                placeholder="Search transactions"
                onChange={(e) => {
                  const searchTerm = e.target.value;
                  setFilteredTransactions(
                    searchTerm
                      ? transactions.filter((transaction) =>
                          transaction.description
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                      : transactions
                  );
                }}
              />
            )}
          </Col>
          <Col className="gutter-row" span={11}>
          </Col>
          <Col className="gutter-row" span={2}>
            {selectedCard ? (
              <>
                <Text>
                  Monitoring:
                </Text>
              </>
            ) : (
              <></>
            )}
          </Col>
          <Col className="gutter-row" span={1}>
            {selectedCard ? (
                <Switch 
                  checked={ cards.find(card => card.id === selectedCard.id)?.monitoring_active }
                  onChange={() => handleToggleMonitoring(selectedCard.id)}
                />
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Table
          dataSource={filteredTransactions}
          rowKey="id"
          style={{ marginTop: "16px" }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={[
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
              filterDropdown: () => (
                <div style={{ padding: 8 }}>
                  <Search
                    placeholder="Search description"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onSearch={() =>
                      setFilteredTransactions(
                        transactions.filter((transaction) =>
                          transaction.description
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                      )
                    }
                    style={{ width: 200 }}
                  />
                </div>
              ),
              filterIcon: () => null,
            },
            {
              title: "Transaction Type",
              dataIndex: "transaction_type",
              key: "transaction_type",
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
            },
            {
              title: "Card Number",
              dataIndex: "card_number",
              key: "card_number",
            },
            {
              title: "Transaction Date",
              dataIndex: "transaction_date",
              key: "transaction_date",
            },
            {
              title: "Amount",
              dataIndex: "amount_formatted",
              key: "amount",
            },
          ]}
        />
      </div>
      
      { selectedRows.length > 0 ? (
        <Form layout="inline">
        <Form.Item>
            <Input
              value={businessDetails}
              onChange={(e) => setBusinessDetails(e.target.value)}
              placeholder="Name this selection"
            />
          </Form.Item>
          <Form.Item>
            <Input
              value={tags}
              key="tags"
              onChange={(e) => setTags(e.target.value)}
              placeholder="Tags"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      ):(
        <Text >Please select a card and transactions to start classifying them into categories</Text>
      )}
    </>
  );
};

export default Transactions;
