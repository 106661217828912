import React, { useState } from "react";
import moment from 'moment';
import {
  Table,
  Switch,
  Row,
  Col,
  Button,
  Card,
  Timeline,
  Statistic,
  Space,
  Progress,
  Tag,
  Tooltip,
  Select, message,
  Skeleton,
  Typography,
  Empty,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  MinusCircleOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
  MessageOutlined,
  UpCircleTwoTone,
  DownCircleTwoTone,
  EyeInvisibleOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  BugOutlined,
} from "@ant-design/icons";
import TimeSince from "./TimeSince";
import { stringToColor } from "./colorUtils";
import axios from 'axios';
import ConfirmSwitch from "./ConfirmSwitch";
import SubsDetailDatePicker from "./SubsDetailDatePicker";
import SubsDetailInput from "./SubsDetailInput";
import SubsDetailBillingRelatedContent from "./SubsDetailBillingRelatedContent";
import Notes from "./Notes";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Text, Title } = Typography;
const { Countdown } = Statistic;
const { Column } = Table;
const { Option } = Select;
const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Dayjs is also OK

const SubscriptionsTable = ({
  subscriptions,
  transactions,
  onToggle,
  onRemove,
  onRowExpand,
  users,
  onAssignUser,
  selectedCard,
  hideCardNameColumn,
}) => {
  const screens = useBreakpoint();
  const formatLink = (link) => {
    if (link === null || link === undefined) {
      return ''; // or some default value
    }
    
    if (link.startsWith("http://") || link.startsWith("https://")) {
      return new URL(link).origin;
    } else if (link.startsWith("www.")) {
      return new URL(`https://${link}`).origin;
    } else {
      return link;
    }
  };

  const renderAssignUser = (text, record) => {
    const handleChange = (value) => {
      if (value === "") {
        value = null;
      }
      onAssignUser(record.subscription_id, value);
    };
  
    return (
      <Select 
        defaultValue={record.user?.name} 
        style={{ width: 120 }} 
        onChange={handleChange}
        placeholder=""
      >
        <Option key="unset" value="">
        </Option>
        {users.map(user => (
          <Option key={user.id} value={user.id}>
            {user.name || user.preferred_name || user.email}
          </Option>
        ))}
      </Select>
    );
  };
  

  const renderLikelihood = (probability) => (
    <Tooltip
      title={`Based on your historical card transactions, we have analyzed the likelihood of this being a subscription. Please note that this is an estimate and not a definitive confirmation.`}
    >
      <Progress
        percent={probability * 100}
        strokeColor={{
          from: '#108ee9',
          to: '#87d068',
        }}
        status="active"
      />
    </Tooltip>
  );

  const renderCategories = (categoryString) => {
    if (categoryString === null) {
      return null;
    }
    const categories = categoryString.split(",");
    return categories.map((category) => (
      // <Tag color={stringToColor(category)} key={category}>
      <Tag key={category}>
        {category}
      </Tag>
    ));
  };

  const renderNameAndDomain = (text, record) => {
    const { name, domain } = record;
    return (
      <Space>
        <Text>{name}</Text>
      </Space>
    );
  };

  const renderPaymentControl = (text, record) => {
    if (selectedCard.is_programmable !== true) {
      return (
        <Tooltip
          title={
            <p>
              Blocking future card payments is only available for programmable cards.
              <a href="/account/organization/cardholders"> Go to Cardholders</a>
            </p>
          }
        >
          <p style={{ cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.25)' }}>
            Payments cannot be paused or enabled.
          </p>
        </Tooltip>
      );
    } else {
      <ConfirmSwitch record={record} onToggle={onToggle} />
    }


    return(
    <Tooltip
      title={ 
        selectedCard.is_programmable !== true ? ("Blocking future card payments is only available for programmable cards. Please configure this on Investec Online.") : (
          record.active 
            ? "Click to block future payments to this service. You're in control."
            : "You are blocking future card payments to this service. Please note that you are still required to cancel the subscription agreement with the provider directly."
        )
      }
    >
      <ConfirmSwitch record={record} onToggle={onToggle} toggleable={selectedCard.is_programmable} />
    </Tooltip>
  )};

  const renderPaymentControlStatus = (text, record, is_programmable) => {
    return(
    <Tooltip
      title={ 
        !is_programmable ? (
          <p>
            Blocking future card payments is only available for programmable cards.
            <a href="/account/organization/cardholders"> Go to Cardholders</a>
          </p>
        ) : (
          record.active 
            ? "Payments are active for this service on this card."
            : "This provider can no longer charge your card. Please contact the service provider directly for billing contract queries."
        )
      }
    >
      { 
        (!record.active) ? (
          <Tag icon={<PauseCircleOutlined />} color="red">
            Paused
          </Tag>
        ) : ( 
          <Tag icon={<PlayCircleOutlined />} color="green">
            Active
          </Tag>
        )
      }
    </Tooltip>
  )};

  const handleFeedbackClick = async (record) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/feedbacks`, {
        feedback: {
          message: `User Reported Issue: Subscription ID ${record.subscription_id}, Business ID ${record.id}`,
          rating: -1,
        }
      }, {
        withCredentials: true,
      });
  
      if (response.status === 201) {
        message.success("Thank you for your feedback.");
      } else {
        message.error("Failed to submit feedback.");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      message.error("An error occurred while submitting feedback.");
    }
  };

  return (
    <>
    { 
      screens.md ? (
        <Table
          dataSource={subscriptions}
          rowKey="subscription_id"
          rowClassName={(record) =>
            record.active === false ? "inactive-subscription" : ""
          }
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleTwoTone onClick={e => onExpand(record, e)} style={{ fontSize: '18px' }} />
            ) : (
              <DownCircleTwoTone onClick={e => onExpand(record, e)} style={{ fontSize: '18px' }}/>
            ),
            expandedRowRender: (record) => {
              return record.transactions && record.transactions.length >= 0 ? (
                <>
                  <Row>
                    <Col className="gutter-row" span={13}>
                      <Card>
                        <Row>
                          <Col span={8}>
                            <Statistic
                              title="Number of payments"
                              value={record.metadata.count}
                            />
                          </Col>
                          <Col span={8}>
                            <Statistic
                              title="Total Spend"
                              value={record.metadata.total}
                            />
                          </Col>
                          <Col span={8}>
                            <Statistic
                              title="Average Monthly Spend"
                              value={record.metadata.average_monthly_spend}
                            />
                          </Col>
                        </Row>
                        <Row>
                          {/* <TimeSince date={new Date(record.transactions[0].transaction_date)} /> */}
                          <Statistic
                            title="Estimated Frequency"
                            value={
                              record.metadata.frequency
                                ? `${record.metadata.frequency} days`
                                : "Unknown"
                            }
                          />
                        </Row>
                        <Row>
                          <Col span={12}>
                            <Statistic
                              title="Last payment date"
                              value={record.metadata.last_payment_date || "Not yet billed"}
                            />
                          </Col>
                          <Col span={12}>
                            <Countdown
                              title="Estimated time until next payment run"
                              value={
                                new Date(record.metadata.estimated_next_payment_date)
                              }
                              format="D . HH . mm . ss"
                            />
                          </Col>
                        </Row>
                      </Card>
                      <Card>
                        { record.transactions.length == 0 ? (
                            <div style={{ textAlign: 'center', padding: '20px' }}>
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
                              <Text type="secondary" style={{ fontSize: '16px', marginTop: '16px' }}>
                                Your card transactions will appear here when you're billed
                              </Text>
                            </div>
                          ):(
                            <Timeline
                              mode="left"
                              items={record.transactions.map((transaction) => ({
                                children: `${transaction.transaction_date} : ${transaction.amount_formatted} : ${transaction.description}`,
                              }))}
                            />
                          ) 
                        }
                        
                        <div style={{ textAlign: 'right', marginTop: '10px' }}>
                          <Button
                            type="link"
                            onClick={() => handleFeedbackClick(record)}
                            icon={<BugOutlined />}
                            size="small"
                          >
                            Seeing something odd with the transactions?
                          </Button>
                        </div>
                      </Card>
                    </Col>

                    <Col className="gutter-row" span={11}>
                      <Card>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ flex: 1, paddingRight: '20px', borderRight: '1px solid #ccc' }}>
                            {renderPaymentControl("", record)}
                          </div>
                          <div style={{ flex: 1, paddingLeft: '20px' }}>
                            <Tooltip title="You can always start tracking this again">
                              <Button
                                type="dashed"
                                danger
                                icon={<EyeInvisibleOutlined />}
                                onClick={() => onRemove(record)}
                                style={{ borderColor: 'red', color: 'red' }}
                              >
                                Hide from tracker
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                        <SubsDetailBillingRelatedContent record={record} selectedCard={selectedCard} />
                      </Card>
                      <Notes subscriptionId={record.subscription_id} />
                    </Col>
                  </Row>
                </>
              ) : (
                <Skeleton active paragraph={{ rows: 4 }} />
              );
            },
            onExpand: onRowExpand,
          }}
        >
          <Column
            title={
              <Tooltip title="To cancel your subscription, navigate to the service provider from here">
                <span>Service </span>
                <QuestionCircleOutlined />
              </Tooltip>
            }
            dataIndex="name"
            key="name"
            sorter={(a, b) => a.name.localeCompare(b.name)}
            render={renderNameAndDomain}
          />
          {/* <Column
            title={
              <Tooltip title="from (unlikely) 0% to 100% (definitely) that this is a subscription on your card">
                <span>Likelihood </span>
                <QuestionCircleOutlined />
              </Tooltip>
            }
            key="likelihood"
            render={(text, record) =>
              renderLikelihood(record.likelihood_of_subscription)
            }
          /> */}
          <Column
            title={
              <Tooltip title="Tags we've applied to view the types of services you're subscribed to">
                <span>Categories </span>
                <QuestionCircleOutlined />
              </Tooltip>
            }
            dataIndex="category_of_subscription"
            key="category_of_subscription"
            filters={Array.from(new Set(
              Array.isArray(subscriptions) 
              ? subscriptions.flatMap(record => record.category_of_subscription.split(',')) 
              : []))
              .map(category => ({
                text: category,
                value: category,
              }))
            }
            onFilter={(value, record) => record.category_of_subscription.split(',').includes(value)}
            render={renderCategories}
          />
          <Column
            title={
              <Tooltip title="Block or allow future payments to the provider. You're in control.">
                <span>Payment Status </span>
                <QuestionCircleOutlined />
              </Tooltip>
            }
            key="paymentControl"
            filters={[{text: "Paused", value: 1}, {text: "Active", value: 0}]}
            onFilter={(value, record) => record.active !== Boolean(value) }
            render={(text, record) => renderPaymentControlStatus(text, record, selectedCard.is_programmable)}
          />
          <Column
            title="Card Name"
            key="card_name"
            dataIndex="card_name"
            render={(text) => <span>{text}</span>}
            {...(hideCardNameColumn ? { className: 'hidden-column' } : {})}
          />
          <Column
            title={
              <Tooltip title="If someone other than the cardholder manages this recurring spend, assign them so that they can manage the items.">
                <span>Assign Owner </span>
                <QuestionCircleOutlined />
              </Tooltip>
            }
            key="assignUser"
            render={renderAssignUser}
          />
          {/* <Column
            title={
              <Tooltip title="The frequency at which you are billed for this subscription.">
                <span>Billing Frequency <QuestionCircleOutlined /></span>
              </Tooltip>
            }
            key="billingFrequency"
            dataIndex="billing_frequency"
            render={(frequency) => frequency}
          />

          <Column
            title={
              <Tooltip title="The date when your subscription contract is set to renew.">
                <span>Renewal Date <QuestionCircleOutlined /></span>
              </Tooltip>
            }
            key="renewalDate"
            dataIndex="renewal_date"
            render={(date) => date ? moment(date).format('YYYY-MM-DD') : ''}
          />

          <Column
            title={
              <Tooltip title="The date you have scheduled to be reminded about this subscription.">
                <span>Reminder Date <QuestionCircleOutlined /></span>
              </Tooltip>
            }
            key="reminderDate"
            dataIndex="reminder_date"
            render={(date) => date ? moment(date).format('YYYY-MM-DD') : ''}
          /> */}
          {/* <Column
            title=""
            key="remove"
            render={(text, record) => (
              <Tooltip title="You can always start tracking this again">
                <Button type="link" danger onClick={() => onRemove(record)}>
                  Hide from tracker
                </Button>
              </Tooltip>
            )}
          /> */}
        </Table>
      ) : (
        <Table
          dataSource={subscriptions}
          rowKey="subscription_id"
          rowClassName={(record) =>
            record.active === false ? "inactive-subscription" : ""
          }
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleTwoTone onClick={e => onExpand(record, e)} style={{ fontSize: '18px' }} />
            ) : (
              <DownCircleTwoTone onClick={e => onExpand(record, e)} style={{ fontSize: '18px' }}/>
            ),
            expandedRowRender: (record) => {
              return record.transactions && record.transactions.length >= 0 ? (
                <>
                  <Row>
                    <Col className="gutter-row" span={24}>
                      <Card>
                        <Row>
                          <Col span={8}>
                            <Statistic
                              title="Number of payments"
                              value={record.metadata.count}
                            />
                          </Col>
                          <Col span={16}>
                            <Statistic
                              title="Average Monthly Spend"
                              value={record.metadata.average_monthly_spend}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={20}>
                            <Statistic
                              title="Last payment date"
                              value={record.metadata.last_payment_date || "Not yet billed"}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="gutter-row" span={24}>
                      <Card>
                        <div>
                          <Row>
                            {selectedCard.is_programmable ? (
                              <>
                                <ConfirmSwitch record={record} onToggle={onToggle} />
                                <br></br>
                              </>
                            ):(
                              <>
                                <p style={{ cursor: 'not-allowed', color: 'rgba(0, 0, 0, 0.25)' }}>
                                  Payments cannot be paused or enabled.
                                </p>
                                <br></br>
                              </>
                            )}
                          </Row>
                          <Row>
                            <Button
                              type="dashed"
                              danger
                              icon={<EyeInvisibleOutlined />}
                              onClick={() => onRemove(record)}
                              style={{ borderColor: 'red', color: 'red' }}
                            >
                              Hide from tracker
                            </Button>
                          </Row>
                        </div>
                        <SubsDetailBillingRelatedContent record={record} selectedCard={selectedCard} />
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (
                <Skeleton active paragraph={{ rows: 4 }} />
              );
            },
            onExpand: onRowExpand,
          }}
        >
          <Column
            title={
              <>
                <span>Service</span>
              </>
            }
            dataIndex="name"
            key="name"
            render={renderNameAndDomain}
          />
          <Column
            title={
              <>
                <span>Payment Status </span>
              </>
            }
            key="paymentControl"
            render={(text, record) => renderPaymentControlStatus(text, record, selectedCard.is_programmable)}
          />
        </Table>
      )
    }
    </>
  );
};

export default SubscriptionsTable;
