import React, { useState } from 'react';
import { Popover, Switch, Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const ConfirmSwitch = ({ record, onToggle, toggleable=true }) => {
  const [visible, setVisible] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(!record.active);

  const hidePopover = () => setVisible(false);

  const handleVisibleChange = (newVisible) => {
    if (!{toggleable}) {
      return; // Do nothing if the switch is disabled
    }
    setVisible(newVisible);
  };

  const confirmToggle = (checked) => {
    onToggle(record, checked);
    setSwitchChecked(checked);
    hidePopover();
  };

  const content = (
    <div>
      <p>Are you sure you want to change the status?</p>
      <Button onClick={() => confirmToggle(!switchChecked)} type="primary" size="small">Confirm</Button>
      <Button onClick={hidePopover} size="small" style={{ marginLeft: 8 }}>Cancel</Button>
    </div>
  );

  return (
    <Popover
      content={content}
      title="Confirmation"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Switch
        checkedChildren={
          <div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CheckOutlined />
            <span style={{ marginLeft: 8 }}>Payments Paused</span>
          </div>
        }
        unCheckedChildren={
          <div style={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CloseOutlined />
            <span style={{ marginLeft: 8 }}>Payments Active</span>
          </div>
        }
        checked={switchChecked}
        disabled={toggleable !== true}
        onClick={() => setVisible(true)} // Assuming you have logic for setVisible
        style={{
          background: switchChecked ? 'red' : 'green', // Set the background color based on the checked state
          borderRadius: '12px', // Add some border-radius if you like rounded corners
          marginBottom: '12px'
        }}
        size="default" // You can change to "small" or remove for a larger switch
      />


    </Popover>
  );
};

export default ConfirmSwitch;
