import axios from "axios";

export const updateOrgName = async (orgId, newOrgName, newOrgDomain) => {
  try {
    await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${orgId}`,
      { name: newOrgName, domain: newOrgDomain },
      { withCredentials: true }
    );
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};
