import React from 'react';
import { ApiOutlined } from "@ant-design/icons";
import { Typography, Tooltip } from "antd";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Link } = Typography;

const InvestecOAuthButton = ({ userId }) => {
  const screens = useBreakpoint();
  const oauthUrl = `https://openapi.investec.com/identity/v2/oauth2/authorize?scope=accounts%20transactions%20balances%20cards&client_id=ufKvZhGqPDZzbCKnF9ThmDSg5DoebzIw&redirect_uri=https://api.subtrack.co.za/api/v1/investec/auth/callback&response_type=code&state=${userId}`;
  
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <a 
        href={oauthUrl}
        style={{
          alignItems: 'center',
          backgroundColor: '#000',
          borderRadius: '48px',
          color: '#fff',
          display: 'inline-flex',
          fontFamily: 'Lato, sans-serif',
          fontSize: '14px',
          fontWeight: '600',
          height: '44px',
          justifyContent: 'center',
          textDecoration: 'none',
          width: screens.xs ? '100%' : '204px', // Full width on mobile screens
          marginRight: screens.xs ? '0' : '10px', // Remove margin on mobile screens
          marginBottom: screens.xs ? '10px' : '0', // Add bottom margin on mobile screens
        }}
      >
        <ApiOutlined />
        &nbsp;Connect
      </a>
      <>
        <Tooltip title="If you meet the qualifying criteria, use this referral link">
          <Link href="https://referrals.investec.com/apply/sy5wt" target="_blank" rel="noopener noreferrer">or open an account</Link>
        </Tooltip>{" "}
      </>
    </div>
  );
};

export default InvestecOAuthButton;
