import { Typography, Space, List } from "antd";
import {
  RightOutlined,
} from "@ant-design/icons";

const { Text } = Typography;
const { Title } = Typography;

const StartingPage = () => {
  return (
    <>
    <Space direction="vertical">
      <Title level={2}>
        Welcome to Subtrack - Effortlessly Manage Your Subscriptions
      </Title>
      <Text>
        Subtrack is a powerful application built on top of Investec's Programmable
        Banking Open API. Designed for professionals like you, it
        provides a seamless and streamlined way to manage your subscriptions using
        virtual and programmable cards. With Subtrack, you can reduce the noise of
        other transactions and take control of your subscription expenses with ease.
      </Text>

      <Title level={4}>
        1. Activate Programmable Banking on your Investec Account
      </Title>
      <List bordered>
        <List.Item>
          <Typography.Text>
            <RightOutlined />
          </Typography.Text>{" "}
          Log in to your Investec Online account
        </List.Item>
        <List.Item>
          <Typography.Text>
            <RightOutlined />
          </Typography.Text>{" "}
          Navigate to the "Tools" section and select "Programmable Banking"
        </List.Item>
        <List.Item>
          <Typography.Text>
            <RightOutlined />
          </Typography.Text>{" "}
          Accept the terms and conditions to activate Programmable Banking
        </List.Item>
        <List.Item>
          <Typography.Text>
            <RightOutlined />
          </Typography.Text>{" "}
          Enable the desired card for Programmable Banking by toggling the button
        </List.Item>
      </List>

      <Title level={4}>2. Obtain Your API Credentials</Title>
      <Text>
        Once you have activated Programmable Banking, you can retrieve your API
        credentials from your Investec Online account. These credentials are
        essential for secure programmatic access to your Investec Account.
      </Text>

      <Title level={4}>For Private Banking Account Holders</Title>
      <List bordered>
        <List.Item>
          <Typography.Text>
            <RightOutlined />
          </Typography.Text>{" "}
          Go to "Tools" <RightOutlined /> "Programmable Banking"{" "}
          <RightOutlined /> "Individuals Connections"
        </List.Item>
        <List.Item>
          <Typography.Text>
            <Text>You will find your "Client ID" and "Client Secret" there</Text>
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            Create an "API Key" with the following permissions: "Read Cards",
            "Execute Card Code", "View Accounts", and "View Transactions"
          </Typography.Text>
        </List.Item>
      </List>

      <Title level={4}>For CIB Account Holders</Title>
      <List bordered>
        <List.Item>
          <Typography.Text>
            <RightOutlined />
          </Typography.Text>{" "}
          Go to "Tools" <RightOutlined /> "Programmable Banking"{" "}
          <RightOutlined /> "Business Connections"
        </List.Item>
        <List.Item>
          <Typography.Text>
            <Text>You will find your "Client ID" and "Client Secret" there</Text>
          </Typography.Text>
        </List.Item>
        <List.Item>
          <Typography.Text>
            Create an "API Key" with the following permissions: "Read Cards",
            "Execute Card Code", "View Accounts", and "View Transactions"
          </Typography.Text>
        </List.Item>
      </List>

      <Text strong>
        Please note that only the main account holder will have access to these
        credentials and the ability to create new API keys.
      </Text>
    </Space>
    </>
  );
};

export default StartingPage;
