import React, { useState } from 'react';
import { Form, Input, Button, Rate, Typography, message } from 'antd';
import { useAuth } from "./AuthProvider";
import axios from 'axios';

const { Title, Text } = Typography;

const FeedbackForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      // Use axios to make a POST request
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/feedbacks', values, {
        withCredentials: true,
      });

      // Check if the request was successful
      if (response.status === 201) {
        message.success('Thank you for your feedback!');
        form.resetFields();
      } else {
        message.error('Something went wrong. Please try again.');
        // console.error(response.data.errors);
      }
    } catch (error) {
      message.error('An error occurred. Please try again.');
      // console.error(error);
    }

    setLoading(false);
  };

  return (
    <>
      <div style={{ maxWidth: '500px', margin: 'auto', padding: '20px' }}>
        <Title level={3}>We'd love to hear your thoughts!</Title>
        <Text>Rate your experience and leave comments below.</Text>

        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item name="rating" label="How would you rate your experience?" rules={[{ required: true, message: 'Please rate your experience.' }]}>
            <Rate />
          </Form.Item>

          <Form.Item name="message" label="Comments or suggestions" rules={[{ required: true, message: 'Please share your thoughts.' }]}>
            <Input.TextArea rows={4} placeholder="Share your thoughts with us..." />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default FeedbackForm;
