import React, { useState, useCallback } from 'react';
import { Input, DatePicker, Select, message } from 'antd';
import debounce from 'lodash/debounce';
import axios from 'axios';

// Example custom input component for billing frequency
const SubsDetailInput = ({ initialValue, subscriptionId, cardId, fieldName, options, label, onChangeArg}) => {
  const [value, setValue] = useState(initialValue);

  const handleSave = async (newValue) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${cardId}/subscriptions/${subscriptionId}`,
        { [fieldName]: newValue },
        { withCredentials: true }
      );
      message.success("Subscription updated successfully");
    } catch (error) {
      message.error("Error updating subscription");
    }
  };

  // Debounce the save function
  const debouncedSave = useCallback(debounce(handleSave, 1000), []);

  const handleChange = (newValue) => {
    setValue(newValue);
    debouncedSave(newValue);
    if (onChangeArg) {
      onChangeArg(newValue); // Pass the new value to the callback
    }
  };

  return (
    <Select placeholder={label} value={value || undefined} onChange={handleChange} style={{ width: '100%' }}>
      {options.map(option => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SubsDetailInput;
