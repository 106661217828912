import React, { useState } from "react";
import {
  Button,
  Input,
  message,
} from "antd";
import { Row, Col } from "antd";

import axios from "axios";

const InviteForm = () => {
  const [members, setMembers] = useState([]);
  const [inviteEmail, setInviteEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState('');

  const handleInvite = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!inviteEmail || /\s/.test(inviteEmail)) {
      setErrorMessage('Invalid email: cannot be empty or contain whitespaces.');
      return;
    }
  
    if (!emailPattern.test(inviteEmail)) {
      setErrorMessage('Invalid email format.');
      return;
    }

    axios
      .post(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/invites",
        {
          email: inviteEmail,
          // Add other fields as required
        },
        { withCredentials: true }
      )
      .then((response) => {
        setMembers([
          ...members,
          { id: response.data.invite.id, email: inviteEmail, name: "Pending invite", teams: [] },
        ]);
        setInviteEmail("");
        setErrorMessage('');
        message.success("Member invited.");
      })
      .catch((error) => {
        message.error("Failed to invite member.");
      });
  };

  return (
      <Row justify="space-between">
        <Col>
          <Row>
            <Col>
              <Input placeholder="Enter email to invite" value={inviteEmail} onChange={(e) => setInviteEmail(e.target.value)} />
              {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  handleInvite()
                }}
              >
                Invite
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
  );
};

export default InviteForm;
