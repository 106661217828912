import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Tag, Button, Select, Typography, Checkbox, Input, message, Row, Col } from 'antd';

const { Title, Text } = Typography;
const { Option } = Select;

const NotificationSettings = () => {
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [webhookURL, setWebhookURL] = useState('');
  const [notificationTypes, setNotificationTypes] = useState([
    'blocked transaction',
    'new transaction logged',
    'new subscription detected',
  ]);
  const [selectedNotificationTypes, setSelectedNotificationTypes] = useState([]);

  useEffect(() => {
    // Fetch teams when component mounts
    const fetchTeams = async () => {
      try {
        const response = await axios.get('/api/v1/teams', { withCredentials: true });
        setTeams(response.data);
      } catch (error) {
        message.error('Failed to fetch data.');
      }
    };

    fetchTeams();
  }, []);

  const handleTeamSelect = (teamId) => {
    setSelectedTeam(teamId);
    // Fetch team members based on the selected team
    axios
      .get(`/api/v1/teams/${teamId}/members`, { withCredentials: true })
      .then((response) => {
        setTeamMembers(response.data.members);
      })
      .catch((error) => {
        message.error('Failed to fetch team members.');
      });
  };

  const handleSaveNotificationSettings = () => {
    if (!selectedTeam || !webhookURL || selectedNotificationTypes.length === 0) {
      message.warning('Please fill in all required fields.');
      return;
    }

    // Here you could send your settings to the server
    axios
      .post(
        '/api/v1/notification_settings',
        {
          teamId: selectedTeam,
          webhookURL,
          types: selectedNotificationTypes,
        },
        { withCredentials: true }
      )
      .then((response) => {
        message.success('Notification settings saved.');
      })
      .catch((error) => {
        message.error('Failed to save notification settings.');
      });
  };

  return (
    <>
      <Title level={3}>Notification Settings</Title>
      <div style={{ marginBottom: '20px' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Select
              value={selectedTeam}
              onChange={handleTeamSelect}
              placeholder="Select Team"
              style={{ width: '100%' }}
            >
              {teams.map((team) => (
                <Option key={team.id} value={team.id}>
                  {team.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={12} md={16}>
            <Text strong>Team Members:</Text>
            <div>
              {teamMembers.map((member) => (
                <Tag key={member.id} style={{ marginBottom: '5px' }}>
                  {member.name}
                </Tag>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8}>
            <Text strong>Webhook URL:</Text>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <Input
              placeholder="Enter Webhook URL"
              style={{ width: '100%' }}
              value={webhookURL}
              onChange={(e) => setWebhookURL(e.target.value)}
            />
          </Col>
        </Row>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Text strong>Select Notification Types:</Text>
            <Checkbox.Group
              options={notificationTypes}
              value={selectedNotificationTypes}
              onChange={(checkedValues) => setSelectedNotificationTypes(checkedValues)}
              style={{ display: 'block' }}
            />
          </Col>
        </Row>
      </div>
      <Button
        type="primary"
        onClick={handleSaveNotificationSettings}
        style={{ marginTop: '20px' }}
      >
        Save Settings
      </Button>
    </>
  );
};

export default NotificationSettings;
