import React, { useState } from "react";
import { Form, Input, Button, Typography, Row, Col, message } from "antd";

const { Text } = Typography;

const OrgSettingsForm = ({ orgName, newOrgName, setNewOrgName, orgDomain, newOrgDomain, setNewOrgDomain, handleOrgNameAndDomainChange }) => {
  const [isDomainValid, setIsDomainValid] = useState(true);

  const validateDomain = (domain) => {
    // Regular expression to validate domain format
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    return domainRegex.test(domain);
  };

  const handleDomainChange = (e) => {
    const value = e.target.value.trim();
    if (value === '') {
      setNewOrgDomain(null);
      setIsDomainValid(true);
    } else {
      setNewOrgDomain(value);
      setIsDomainValid(validateDomain(value));
    }
  };

  const handleSave = () => {
    if (!newOrgDomain || newOrgDomain.trim() === '') {
      setNewOrgDomain(null);
    }

    if (isDomainValid) {
      handleOrgNameAndDomainChange();
    } else {
      // Optionally, you can add more error handling here
      message.error('Please specify a valid domain');
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Text>Human-friendly label for your organisation, shown in team invites and user interfaces</Text>
          <Input 
            placeholder="Enter new organisation name"
            defaultValue={orgName}
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
        </Col>
        <Col xs={24}>
          <Text>Limit team member invites to only be sent email addresses on your domain.</Text>
          <Form.Item
            validateStatus={isDomainValid ? '' : 'error'}
            help={isDomainValid ? '' : 'Please enter a valid domain.'}
            style={{ marginBottom: "20px" }}
          >
            <Input 
              prefix="@"
              placeholder="your-domain.com"
              defaultValue={orgDomain}
              value={newOrgDomain}
              onChange={handleDomainChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default OrgSettingsForm;
