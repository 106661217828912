import React from 'react';
import { SlackOutlined } from "@ant-design/icons";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const SlackOAuthButton = ({ userId }) => {
  const screens = useBreakpoint();
  const oauthUrl = `https://slack.com/oauth/v2/authorize?client_id=338715370387.5983732420454&scope=channels:read,groups:read,chat:write,chat:write.public,users:read,users:read.email&user_scope=&state=${userId}`;
  
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <a 
        href={oauthUrl}
        style={{
          alignItems: 'center',
          backgroundColor: '#4A154B',
          borderRadius: '48px',
          color: '#fff',
          display: 'inline-flex',
          fontFamily: 'Lato, sans-serif',
          fontSize: '14px',
          fontWeight: '600',
          height: '44px',
          justifyContent: 'center',
          textDecoration: 'none',
          width: screens.xs ? '100%' : '204px', // Full width on mobile screens
          marginRight: screens.xs ? '0' : '10px', // Remove margin on mobile screens
          marginBottom: screens.xs ? '10px' : '0', // Add bottom margin on mobile screens
        }}
      >
        <SlackOutlined />
        &nbsp;Connect
      </a>
    </div>
  );
};

export default SlackOAuthButton;
