import React, { useState, useEffect } from 'react';
import { Menu, Typography, Button, Modal } from 'antd';
import {
  AppstoreOutlined,
  UserOutlined,
  SettingOutlined,
  CreditCardOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Text } = Typography;

const UserMenu = ({ user, handleSignOut, navigate }) => {
  const screens = useBreakpoint();
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showFallbackUI, setShowFallbackUI] = useState(false);
  const [menuVisible, setMenuVisible] = useState(true);

  useEffect(() => {
    console.log("userMenu useEffect is running...");
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      console.log('beforeinstallprompt event captured');
      setDeferredPrompt(e);
      setShowFallbackUI(false); // Hide fallback UI if event is captured
    };

    console.log('userMenu useEffect main body');
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      console.log('userMenu useEffect return statement');
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    console.log("clicked install");
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null); // Clear the prompt as it can only be used once
      });
    } else {
      console.log('deferredPrompt is null');
    }
  };

  if (!user) {
    return null;
  }

  const menuContent = (
    <Menu theme="light">
      <Menu.Item>
        <Text type="secondary">{user.email || 'Email'}</Text>
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/account/organization/settings')}>
        <strong>Org: {user.organization.name || 'Organization Name'}</strong>
      </Menu.Item>
      <Menu.Divider />
      {deferredPrompt && (
        <>
          <Menu.Item onClick={handleInstallClick}>
            Add to Home Screen
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item onClick={() => {setShowFallbackUI(true)}}>
        <img src={`${process.env.PUBLIC_URL}/install-icon.png`} alt="Install Icon" style={{ width: 15, height: 15, marginTop: 5 }} /> Install app
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => navigate('/account/organization/settings')}>
        <SettingOutlined /> Manage Account
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/account/organization/members')}>
        <UserOutlined /> Invite Team
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/account/organization/cardholders')}>
        <CreditCardOutlined /> Cardholders
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/account/organization/teams')}>
        <NotificationOutlined /> Teams and Notifications
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/account/organization/integrations')}>
        <AppstoreOutlined /> Manage Integrations
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={() => navigate('/account/user/feedback')}>
        Give Feedback
      </Menu.Item>
      <Menu.Item onClick={handleSignOut}>
        <Button type="link" onClick={handleSignOut}>Logout</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {screens.md ? (menuVisible && menuContent) : (menuVisible && menuContent)}
      {showFallbackUI && (
        <Modal
          title="Add to your mobile Home Screen or Desktop"
          // open={showFallbackUI}
          open={() => {
            if(showFallbackUI){
              setMenuVisible(false);
              return true;
            };
          }}
          onCancel={() => {
            setShowFallbackUI(false);
            setMenuVisible(true);
          }}
          footer={[
            <Button key="ok" onClick={() => setShowFallbackUI(false)}>
              OK
            </Button>
          ]}
        >
          <p>To add this app to your home screen, please follow these steps:</p>
          <ul>
            <li><strong>On iOS:</strong> Tap the "Share" button at the bottom of the screen and then tap "Add to Home Screen".</li>
            <li><strong>On Android:</strong> Tap the menu button (three dots) in the top right and then tap "Add to Home Screen".</li>
            <li><strong>On Desktop:</strong> At the top right of the address bar, select Install 
              <img src={`${process.env.PUBLIC_URL}/install-icon.png`} alt="Install Icon" style={{ width: 20, height: 20, marginLeft: 5 }} />
            </li>
          </ul>
        </Modal>
      )}
    </>
  );
};

export default UserMenu;
