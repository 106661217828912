import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Checkbox, Select } from 'antd';
import {
  LockOutlined,
  BorderlessTableOutlined,
  SlackOutlined,
  WindowsOutlined
} from "@ant-design/icons";
import axios from 'axios';

const { Option } = Select;

const NotificationModal = ({ visible, onCancel, saveNotificationConfig, notificationConfig, setNotificationConfig, eventTypeOptions }) => {
  const [channels, setChannels] = useState([]);
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [selectedService, setSelectedService] = useState('slack');

  useEffect(() => {
    if (visible) {
      // Fetch and cache channels when the modal opens
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/notification_channels`, { withCredentials: true })
        .then(response => {
          let fetchedChannels = [];
          if (selectedService === 'slack') {
            fetchedChannels = response.data.slack.map(channel => ({ 
              label: channel.name,
              value: channel.id,
              isPrivate: channel.is_private
            }));
          } else if (selectedService === 'splitwise') {
            fetchedChannels = response.data.splitwise.map(group => ({ 
              label: group.name,
              value: group.id,
              isPrivate: null // Splitwise groups don't have a private flag
            }));
          }
          
          setChannels(fetchedChannels);
          setFilteredChannels(fetchedChannels); // Initially, all channels are shown
        })
        .catch(error => {
          console.error('Error fetching channels:', error);
        });
    }
  }, [visible, selectedService]);

  const formatOptionLabel = ({ label, isPrivate }) => (
    <div>
      {isPrivate ? <LockOutlined /> : <BorderlessTableOutlined />} {label}
    </div>
  );

  const formatServiceLabel = ({ service }) => (
    <div>
      {service === 'slack' ? < SlackOutlined /> : <WindowsOutlined />} {service}
    </div>
  );

  const handleServiceChange = (value) => {
    setSelectedService(value);
    setNotificationConfig({
      ...notificationConfig,
      channel: value,
      destination: ""
    });
  };

  const handleChannelSearch = (value) => {
    const searchResult = channels.filter(channel =>
      channel.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredChannels(searchResult);
  };

  return (
    <Modal
      title="Configure Notifications"
      open={visible}
      onCancel={onCancel}
      onOk={() => saveNotificationConfig(notificationConfig)}
    >
      <Form>
        <Form.Item label="To: ">
          <Select
            // value={notificationConfig ? notificationConfig.channel : ""}
            // onChange={(value) =>
            //   setNotificationConfig({ ...notificationConfig, channel: value })
            // }
            value={notificationConfig.channel}
            onChange={handleServiceChange}
            formatOptionLabel={formatServiceLabel}
          >
            <Option value="slack">Slack</Option>
            <Option value="splitwise">Splitwise</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Channel">
          <Select
            showSearch
            onSearch={handleChannelSearch}
            value={notificationConfig.destination}
            onChange={value => setNotificationConfig({ ...notificationConfig, destination: value })}
            filterOption={false}
          >
            {filteredChannels.map(channel => (
              <Option key={channel.value} value={channel.value}>
                {channel.label} {channel.isPrivate ? "(private)" : "" }
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Subscribed alerts">
          <Checkbox.Group
            options={eventTypeOptions}
            value={notificationConfig.eventTypes}
            onChange={(values) =>
              setNotificationConfig({
                ...notificationConfig,
                eventTypes: values,
              })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NotificationModal;
