import React from "react";
import { Card, Typography, Row, Col } from "antd";
import { NotificationOutlined } from "@ant-design/icons";
import EventsTable from "./components/EventsTable";

const { Text } = Typography;

function Notifications() {
  return (
    <>
      <Card>
        <Row>
          <Col span={24}>
            <h3><NotificationOutlined />&nbsp; Activity and Notifications</h3>
            <Text type="secondary" style={{ display: 'block', marginBottom: '20px' }}>
              Recent activity related to your account
            </Text>
          </Col>
        </Row>
        <EventsTable />
      </Card>
    </>
  );
}

export default Notifications;
