import React, { useEffect } from "react";
import { Button, Typography, message } from "antd";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";
import { sendEmailVerification } from "firebase/auth";
import { getAuth } from "@firebase/auth";

const { Link } = Typography;

const VerifyEmailPage = () => {
  const auth = getAuth();
  const { user, signOutOfFirebase } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/sign_in"); // Redirect to sign-in if no user is authenticated
    }
  }, [user, navigate]);

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(user);
      message.success("Verification email sent successfully!");
    } catch (error) {
      message.error(error.message);
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const handleSignOut = async () => {
    await signOutOfFirebase();
  };

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <Typography.Title level={3}>Email Verification Required</Typography.Title>
      <p>
        Your email is not verified. Please <strong>verify it to continue</strong> or {' '}
        <Link onClick={handleSignOut}>
          sign out.
        </Link>
      </p>

      <Button type="primary" onClick={sendVerificationEmail} style={{ margin: "10px" }}>
        Re-send Verification Email
      </Button>
      <Button type="link" onClick={reloadPage} style={{ margin: "10px" }}>
        I've Verified, Reload Page
      </Button>
    </div>
  );
};

export default VerifyEmailPage;
