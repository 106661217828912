import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "./AuthProvider";
import SlackOAuthButton from "./components/SlackOAuthButton";
import SplitwiseOAuthButton from "./components/SplitwiseOAuthButton";
import InvestecOAuthButton from "./components/InvestecOAuthButton";
import { Button, Card, Divider, Form, Input, List, Collapse } from "antd";
import { Space, Typography } from "antd";
import { notification, message } from "antd";
import { Row, Col } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SecurityScanOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { useNavigate } from "react-router-dom";

const { Text, Link, Title } = Typography;

function Credentials() {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const [data, setData] = useState(null);
  const [slackConnectionStatus, setSlackConnectionStatus] = useState(null)
  const [splitwiseConnectionStatus, setSplitwiseConnectionStatus] = useState(null)
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const { currentUser, logout } = useAuth();
  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [formLayout, setFormLayout] = useState("horizontal");
  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 5 }, wrapperCol: { span: 19 } }
      : null;

  const buttonItemLayout =
    formLayout === "horizontal"
      ? { wrapperCol: { span: 19, offset: 5 } }
      : null;

  const fetchData = async () => {
    setFetchLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/credentials/check",
        { withCredentials: true }
      );
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setFetchLoading(false);
    }
  };

  const checkSlackConnectionStatus = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/credentials/slack/check",
        { withCredentials: true }
      );
      setSlackConnectionStatus(response.data);
    } catch (err) {
      setError(err.message);
    }
  };

  const checkSplitwiseConnectionStatus = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "/api/v1/credentials/splitwise/check",
        { withCredentials: true }
      );
      setSplitwiseConnectionStatus(response.data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/credentials', values, { withCredentials: true });
      await fetchData();  // Rerun the check after successfully sending the credentials
      if(data && data.connection) {
        notification.success({
          message: 'Success',
          description: 'Your credentials have been successfully updated and connected.',
          duration: 3,
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'There was an error updating your credentials. Please try again.',
          duration: 3,
        });  
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error',
        description: 'There was an error updating your credentials. Please try again.',
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    checkSlackConnectionStatus();
    checkSplitwiseConnectionStatus();
  }, []);

  if (error) {
    return <>An error occurred: {error}</>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Title level={2} >Banks</Title>
          <Card style={{ marginBottom: '16px' }}>
            <Title level={3}>Connect to Investec</Title>
            <Text>Connection Status: </Text>
            {data && !fetchLoading && !loading ? (
              data.connection ? (
                <>
                  <CheckCircleOutlined style={{ color: "green" }} />
                  {screens.md && <Text type="success">{" "}Successfully connected!</Text>}
                </>
              ) : (
                <CloseCircleOutlined style={{ color: "red" }} />
              )
            ) : (
              <LoadingOutlined style={{ color: "blue" }} />
            )}
            <div>
              <Text>Securely connect your Investec account to Subtrack</Text>
            </div>
            {currentUser ? <InvestecOAuthButton userId={currentUser.rotating_identifier} /> : <></>}
            <Collapse accordion>
              <Collapse.Panel header="Developer? Manually enter your programmable banking API Credentials to connect to Investec" key="1">
                <Form
                  {...formItemLayout}
                  layout={formLayout}
                  form={form}
                  initialValues={{ layout: formLayout }}
                  style={{ maxWidth: formLayout === "inline" ? "none" : 600 }}
                  onFinish={handleSubmit}
                >
                  <Form.Item label="Client Id" name="client_id" rules={[{ required: true, message: 'Can\'t be blank' }]} >
                    <Input.Password placeholder="1q***************************M0" style={{ width: '100%' }}/>
                  </Form.Item>
                  <Form.Item label="Client Secret" name="client_secret" rules={[{ required: true, message: 'Can\'t be blank' }]} >
                    <Input.Password placeholder="20************gZ" style={{ width: '100%' }}/>
                  </Form.Item>
                  <Form.Item label="API key" name="client_api_key" rules={[{ required: true, message: 'Can\'t be blank' }]} >
                    <Input.Password placeholder="Qm*****...*****AP==" style={{ width: '100%' }}/>
                  </Form.Item>
                  <Form.Item {...buttonItemLayout}>
                    <Button type="primary" htmlType="submit" loading={loading} disabled={currentUser && currentUser.role !== "admin"}>Submit</Button>
                  </Form.Item>
                </Form>
              </Collapse.Panel>
            </Collapse>
          </Card>
          <Card>
            <>
              <Text>Looking for another bank?</Text>
              <Button type="link" onClick={() => { navigate('/account/user/feedback');}}>Please let us know</Button>
            </>
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Title level={2} >Team Comms</Title>
          <Card style={{ marginBottom: '16px' }}>
            <Title level={3}>Connect to Slack</Title>
            <div>
              <Space>
                <Text>Connection Status: </Text>
                {slackConnectionStatus ? (
                  slackConnectionStatus.connection ? (
                    <>
                      <CheckCircleOutlined style={{ color: "green" }} />
                      { screens.md && <Text type="success">{" "}Successfully connected!</Text> }
                    </>
                  ) : (
                    <CloseCircleOutlined style={{ color: "red" }} />
                  )
                ) : (
                  <LoadingOutlined style={{ color: "blue" }} />
                )}
              </Space>
            </div>
            <div>
              <Text>Easily invite team mates and send notifications</Text>
            </div>
            {currentUser ? <SlackOAuthButton userId={currentUser.rotating_identifier} /> : <></>}
          </Card>

          <Card style={{ marginBottom: '16px' }}>
            <Title level={3}>Connect to Splitwise</Title>
            <div>
              <Space>
                <Text>Connection Status: </Text>
                {splitwiseConnectionStatus ? (
                  splitwiseConnectionStatus.connection ? (
                    <>
                      <CheckCircleOutlined style={{ color: "green" }} />
                      { screens.md && <Text type="success">{" "}Successfully connected!</Text> }
                    </>
                  ) : (
                    <CloseCircleOutlined style={{ color: "red" }} />
                  )
                ) : (
                  <LoadingOutlined style={{ color: "blue" }} />
                )}
              </Space>
            </div>
            <div>
              <Text>Easily invite other users to split bills with</Text>
            </div>
            {currentUser ? <SplitwiseOAuthButton userId={currentUser.rotating_identifier} /> : <></>}
          </Card>

          <Card>
            <>
              <Text>Looking for another integration?</Text>
              <Button type="link" onClick={() => { navigate('/account/user/feedback');}}>Please let us know</Button>
            </>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Credentials;
