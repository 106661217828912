import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppstoreOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  WalletOutlined,
  TeamOutlined,
  RetweetOutlined,
  UserOutlined,
  SettingOutlined,
  CreditCardOutlined,
  NotificationOutlined,
  AuditOutlined,
  DollarOutlined,
  ExpandOutlined,
  QuestionCircleOutlined,
  CommentOutlined,
  InfoCircleOutlined,
  RedoOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Layout, Menu, Drawer, theme } from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Content, Sider } = Layout;

function getItem(label, key, icon = null, path = "/", children = []) {
  return { key, icon, children, label, path };
}

const LayoutContainer = ({ children, cards }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { token: { colorBgContainer }, } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();
  const [current, setCurrent] = useState(
    location.pathname.split("/")[1] || "subscriptions"
  );
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    let newOpenKeys = [];
    if (location.pathname.startsWith('/subscriptions/')) {
      newOpenKeys = [...newOpenKeys, "by_card"];
    }
    if (location.pathname.startsWith('/account/organization/')) {
      newOpenKeys = [...newOpenKeys, "organization"];
    }
    if (location.pathname.startsWith('/reports/')) {
      newOpenKeys = [...newOpenKeys, "reports"];
    }
    if (location.pathname.startsWith('/account/user')) {
      if (location.pathname.startsWith('/account/user/support')) {
        newOpenKeys = [...newOpenKeys, "help"];
      } else if (location.pathname.startsWith('/account/user/feedback')) {
        newOpenKeys = [...newOpenKeys, "help"];
      } else if (location.pathname.startsWith('/account/user/tour')) {
        newOpenKeys = [...newOpenKeys, "help"];
      } else if (location.pathname.startsWith('/account/user/')) {
        newOpenKeys = [...newOpenKeys, "user"];
      }
    }
    setOpenKeys(newOpenKeys);
  }, [location]);
  
  const onClick = (e) => {
    navigate(e.item.props.path);
    setDrawerVisible(false);
    // setCurrent(e.key);
    // if (e.keyPath.length == 2 ) {
    //   if (!openKeys.includes(e.keyPath[1])) {
    //     setOpenKeys([...openKeys, e.keyPath[1]]);
    //   }
    // }
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    const route = location.pathname;

    const routeHighlights = {
      '/subscriptions/': (route) => {
        const cardId = route.split('/').pop();
        return `by_card_${cardId}`;
      },
      '/subscriptions': 'by_team',
      '/notifications': 'notifications',
      '/account/user/settings': 'userSettings',
      '/account/user/feedback': 'userFeedback',
      '/account/user/support': 'userSupport',
      '/account/organization/settings': 'orgSettings',
      '/account/organization/cardholders': 'orgCardholders',
      '/account/organization/members': 'orgMembers',
      '/account/organization/teams': 'orgTeams',
      '/account/organization/integrations': 'orgIntegrations',
      '/account/organization/bank_accounts_and_cards': 'orgCards',
      '/account/organization/audit-logs': 'orgAudit',
      '/transactions': 'transactions',
      '/reports/monthly_expenses': 'reports_monthly_expenses',
      '/reports/cash_plan': 'reports_cash_plan',
      '/account/user/data': 'userData',
      '/account/user/api-keys': 'userAPIKeys',
    };

    const highlight = Object.keys(routeHighlights).find(routePattern =>
      new RegExp(`^${routePattern}`).test(route)
    );

    setCurrent(
      highlight ? (typeof routeHighlights[highlight] === 'function' ? routeHighlights[highlight](route) : routeHighlights[highlight]) : 'default_key'
    );
  }, [location.pathname]);
  
  let items = [];
  let cardsSubMenu = cards && cards.length > 0
    ? cards
        .filter(card => card.monitoring_active)
        .map(card => getItem(card.embossed_name, `by_card_${card.id}`, null, `/subscriptions/${card.id}`, null))
    : null;


  if (screens.md) {
    items = [
      getItem("All Recurring", "by_team", <RetweetOutlined />, "/subscriptions", null),
      getItem("By Card", "by_card", <CreditCardOutlined />, "/subscriptions", cardsSubMenu),
      getItem('Notifications', 'notifications', <NotificationOutlined />, '/notifications', null),
      getItem('Reports', 'reports', <PieChartOutlined />, '/reports', [
        getItem("Monthly Expenses", "reports_monthly_expenses", <UnorderedListOutlined />, "/reports/monthly_expenses", null),
        getItem("Cash Plan", "reports_cash_plan", <WalletOutlined />, "/reports/cash_plan", null),
        // getItem("Upcoming Subscriptions", "2", <WalletOutlined />, "/upcoming_payments", null),
        // getItem("Notifications", "3", <NotificationOutlined />, "/notifications"),
      ]),
      getItem('Organization', 'organization', <TeamOutlined />, '/account/organization', [
        getItem('Settings', 'orgSettings', <SettingOutlined />, '/account/organization/settings', null),
        getItem('Members', 'orgMembers', <UserOutlined />, '/account/organization/members', null),
        getItem('Cardholders', 'orgCardholders', <CreditCardOutlined />, '/account/organization/cardholders', null),
        getItem('Teams and Notifications', 'orgTeams', <NotificationOutlined />, '/account/organization/teams', null),
        getItem('Integrations', 'orgIntegrations', <AppstoreOutlined />, '/account/organization/integrations', null),
        getItem("Bank Sync", 'orgCards', <DollarOutlined />, '/account/organization/bank_accounts_and_cards', null),
        // getItem('Billing', 'orgBilling', <DollarOutlined />, '/account/organization/billing', null),
        // getItem('Alerts and Notifications', 'orgAlerts', <NotificationOutlined />, '/account/organization/alerts', null),
        getItem('Audit Logs', 'orgAudit', <AuditOutlined />, '/account/organization/audit-logs', null),
      ]),
      getItem('User', 'user', <UserOutlined />, '/account/user', [
        getItem('Settings', 'userSettings', <SettingOutlined />, '/account/user/settings', null),
        // getItem('API Keys', 'userAPIKeys', <ApiOutlined />, '/account/user/api-keys', null),
        getItem('Manage my data', 'userData', <UserOutlined />, '/account/user/data', null),
        // getItem('Access', 'userAccess', <UserOutlined />, '/account/user/access', null),
      ]),
      getItem('Help', 'help', <InfoCircleOutlined />, '/account/user', [
        getItem('Support', 'userSupport', <QuestionCircleOutlined />, '/account/user/support', null),
        getItem('Feedback', 'userFeedback', <CommentOutlined />, '/account/user/feedback', null),
        getItem('Onboarding', 'userOnboarding', <RedoOutlined />, '/account/organization/integrations/?displayOnboarding=true', null),
      ]),
    ];
  } else {
    items = [
      getItem("All Recurring", "by_team", <RetweetOutlined />, "/subscriptions", null),
      getItem("By Card", "by_card", <CreditCardOutlined />, "/subscriptions", cardsSubMenu),
      getItem('Notifications', 'notifications', <NotificationOutlined />, '/notifications', null),
      getItem('Organization', 'organization', <TeamOutlined />, '/account/organization', [
        getItem('Settings', 'orgSettings', <SettingOutlined />, '/account/organization/settings', null),
        getItem('Members', 'orgMembers', <UserOutlined />, '/account/organization/members', null),
        getItem('Cardholders', 'orgCardholders', <CreditCardOutlined />, '/account/organization/cardholders', null),
        getItem('Integrations', 'orgIntegrations', <AppstoreOutlined />, '/account/organization/integrations', null),
      ]),
      getItem('User', 'user', <UserOutlined />, '/account/user', [
        getItem('Settings', 'userSettings', <SettingOutlined />, '/account/user/settings', null),
        getItem('Manage my data', 'userData', <UserOutlined />, '/account/user/data', null),
      ]),
      getItem('Help', 'help', <InfoCircleOutlined />, '/account/user', [
        getItem('Support', 'userSupport', <QuestionCircleOutlined />, '/account/user/support', null),
        getItem('Feedback', 'userFeedback', <CommentOutlined />, '/account/user/feedback', null),
        getItem('Onboarding', 'userOnboarding', <RedoOutlined />, '/account/organization/integrations/?displayOnboarding=true', null),
      ]),
    ];
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {screens.md ? (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            defaultSelectedKeys={["all_recurring_costs", "organization"]}
            defaultOpenKeys={openKeys}
            openKeys={openKeys}
            mode="inline"
            items={items}
            selectedKeys={[current]}
            onClick={onClick}
            onOpenChange={onOpenChange}
          />
        </Sider>
      ) : (
        <Drawer
          title="Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          bodyStyle={{ padding: 0 }}
        >
          <Menu
            theme="dark"
            mode="inline"
            items={items}
            selectedKeys={[current]}
            onClick={onClick}
            onOpenChange={onOpenChange}
            openKeys={openKeys}
          />
        </Drawer>
      )}  
      <Layout>
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              {!screens.md && (
                <Button
                  type="primary"
                  icon={<MenuOutlined />}
                  onClick={() => setDrawerVisible(true)}
                />
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default LayoutContainer;
