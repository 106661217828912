import React, { useState } from "react";
import { Tooltip, Result, Button, Modal, message } from "antd";
import {
  EditOutlined,
  CloudDownloadOutlined,
  LockOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useAuth } from "./AuthProvider";

function ManageMyData() {
  const { user, currentUser, logout } = useAuth();
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const handleDeleteAccount = () => {
    setDeleteModalVisible(true);
  };
  const handleSignOut = async () => {
    await logout();
    window.location.href = "https://www.subtrack.co.za/";
  };

  const handleConfirmDeleteAccount = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/users/me`,
        {
          headers: {
            "Content-Type": "application/json", // Add Content-Type header
          },
          withCredentials: true,
        }
      );
      message.success("Account deleted successfully");
      await handleSignOut()
      // Redirect to the login page or any other desired page after account deletion
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the account. Please try again later.");
    }
    setDeleteModalVisible(false);
  };

  const handleCancelDeleteAccount = () => {
    setDeleteModalVisible(false);
  };

  const handleDownloadData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_BASE_URL +
          `/api/v1/users/me/download_data`,
        {
          withCredentials: true,
          responseType: "blob", // Set the response type to blob
        }
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "user_data.json");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
      message.error("Failed to download user data. Please try again later.");
    }
  };

  return (
    <>
      <Result
        icon={<LockOutlined />}
        title="Manage My Data"
        subTitle="Take control of your personal information and privacy settings."
        extra={[
          <Button key="download" onClick={handleDownloadData}>
            <Tooltip
              title="Warning: Contains Sensitive Information"
              color="red"
              key="red"
            >
              <CloudDownloadOutlined /> Download My Data
            </Tooltip>
          </Button>,
          <Button key="delete" danger type="text" onClick={handleDeleteAccount}>
            Delete My Account
          </Button>,
        ]}
      />
      <Modal
        title="Confirm Account Deletion"
        visible={isDeleteModalVisible}
        onCancel={handleCancelDeleteAccount}
        footer={[
          <Button key="cancel" onClick={handleCancelDeleteAccount}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            danger
            onClick={handleConfirmDeleteAccount}
          >
            Delete Account
          </Button>,
        ]}
      >
        <p>
          <ExclamationCircleOutlined
            style={{ marginRight: "8px", color: "red" }}
          />
          This action is irreversible and will permanently delete your account
          and associated data.
        </p>
        <p>Are you sure you want to proceed with the account deletion?</p>
      </Modal>
    </>
  );
}

export default ManageMyData;
