import React, { useEffect, useState } from "react";
import { Menu, Button, Dropdown, Avatar, Typography, Drawer } from "antd";
import {
  AppstoreOutlined,
  PieChartOutlined,
  NotificationOutlined,
  UserOutlined,
  SettingOutlined,
  QuestionOutlined,
  CommentOutlined,
  InfoCircleOutlined,
  RedoOutlined,
  MenuOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useAuth } from "../AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import UserMenu from "./UserMenu";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Text } = Typography;

const NavBar = () => {
  const { user, currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const screens = useBreakpoint();
  let [searchParams, setSearchParams] = useSearchParams();
  const [drawerVisible, setDrawerVisible] = useState(false); 
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMobileMenuClick = (e) => {
    setMenuVisible(!menuVisible);
  };

  const handleSignOut = async () => {
    await logout();
    navigate("/sign_in");
  };

  const displayOnboarding = () => {
    setSearchParams({'displayOnboarding': 'true'})
  }

  const handleMenuClick = (e) => {
    switch (e.key) {
      case 'feedback':
        navigate('/account/user/feedback');
        break;
      case 'support':
        navigate('/account/user/support');
        break;
      case 'tour':
        // navigate('/account/user/feedback');
        break;
      // Add cases for other menu items if needed
      default:
        break;
    }
  };

  const helpMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="support" icon={<QuestionOutlined />}>
        Get help with an issue
      </Menu.Item>
      <Menu.Item key="feedback" icon={<CommentOutlined />}>
        Send us feedback
      </Menu.Item>
      <Menu.Item key="onboarding" icon={<RedoOutlined />} onClick={displayOnboarding}>
        Redo Onboarding
      </Menu.Item>
      {/* <Menu.Item key="tour" icon={<InfoCircleOutlined />}>
        Tour
      </Menu.Item> */}
    </Menu>
  );

  return user && currentUser ? (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        {screens.md ? (
          <>
            <Dropdown overlay={helpMenu} placement="bottomLeft" style={{ marginRight: 16 }}>
              <Button
                type="text" // Makes the button appear as text
                style={{
                  color: 'white', // Match the color with your navbar
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  padding: '0 16px', // Add some padding
                  // Add more styles as per your design
                }}
                onClick={e => e.preventDefault()}
              >
                <InfoCircleOutlined />
                Need help?
              </Button>
            </Dropdown>
            <Dropdown
              overlay={
                <UserMenu
                  user={currentUser}
                  handleSignOut={handleSignOut}
                  navigate={navigate}
                />
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "space-between",
                }}
              >
                {currentUser.preferred_photo_url &&
                currentUser.preferred_photo_url.trim() !== "" ? (
                  <Avatar src={currentUser.preferred_photo_url} />
                ) : user.photoURL ? (
                  <Avatar src={user.photoURL} />
                ) : (
                  <Avatar icon={<UserOutlined />} />
                )}
                <Text style={{ color: "white", marginLeft: 8 }}>
                  {currentUser.preferred_name &&
                  currentUser.preferred_name.trim() !== ""
                    ? currentUser.preferred_name
                    : user.displayName || user.email || currentUser.name}
                </Text>
              </div>
            </Dropdown>
          </>
        ) : (
          <Dropdown
            overlay={
              <UserMenu
                user={currentUser}
                handleSignOut={handleSignOut}
                navigate={navigate}
              />
            }
            trigger={['click']}
            visible={menuVisible}
            onVisibleChange={setMenuVisible}
          >
            <Button type="text" onClick={handleMobileMenuClick}>
              <DownOutlined style={{ color: 'white' }}/>
            </Button>
          </Dropdown>
        )}
      </div>
    </>
  ) : (
    <>
      
    </>
  );
};

export default NavBar;
