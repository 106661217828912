import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Empty, Card, List, Input, Button, Typography, Popconfirm, message } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Title } = Typography;

const Notes = ({ subscriptionId }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [editingContent, setEditingContent] = useState('');

  useEffect(() => {
    fetchNotes(subscriptionId);
  }, [subscriptionId]);

  const fetchNotes = async (subscriptionId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/subscriptions/${subscriptionId}/notes`, { withCredentials: true });
      setNotes(response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
    } catch (error) {
      message.error('Failed to fetch notes');
    }
  };

  const addNote = async () => {
    if (!newNote) return;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/subscriptions/${subscriptionId}/notes`, 
        { content: newNote }, 
        { withCredentials: true }
      );
      setNotes([response.data, ...notes]);
      setNewNote('');
      message.success('Note added successfully');
    } catch (error) {
      message.error('Failed to add note');
    }
  };

  const updateNote = async (noteId) => {
    if (!editingContent) return;

    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/subscriptions/${subscriptionId}/notes/${noteId}`, 
        { content: editingContent }, 
        { withCredentials: true }
      );
      setNotes(notes.map(note => note.id === noteId ? response.data : note));
      setEditingNoteId(null);
      setEditingContent('');
      message.success('Note updated successfully');
    } catch (error) {
      message.error('Failed to update note');
    }
  };

  const deleteNote = async (noteId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/v1/subscriptions/${subscriptionId}/notes/${noteId}`, { withCredentials: true });
      setNotes(notes.filter(note => note.id !== noteId));
      message.success('Note deleted successfully');
    } catch (error) {
      message.error('Failed to delete note');
    }
  };

  const formatUserName = (user) => {
    return `${user.preferred_name || user.name}`;
  };

  return (
    <Card>
      {notes.length === 0 ? (
        <Empty description="No notes yet.">
          
        </Empty>
      ) : (
        <>
          <List
            dataSource={notes}
            renderItem={(note) => (
              <List.Item
                actions={[
                  editingNoteId === note.id ? (
                    <>
                      <Button
                        icon={<CheckOutlined />}
                        type="primary"
                        onClick={() => updateNote(note.id)}
                      />
                      <Button
                        icon={<CloseOutlined />}
                        onClick={() => setEditingNoteId(null)}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                          setEditingNoteId(note.id);
                          setEditingContent(note.content);
                        }}
                      />
                      <Popconfirm
                        title="Are you sure to delete this note?"
                        onConfirm={() => deleteNote(note.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </>
                  )
                ]}
              >
                <List.Item.Meta
                  title={editingNoteId === note.id ? (
                    <Input
                      value={editingContent}
                      onChange={(e) => setEditingContent(e.target.value)}
                    />
                  ) : (
                    note.content
                  )}
                  description={`By ${formatUserName(note.user)} on ${moment(note.created_at).format('YYYY-MM-DD HH:mm')}`}
                />
              </List.Item>
            )}
          />
        </>
      )}
      <div style={{ display: 'flex', marginTop: 16 }}>
        <Input
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Add a new note"
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={addNote}
          style={{ marginLeft: 8 }}
        />
      </div>
    </Card>
  );
};

export default Notes;
