import React from "react";
import { Typography } from "antd";
import { Row, Col, theme, Layout } from "antd";
const { Content } = Layout;

const { Title, Paragraph } = Typography;

function PrivacyPolicy() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Content className="site-layout" style={{ padding: "0 50px" }}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={6}></Col>
        <Col className="gutter-row" span={12}></Col>
        <div
          style={{ padding: 24, minHeight: 380, background: colorBgContainer }}
        >
          <div>
            <Title level={2}>Privacy Policy</Title>
          </div>

          <div>
            <Paragraph>
              At Subtrack, we are committed to protecting your privacy and
              ensuring the security of your personal information. This Privacy
              Policy explains how we collect, use, disclose, and safeguard your
              information when you use our Subtrack application. Please read
              this Privacy Policy carefully. By accessing or using our
              application, you agree to be bound by the terms and conditions of
              this Privacy Policy.
            </Paragraph>
          </div>

          <div>
            <Title level={3}>1. Information We Collect</Title>
            <Paragraph>
              We may collect certain personal information from you when you use
              our Subtrack application. This may include:
              <ul>
                <li>Your name</li>
                <li>Your email address</li>
                <li>Any other information you provide voluntarily</li>
              </ul>
            </Paragraph>
          </div>

          <div>
            <Title level={3}>2. Use of Your Information</Title>
            <Paragraph>
              We use the information we collect from you to:
              <ul>
                <li>Provide and maintain our Subtrack application</li>
                <li>Personalize your experience</li>
                <li>Respond to your inquiries, comments, or feedback</li>
                <li>Improve our application and services</li>
                <li>
                  Send you administrative notifications, technical notices,
                  updates, security alerts, and support and administrative
                  messages
                </li>
                <li>Comply with legal obligations</li>
                <li>Protect against any fraudulent or unauthorized activity</li>
              </ul>
            </Paragraph>
          </div>

          <div>
            <Title level={3}>3. Disclosure of Your Information</Title>
            <Paragraph>
              We may disclose your personal information:
              <ul>
                <li>
                  To comply with applicable laws, regulations, legal processes,
                  or governmental requests
                </li>
                <li>To enforce our Terms of Service or other agreements</li>
                <li>
                  To protect the rights, property, or safety of Subtrack, our
                  users, or others
                </li>
                <li>With your consent</li>
              </ul>
            </Paragraph>
          </div>

          <div>
            <Title level={3}>4. Data Security</Title>
            <Paragraph>
              We implement reasonable security measures to protect the
              confidentiality, integrity, and availability of your personal
              information. However, please note that no method of transmission
              over the internet or electronic storage method is 100% secure.
            </Paragraph>
          </div>

          <div>
            <Title level={3}>5. Third-Party Data Processors</Title>
            <Paragraph>
              We may use third-party data processors to provide and improve our
              services. The following third-party services are currently used:
              <ul>
                <li>
                  Google Cloud Platform: We host our application on Google Cloud
                  Platform, which provides secure and reliable infrastructure.
                </li>
                <li>
                  Render: We use Render as our hosting provider, which helps us
                  deploy and manage our application.
                </li>
                <li>
                  Hubspot: We utilize Hubspot for marketing and customer
                  relationship management purposes.
                </li>
                <li>
                  GoDaddy: Our email services are hosted with GoDaddy.
                </li>
                <li>
                  Segment: Our customer data plaform for managing third party integrations is Segment.
                </li>
                <li>
                  Mixpanel: For user behavioural analytics and improve our services.
                </li>
              </ul>
              Please note that these third-party services may have their own
              privacy policies and terms of service, which we encourage you to
              review.
            </Paragraph>
          </div>

          <div>
            <Title level={3}>6. GDPR Compliance</Title>
            <Paragraph>
              Subtrack offers features to help you exercise your rights under
              the General Data Protection Regulation (GDPR), including the
              ability to download your data and delete your data. If you have
              any requests or inquiries regarding your personal data, please
              contact us using the information provided in the "Contact Us"
              section below.
            </Paragraph>
          </div>

          <div>
            <Title level={3}>6. POPIA Compliance</Title>
            <Paragraph>
              Subtrack offers features to help you exercise your rights under
              the Protection of Personal Information Act (POPIA), including the
              ability to download your data and delete your data. If you have
              any requests or inquiries regarding your personal data, please
              contact us using the information provided in the "Contact Us"
              section below.
            </Paragraph>
          </div>

          <div>
            <Title level={3}>7. Children's Privacy</Title>
            <Paragraph>
              Our Subtrack application is not intended for children under the
              age of 13. We do not knowingly collect personal information from
              children under the age of 13. If you believe we have collected
              personal information from a child under the age of 13, please
              contact us immediately.
            </Paragraph>
          </div>

          <div>
            <Title level={3}>8. Changes to This Privacy Policy</Title>
            <Paragraph>
              We may update our Privacy Policy from time to time. Any changes
              will be posted on this page, and the effective date will be
              indicated at the top of the page. We encourage you to review the
              Privacy Policy periodically for any updates or changes.
            </Paragraph>
          </div>

          <div>
            <Title level={3}>9. Contact Us</Title>
            <Paragraph>
              If you have any questions, concerns, or suggestions regarding this
              Privacy Policy or our privacy practices, please contact us at
              hello@subtrack.co.za.
            </Paragraph>
          </div>

          <div>
            <Paragraph>
              By using the Subtrack application, you acknowledge that you have
              read and understood this Privacy Policy and consent to the
              collection, use, and disclosure of your personal information as
              described herein.
            </Paragraph>
          </div>

          <div>
            <Paragraph>Thank you for using Subtrack!</Paragraph>
          </div>
        </div>
        <Col className="gutter-row" span={6}></Col>
      </Row>
    </Content>
  );
}

export default PrivacyPolicy;
