import React, { useState, useEffect } from "react";
import { Space, Typography } from "antd";
const { Text, Link, Title } = Typography;

const TimeSince = ({ date }) => {
  const [secondsElapsed, setSecondsElapsed] = useState(Math.floor((Date.now() - date) / 1000));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsElapsed(se => se + 1);
    }, 1000);

    return () => clearInterval(intervalId);  // cleanup function to stop the interval when component is unmounted
  }, []);

  const formatTimeElapsed = () => {
    const days = Math.floor(secondsElapsed / 86400);
    const hours = Math.floor(secondsElapsed % 86400 / 3600);
    const minutes = Math.floor(secondsElapsed % 3600 / 60);
    const seconds = Math.floor(secondsElapsed % 60);
    return `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`;
  };

  return (
    <div>
      <Text type="secondary">Time since last payment</Text>
      <Title level={5}>{formatTimeElapsed()}</Title>
    </div>
  );
};

export default TimeSince;
