import React, { useState } from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import SubsDetailInput from './SubsDetailInput';
import SubsDetailNumericInput from './SubsDetailNumericInput';
import SubsDetailDatePicker from './SubsDetailDatePicker';
import { Divider } from 'antd';

const SubsDetailBillingRelatedContent = ({ record, selectedCard }) => {
  const screens = useBreakpoint();
  const [billingFrequency, setBillingFrequency] = useState(record.billing_frequency);
  const [showBillingDay, setShowBillingDay] = useState(['weekly', 'monthly', 'annually'].includes(record.billing_frequency));
  const [numberOfSeats, setNumberOfSeats] = useState(null);
  const [showNumberOfSeats, setShowNumberOfSeats] = useState(record.billing_type === 'seats-based');

  const findLabelByValue = (options, value) => {
    const option = options.find(opt => opt.value === value);
    return option ? option.label : null;
  };

  const billingTypeOptions = [
    { label: 'Usage based', value: 'usage-based' },
    { label: 'Fixed fee', value: 'fixed-fee' },
    { label: 'Seats based', value: 'seats-based' },
    { label: 'Wallet top-up based', value: 'wallet-topup-based' },
  ];

  const weeklyOptions = [
    { label: 'Sunday', value: '0' },
    { label: 'Monday', value: '1' },
    { label: 'Tuesday', value: '2' },
    { label: 'Wednesday', value: '3' },
    { label: 'Thursday', value: '4' },
    { label: 'Friday', value: '5' },
    { label: 'Saturday', value: '6' },
  ];

  const formatDayWithSuffix = (day) => {
    const j = day % 10,
          k = day % 100;
    if (j === 1 && k !== 11) {
      return day + "st";
    }
    if (j === 2 && k !== 12) {
      return day + "nd";
    }
    if (j === 3 && k !== 13) {
      return day + "rd";
    }
    return day + "th";
  };

  const monthlyOptions = Array.from({ length: 31 }, (_, i) => {
    const day = i + 1; // Day of the month
    return {
      label: formatDayWithSuffix(day),
      value: String(day)
    };
  });  

  const annuallyOptions = [
    { label: 'January', value: '1' },
    { label: 'February', value: '2' },
    { label: 'March', value: '3' },
    { label: 'April', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'August', value: '8' },
    { label: 'September', value: '9' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' },
  ];

  const getDayPickerOptions = (frequency) => {
    switch (frequency) {
      case 'weekly':
        return weeklyOptions;
      case 'monthly':
        return monthlyOptions;
      case 'annually':
        return annuallyOptions;
      default:
        return [];
    }
  };

  const billingDayLabel = findLabelByValue(getDayPickerOptions(record.billing_frequency), record.billing_day?.toString());
  const billingTypeLabel = findLabelByValue(billingTypeOptions, record.billing_type);
  const [billingDay, setBillingDay] = useState(billingDayLabel);
  const [billingType, setBillingType] = useState(billingTypeLabel);
  const billingDayInitialValue = findLabelByValue(getDayPickerOptions(billingFrequency), record.billing_day?.toString());

  const renderBillingDay = (newFrequency) => {
    setBillingFrequency(newFrequency);
    setShowBillingDay(['weekly', 'monthly', 'annually'].includes(newFrequency));
  };

  const renderBillingType = (newBillingType) => {
    setBillingType(newBillingType);
    setShowNumberOfSeats(newBillingType === 'seats-based');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
      <div style={{ display: 'flex', flexDirection: screens.md ? 'row' : 'column', gap: '10px' }}>
        <div style={{ flex: 1 }}>
          <SubsDetailInput
            initialValue={record.billing_frequency}
            subscriptionId={record.subscription_id}
            cardId={selectedCard.id}
            fieldName="billing_frequency"
            options={[
              { label: '', value: null, placeholder: 'set the billing frequency' },
              { label: 'Monthly', value: 'monthly' },
              { label: 'Annually', value: 'annually' },
              { label: 'Weekly', value: 'weekly' },
              { label: 'Daily', value: 'daily' },
              { label: 'Quarterly', value: 'quarterly' },
            ]}
            label="Set the billing frequency"
            onChangeArg={(newFrequency) => renderBillingDay(newFrequency)}
          />
        </div>
        { billingFrequency && (
          <div style={{ flex: 1 }}>
            <SubsDetailInput
              initialValue={billingDayInitialValue}
              subscriptionId={record.subscription_id}
              cardId={selectedCard.id}
              fieldName="billing_day"
              options={getDayPickerOptions(billingFrequency)}
              label="Billing Day or Month"
              onChangeArg={(newValue) => {
                const newLabel = findLabelByValue(getDayPickerOptions(billingFrequency), newValue);
                setBillingDay(newLabel);
              }}
            />
          </div>
        )}
      </div>
      <div style={{ display:'flex', flexDirection: screens.md ? 'row' : 'column', gap: '10px' }}>
        <div style={{ flex:1 }}>
          <SubsDetailInput
            initialValue={record.billing_type}
            subscriptionId={record.subscription_id}
            cardId={selectedCard.id}
            fieldName="billing_type"
            options={billingTypeOptions}
            label="Select the billing type"
            onChangeArg={(newBillingType) => renderBillingType(newBillingType)}
          />
        </div>
        {showNumberOfSeats && (
          <div style={{ flex:1 }}>
            <SubsDetailNumericInput
              initialValue={numberOfSeats}
              subscriptionId={record.subscription_id}
              cardId={selectedCard.id}
              fieldName="number_of_seats"
              label="Specify the number of seats"
              onChangeArg={setNumberOfSeats}
            />
          </div>
        )}
        {!showNumberOfSeats && (
          <div style={{ flex:1 }}>
            <SubsDetailNumericInput
              initialValue={record.billing_volume}
              subscriptionId={record.subscription_id}
              cardId={selectedCard.id}
              fieldName="billing_volume"
              label="Specify expected amount"
              onChangeArg={setNumberOfSeats}
            />
          </div>
        )}
      </div>
      <Divider orientation='left' plain>Reminders</Divider>
      <SubsDetailDatePicker
        initialValue={record.renewal_date}
        subscriptionId={record.subscription_id}
        cardId={selectedCard.id}
        fieldName="renewal_date"
        label="Contract Renewal Date"
      />
      <SubsDetailDatePicker
        initialValue={record.reminder_date}
        subscriptionId={record.subscription_id}
        cardId={selectedCard.id}
        fieldName="reminder_date"
        label="Schedule a reminder about this"
      />
    </div>
  );
};

export default SubsDetailBillingRelatedContent;
