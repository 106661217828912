import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  List,
  Typography,
  Input,
  Button,
  message,
  Row,
  Col,
  Form,
  Divider,
} from "antd";
import { useAuth } from "./AuthProvider";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Text, Title } = Typography;

function UserSettings() {
  const screens = useBreakpoint();
  const [error, setError] = useState(null);
  const [newPreferredName, setPreferredName] = useState("");
  const [newEmploymentTitle, setEmploymentTitle] = useState("");
  const [newPreferredPhotoUrl, setPreferredPhotoUrl] = useState("");
  const { user, currentUser, logout } = useAuth();

  const auth = getAuth();
  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    if (currentUser && currentUser.id) {
      setPreferredName(currentUser.name);
      setPreferredPhotoUrl(currentUser.photo_url || "");
      setEmploymentTitle(currentUser.employment_title || "");
    }
  }, [currentUser]);

  const handleUserSettingsChange = () => {
    axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/users/${currentUser.id}`,
        {
          preferred_name: newPreferredName,
          preferred_photo_url: newPreferredPhotoUrl,
          employment_title: newEmploymentTitle,
        },
        { withCredentials: true }
      )
      .then(() => {
        message.success("Details updated successfully");
      })
      .catch(() => {
        message.error("Failed to update user name");
      });
  };

  const handleResetPassword = () => {
    resetPassword(currentUser.email)
      .then(() => {
        message.success("Password reset email sent");
      })
      .catch(() => {
        message.error("Failed to send password reset email");
      });
  };

  if (error) {
    return <>An error occurred: {error}</>;
  }

  return (
    <>
      <div>
        <Title level={2}>User Settings</Title>

        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <Form.Item label="Preferred Name">
                <Input
                  value={newPreferredName}
                  onChange={(e) => setPreferredName(e.target.value)}
                  placeholder="Enter your preferred name"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Preferred Photo URL">
                <Input
                  value={newPreferredPhotoUrl}
                  onChange={(e) => setPreferredPhotoUrl(e.target.value)}
                  placeholder="Enter the URL for your preferred photo"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
              <Form.Item label="Employment Title">
                <Input
                  value={newEmploymentTitle}
                  onChange={(e) => setEmploymentTitle(e.target.value)}
                  placeholder="Enter your employment title"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button type="primary" onClick={handleUserSettingsChange}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default UserSettings;
