import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import { Card, Button, Row, Col, Layout } from "antd";
const { Content } = Layout;

const SignInPage = () => {
  const auth = getAuth();
  const [showSignUp, setShowSignUp] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in
      } else {
        // User is signed out
      }
    });
  }, [auth]);

  return (
    <Content style={{ display: 'flex', flexDirection: 'column' }}>
      <Card>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={24} lg={24}>
            {showSignUp ? <SignUp /> : <SignIn />}
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ textAlign: "center" }}>
          <Col span={24}>
            <Button type="link" onClick={() => setShowSignUp(!showSignUp)}>
              {showSignUp ? "Go to Sign In" : "Go to Sign Up"}
            </Button>
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default SignInPage;
