import React, { useState } from 'react';
import { Form, Input, Button, Rate, Typography, message, Tooltip } from 'antd';
import { useAuth } from "./AuthProvider";
import axios from 'axios';
import { SendOutlined, VideoCameraOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph, Link } = Typography;

const SupportForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const onFinish = async (values) => {
    setLoading(true);

    const payload = {
      rating: 0,
      message: `How can we help today? ${values.help}\nWhat specific problem are you trying to solve? ${values.problem}\nVideo Link (if any): ${values.video || 'N/A'}\nContact: ${user.email}`
    };

    const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/feedbacks', { feedback: payload }, {
      withCredentials: true,
    });
    
    try {
      // Example: await axios.post('your-feedback-api-endpoint', payload);
      // console.log(payload); // For demonstration
      message.success('Support ticket created successfully.');
      form.resetFields();
    } catch (error) {
      message.error('Failed to submit support ticket. Please try again later.');
    }

    setLoading(false);
  };

  return (
    <div style={{ maxWidth: '500px', margin: 'auto', padding: '20px' }}>
      <Title level={3}>Help & Support</Title>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="help"
          label="How can we help today?"
          rules={[{ required: true, message: 'Please input your query!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="problem"
          label="What specific problem are you trying to solve?"
          rules={[{ required: true, message: 'Please describe your issue!' }]}
        >
          <Input.TextArea placeholder="Describe your issue in more detail. The more information you provide the better we can help." />
        </Form.Item>

        <Form.Item
          name="video"
          label={
            <>
              Could you show us with a video? e.g.{" "}
              <Tooltip title="A Loom is a video recording of you showing us what's going on. It helps us a lot and we can resolve your issue faster!">
                <Link href="https://www.loom.com/" target="_blank" rel="noopener noreferrer">
                  Loom
                </Link>
              </Tooltip>{" "}
            </>
          }
        >
          <Input
            addonAfter={
              <Tooltip title="A Loom is a video recording of you showing us what's going on. It helps us a lot and we can resolve your issue faster!">
                <Link href="https://www.loom.com/" target="_blank" rel="noopener noreferrer">
                  <VideoCameraOutlined />
                </Link>
              </Tooltip>
            }
            placeholder="Paste your video link here (optional)" 
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<SendOutlined />} disabled={loading}>
            Submit Ticket
          </Button>
        </Form.Item>
      </Form>
      <Paragraph>
        <Text italic>
          We aim to get back to you within 24 hours.
        </Text>
      </Paragraph>
    </div>
  );
};

export default SupportForm;
