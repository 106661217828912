import React from "react";
import { useNavigate } from "react-router-dom"; 
import { Result, Button } from "antd";
import { SmileOutlined } from "@ant-design/icons";

function InvestecWaitlist() {
  const navigate = useNavigate(); // Initialize the navigate function

  // Handler to navigate to the integrations page
  const goToIntegrations = () => {
    navigate('/account/organization/integrations');
  };

  return (
    <>
      <Result
        icon={<SmileOutlined />}
        title="Hold Tight! Access is on the Way"
        subTitle="We're thrilled to see your interest in connecting your account. We're currently in the process of expanding our access, and we'll be ready to onboard more users soon. We promise to keep you updated on our progress and notify you as soon as access is granted."
        extra={[
          <Button type="primary" key="integrations" style={{ marginTop: '16px' }} onClick={goToIntegrations}>
            View Other Integrations
          </Button>,
        ]}
      />
    </>
  );
}

export default InvestecWaitlist;
