import React, { useState, useCallback } from 'react';
import { DatePicker, message } from 'antd';
import debounce from 'lodash/debounce';
import axios from 'axios';
import moment from 'moment';

const SubsDetailDatePicker = ({ initialValue, subscriptionId, cardId, fieldName, label }) => {
  const [date, setDate] = useState(initialValue ? moment(initialValue) : null);

  const handleSave = async (selectedDate) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards/${cardId}/subscriptions/${subscriptionId}`,
        { [fieldName]: selectedDate ? selectedDate.toISOString() : null },
        { withCredentials: true }
      );
      message.success("Subscription date updated successfully");
    } catch (error) {
      message.error("Error updating subscription date");
    }
  };

  const debouncedSave = useCallback(debounce(handleSave, 1000), []);

  const handleChange = (date) => {
    setDate(date);
    debouncedSave(date);
  };

  return (
    <DatePicker placeholder={label} value={date} onChange={handleChange} />
  );
};

export default SubsDetailDatePicker;
