import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Typography, Row, Col, Table, Tag, Button, Popconfirm, message, Input, Select, Modal, Form, Checkbox, Space, Card, Tooltip, Tour } from "antd";
import { useAuth } from "./AuthProvider";
import MemberAssignmentModal from "./components/MemberAssignmentModal";
import NotificationModal from "./components/NotificationModal";
import axios from "axios";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const { Option } = Select;
const { Title, Text } = Typography;

const ManageTeams = () => {
  const { user, currentUser, logout } = useAuth();
  const [cards, setCards] = useState([]);
  const [members, setMembers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [editableTeamName, setEditableTeamName] = useState("");
  const [editingTeamId, setEditingTeamId] = useState(null);
  const [searchedMember, setSearchedMember] = useState("");
  // const [isMemberAssignmentModalVisible, setMemberAssignmentModalVisible] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [newTeamName, setNewTeamName] = useState("");
  const [notificationModalVisible, setNotificationModalVisible] =
    useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [notificationConfig, setNotificationConfig] = useState({
    channel: "",
    eventTypes: [],
    destination: "",
  });
  const [eventTypeOptions, setEventTypeOptions] = useState([])
  const [showAdminActions, setShowAdminActions] = useState(false)

  // >>TOUR
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([
    // {
    //   title: '',
    //   description: '',
    //   target: () => ref1.current,
    // },
    // {
    //   title: '',
    //   description: '',
    //   target: () => ref2.current,
    // },
    // {
    //   title: '',
    //   description: '',
    //   target: () => ref3.current,
    // },
  ]);
  // <<TOUR

  useEffect(() => {
    if (
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      const membersUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/members`;
      const teamsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`;
      const cardsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards`;

      Promise.all([
        axios.get(membersUrl, { withCredentials: true }),
        axios.get(teamsUrl, { withCredentials: true }),
        axios.get(cardsUrl, { withCredentials: true }),
      ])
        .then(([membersResponse, teamsResponse, cardsResponse]) => {
          setMembers(membersResponse.data.members);
          setTeams(teamsResponse.data.teams);
          setCards(cardsResponse.data.cards);
        })
        .catch((error) => {
          message.error("Failed to fetch data.");
        });

      let opts = [
        { label: "Card Payments", value: "card_payment" },
        { label: "Subscription Alerts", value: "subscription" },
        { label: "Card Alerts", value: "card" },
        { label: "Account Access Alerts", value: "access" },
        { label: "Billing", value: "billing" },
        { label: "Account Configuration Alerts", value: "configuration" },
      ]
      if(currentUser.role == "admin") {
        opts.push({ label: "Non-card related Payments/Incomes (EFTs, debit orders, transfers)", value: "non_card_payment" })
      }
      setEventTypeOptions(opts);
    } else {
      // message.warning('currentUser or organization is not defined.');
    }

    if (
      currentUser &&
      currentUser.role == "admin"
    ) {
      setShowAdminActions(true);
    }

  }, [currentUser]);

  const deleteTeam = (team) => {
    // Delete team via API call, then update local state.
    // Note: Prevent deleting the default team.
    if (team.is_default === true) {
      message.error("You cannot delete the default team.");
      return;
    }

    // Make an API call to delete the team by teamId.
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/${team.id}`, {
        withCredentials: true,
      })
      .then(() => {
        setTeams((prevTeams) =>
          prevTeams.filter((teamItem) => teamItem.id !== team.id)
        );
        message.success(`Team with id ${team.id} deleted.`);
      })
      .catch((error) => {
        message.error("Failed to delete the team.");
      });
  };

  const removeMemberFromTeam = (teamId, memberId) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/${teamId}/remove_member/${memberId}`,
        { withCredentials: true }
      )
      // Update local state to reflect the member removal.
      .then((response) => {
        const updatedMembers = members.map((member) => {
          if (member.id === memberId) {
            return {
              ...member,
              teams: member.teams.filter((team) => team.id !== teamId),
            };
          }
          return member;
        });
        setMembers(updatedMembers);
        message.success(`Member removed from team.`);
      })
      .catch((error) => {
        message.error("Failed to remove member from the team.");
      });
  };

  const handleEditTeamName = (teamId, currentName) => {
    setEditingTeamId(teamId);
    setEditableTeamName(currentName);
  };

  const handleSaveTeamName = (teamId) => {
    // Make an API call to update the team name with editableTeamName.
    axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/${teamId}`,
        { name: editableTeamName },
        { withCredentials: true }
      )
      .then((response) => {
        // Update local state with the new team name.
        setTeams((prevTeams) =>
          prevTeams.map((team) => {
            if (team.id === teamId) {
              return {
                ...team,
                name: editableTeamName,
              };
            }
            return team;
          })
        );
        message.success("Team name updated successfully.");
        setEditingTeamId(null); // Reset the editing team ID.
      })
      .catch((error) => {
        message.error("Failed to update team name.");
      });
  };

  const createNewTeam = () => {
    if (newTeamName && newTeamName.trim() !== '') {
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/organizations/${currentUser.organization.id}/teams`,
          { name: newTeamName.trim() },
          { withCredentials: true }
        )
        .then((response) => {
          // Assuming the response contains an updated list of all teams
          setTeams(response.data.teams);
          message.success("New team created.");
        })
        .catch((error) => {
          message.error("Failed to create new team.");
        });
    } else {
      message.warning("Team name cannot be empty or contain only whitespace.");
    }
  };

  const openNotificationModal = (team) => {
    setSelectedTeam(team);

    const eventTypesForUI = team.notification?.event_types || []

    setNotificationConfig({
      channel: team.notification?.channel || "",
      eventTypes: eventTypesForUI,
      destination: team.notification?.destination || "",
    });
    setNotificationModalVisible(true);
  };

  const saveNotificationConfig = () => {
    const { channel, eventTypes, destination } = notificationConfig;

    const eventTypesForAPI = eventTypes || [];

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/${selectedTeam.id}/configure_notifications`,
        {
          channel,
          event_types: eventTypesForAPI,
          destination,
        },
        { withCredentials: true }
      )
      .then((response) => {
        // Update local state to reflect the saved configuration
        const updatedTeams = teams.map((team) => {
          if (team.id === selectedTeam.id) {
            return {
              ...team,
              notification: {
                channel,
                event_types: eventTypesForAPI,
                destination,
              },
            };
          }
          return team;
        });
        setTeams(updatedTeams);
        message.success("Notification configuration saved successfully.");
        setNotificationModalVisible(false);
      })
      .catch((error) => {
        message.error("Failed to save notification configuration.");
      });
  };

  const assignMemberToTeam = (teamId, memberId) => {
    // Assign a member to a team via API call, then update local state...
  };

  const addMemberToTeam = (memberId, teamId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/teams/${teamId}/add_member`,
        { user_id: memberId },
        { withCredentials: true }
      )
      .then((response) => {
        message.success("Successfully added member to team.");

        setMembers((prevMembers) =>
          prevMembers.map((member) => {
            if (member.id === memberId) {
              return {
                ...member,
                teams: [...member.teams, response.data.team],
              };
            }
            return member;
          })
        );
      })
      .catch((error) => {
        // message.error("Failed to add member to team.");
      });
  };
  
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMemberAssigned = (invitedMember) => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div>
          {editingTeamId === record.id ? (
            <div>
              <Input
                value={editableTeamName}
                onChange={(e) => setEditableTeamName(e.target.value)}
                style={{ width: "100px" }}
                placeholder=""
              />
              <Button
                type="primary"
                onClick={() => handleSaveTeamName(record.id)}
              >
                Save
              </Button>
            </div>
          ) : (
            <div>
              {text}
              {record.is_default ? (
                <Text type="secondary"> (only visible to admins)</Text>
              ) : (
                <>
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => handleEditTeamName(record.id, text)}
                    disabled={record.is_default === true}
                  />
                  <Popconfirm
                    title="Are you sure you want to delete this team?"
                    onConfirm={() => deleteTeam(record)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      disabled={record.is_default === true}
                    />
                  </Popconfirm>
                </>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Members",
      key: "members",
      render: (record) => {
        const isDefaultTeam = record.is_default;

        const teamMembers = members
          ?.filter((member) =>
            member.teams.some((team) => team.id === record.id)
          )
          .map((member) => (
            <Tag
              key={member.id}
              closable={!isDefaultTeam && showAdminActions}
              onClose={() => removeMemberFromTeam(record.id, member.id)}
            >
              {member.name || member.preferred_name || member.email}
            </Tag>
          ));

        const nonTeamMembers = members.filter(
          (member) => !member.teams.some((team) => team.id === record.id)
        );

        return (
          <div>
            {
              record.is_default ? (
                <Text>
                  All{" "}
                  <Link to="/account/organization/members">
                    Members
                  </Link>{" "}
                  on the account{" "}
                </Text>
              ) : (
                <>
                  {teamMembers}
                  <Select
                    size="small"
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Add Member"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(memberId) => addMemberToTeam(memberId, record.id)}
                    disabled={!showAdminActions}
                  >
                    {nonTeamMembers.map((member) => (
                      <Option key={member.id} value={member.id}>
                        {member.name || member.preferred_name || member.email}
                      </Option>
                    ))}
                  </Select>
                </>
              )
            }
            <Button type="primary" size="small" disabled={!showAdminActions} onClick={() => setIsModalVisible(true)}><PlusOutlined /></Button>
            { showAdminActions ? ( 
                <MemberAssignmentModal
                  currentUser={currentUser}
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  onMemberAssigned={handleMemberAssigned}
                />
              ) : (<></>)
            }
          </div>
        );
      },
    },
    {
      title: "Cards",
      key: "cards",
      render: (record) => {
        const teamMembers = members
          ?.filter((member) =>
            member.teams.some((team) => team.id === record.id)
          )
          .map((member) => member);

        let teamCards = [];

        if (teamMembers && teamMembers.length !== 0) {
          teamCards = cards.filter((card) =>
            teamMembers.some((member) => member.id === card.user_id)
          );
        }

        return (
          <div>
            {
              record.is_default ? (
                <div key={"default-"+record.id}>
                  <span style={{ fontFamily: "monospace" }}>
                    <Text>
                      All{" "}
                      <Link to="/account/organization/cardholders">
                        Cards
                      </Link>{" "}
                      on the account{" "}
                    </Text>
                  </span>
                </div>
              ) : teamCards.map((card) => (
                    <div key={card.id}>
                      <span style={{ fontFamily: "monospace" }}>
                        {card.card_number.substring(0, 4)}&nbsp;****&nbsp;****&nbsp;
                        {card.card_number.substring(12)}
                      </span>
                    </div>
                  )
              )
            }
          </div>
        );
      },
    },
    {
      title: "Configure Notifications",
      key: "configureNotifications",
      render: (record) => (
        <>
          {record.notification &&
          record.notification.event_types.length !== 0 ? (
            // Render the notification configuration here
            <div>
              {/* Render notification details based on record.notification */}
              <div>Via: {record.notification.channel}</div>
              <div>
                Subscribed to: {
                  record.notification.event_types
                    .map(code => eventTypeOptions.find(opt => opt.value === code)?.label)
                    .join(", ")
                }
              </div>
            </div>
          ) : (
            // Render a message for teams without notification configuration
            <div></div>
          )}
          <Button
            type="primary"
            size="small"
            icon={<EditOutlined />}
            onClick={() => openNotificationModal(record)}
          >
            Configure
          </Button>
        </>
      ),
    },
  ];

  const handleEditTeam = (teamId) => {
    // Implement the logic to edit the team here.
    // You can navigate to a separate edit page or show a modal for editing.
  };
  
  return (
    <>
      <div>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          <Col flex="auto">
            <Title level={2}>Manage Teams, alerts and what people can access</Title>
          </Col>
          {/* <Col>
            <Button 
              type="text" 
              onClick={() => setOpen(true)} 
              style={{ zIndex: 1000 }}
            >
              <em>Learn how to use this page</em>
            </Button>
          </Col> */}
        </Row>
      </div>
      <div>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          <Col flex="auto">
            <Text>
              Create teams for various departments to manage
              alerts, and access. 
              Users can only see card and transaction information tied to their team.
            </Text>
          </Col>
          <Col>
            { 
              showAdminActions ? (
                <div style={{ display: "flex", flexWrap: "nowrap", marginBottom: "20px" }}>
                  <Input
                    style={{ flex: "auto", marginRight: 8 }}
                    value={newTeamName}
                    onChange={(e) => setNewTeamName(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        createNewTeam();
                        e.preventDefault();
                      }
                    }}
                    placeholder="New Team Name"
                  />
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={createNewTeam}
                  >
                    Add
                  </Button>
                </div>
              ) : (
                <></>
              )
            }
          </Col>
        </Row>
      </div>
      
      <Table dataSource={teams} columns={columns} rowKey="id" />
      <NotificationModal
        visible={notificationModalVisible}
        onCancel={() => setNotificationModalVisible(false)}
        saveNotificationConfig={saveNotificationConfig}
        notificationConfig={notificationConfig}
        setNotificationConfig={setNotificationConfig}
        eventTypeOptions={eventTypeOptions}
      >

      </NotificationModal>
      <Tour
        open={open}
        onClose={() => setOpen(false)}
        steps={steps}
      />
    </>
  );
};

export default ManageTeams;
