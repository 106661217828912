import React, { useEffect, useState, useRef } from "react";
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Layout, Tag, message } from "antd";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Link,
  useSearchParams,
} from "react-router-dom";
import {
  AppstoreOutlined,
  PieChartOutlined,
  UserOutlined,
  NotificationOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { AuthProvider, useAuth } from "./AuthProvider";
import UserMenu from './components/UserMenu'; 
import Dashboard from "./Dashboard";
import SignInPage from "./SignInPage";
import SignUpPage from "./SignUpPage";
import AuthActions from "./AuthActions";
import Credentials from "./Credentials";
import Notifications from "./Notifications";
import OrgSetup from "./OrgSetup";
import Subscriptions from "./Subscriptions";
import AllSubscriptions from "./AllSubscriptions";
import CashPlan from "./CashPlan"
import Cards from "./Cards";
import Transactions from "./Transactions";
import ManageMyData from "./ManageMyData";
import ManageApiKeys from "./ManageApiKeys";
import PrivacyPolicy from "./PrivacyPolicy";
import Overview from "./Overview";
import { Dropdown, Menu, Avatar, Button } from "antd";
import { Typography, Space, List } from "antd";
import NavBar from './components/NavBar';
import AccountOrganizationMembers from "./AccountOrganizationMembers"
import UserSettings from "./UserSettings";
import AuditLogs from "./AuditLogs";
import { Modal } from 'antd';
import OnboardingWizard from "./OnboardingWizard";
import FeedbackForm from "./FeedbackForm";
import SupportForm from "./SupportForm";
import NotificationSettings from "./NotificationSettings";
import ManageCards from "./ManageCards";
import ManageTeams from "./ManageTeams";
import logo from './logo-full3.jpg'
import EmailVerificationModal from './components/EmailVerificationModal';
import InvestecWaitlist from "./InvestecWaitlist";
import LayoutContainer from './LayoutContainer';
import axios from "axios";
import VerifyEmailPage from "./VerifyEmailPage";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: 'production',
};

const { Text } = Typography;
const { Title } = Typography;
const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    <Router>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <AuthProvider>
            <Layout>
              <Header style={{ display: "flex", alignItems: "center" }}>
                <Link to="/subscriptions">
                  <img src={logo} alt="Logo" style={{ width: "auto", height: "35px", display: "block"}} />
                </Link>
                <Tag color="red">beta</Tag>
                <div style={{ flexGrow: 1 }} />
                <NavBar />
              </Header>
              <Layout>
                <Content>
                  <RoutesContainer />
                </Content>
              </Layout>
              <Footer style={{ textAlign: "center", color: "grey" }}>
                Subscription Tracker (Pty) Ltd © 2024
              </Footer>
            </Layout>
          </AuthProvider>
        </ErrorBoundary>
      </Provider>
    </Router>
  );
};

const UnauthedRoutesContainer = () => {
  return (
    <>
      <Routes>
        <Route path="/sign_up" element={<SignUpPage />} />
        <Route path="/sign_in" element={<SignInPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/account/user/manage_access" element={<AuthActions />} />
      </Routes>
    </>
  )
}

const UnauthedRoutes = ['/sign_up', '/sign_in', '/privacy', '/account/user/manage_access'];

const RoutesContainer = () => {
  const { isAuthenticated, currentUser, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [cards, setCards] = useState([]);
  const [emailVerificationVisible, setEmailVerificationVisible] = useState(false);
  const [displayOnboardingWizard, setDisplayOnboardingWizard] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleToggleOnboardingWizard = () => {
    setDisplayOnboardingWizard(!displayOnboardingWizard);
  };

  useEffect(() => {
    if (searchParams.get('displayOnboarding') === 'true') {
      setDisplayOnboardingWizard(true);
      searchParams.delete('displayOnboarding');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  React.useEffect(() => {
    const pathIsUnauthedRoute = UnauthedRoutes.includes(location.pathname);
    if (location.pathname === "/account/user/manage_access") {
      return;
    }
    
    if (isAuthenticated) {

      if (currentUser && !user.emailVerified) {
        // if (location.pathname === '/subscriptions') {
          // Render verification modal instead of redirect
          setEmailVerificationVisible(true);
          return;
        // }
      } else {
        if (location.pathname === "/sign_in") {
          const redirectPath = localStorage.getItem("preAuthPath") || "/subscriptions";
          console.log("currently on route: " + location.pathname)
          console.log("redirecting to route: " + redirectPath)
          localStorage.removeItem("preAuthPath");
          if ( redirectPath === "/sign_in") {
            navigate("/subscriptions");
          } else {
            navigate(redirectPath);
          }
        }
      }
    } else {
      if (!pathIsUnauthedRoute && location.pathname !== "/") {
        localStorage.setItem("preAuthPath", location.pathname);
        navigate("/sign_in");
      } else if (location.pathname === "/") {
        navigate("/sign_in");
      }
    }
  }, [isAuthenticated, navigate, location.pathname]);

  React.useEffect(() => {
    if (
      currentUser &&
      currentUser.organization &&
      currentUser.organization.id
    ) {
      const cardsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/cards`;

      Promise.all([
        axios.get(cardsUrl, { withCredentials: true }),
      ])
        .then(([cardsResponse]) => {
          setCards(cardsResponse.data.cards);
        })
        .catch((error) => {
          message.error("Failed to fetch data.");
        });
    } else {
      // message.warning('currentUser or organization is not defined.');
    }
  }, [currentUser]);

  return (
    <>
      {
        isAuthenticated && location.pathname !== '/account/user/manage_access' ? (
          user && !user.emailVerified ? (
            <Routes>
              <Route path="/verify-email" element={<VerifyEmailPage />} />
            </Routes>
          ) : currentUser && (
            <>
              <EmailVerificationModal visible={emailVerificationVisible}/>

              <OnboardingWizard visible={((currentUser && currentUser.organization.state === 'onboarding') || displayOnboardingWizard)} onClose={handleToggleOnboardingWizard} />
              
              <LayoutContainer cards={cards}>
                <Routes>
                  {/* <Route path="/sign_up" element={<SignUpPage />} />
                  <Route path="/sign_in" element={<SignInPage />} /> */}
                  {/* <Route path="/privacy" element={<PrivacyPolicy />} /> */}
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/account/organization/integrations" element={<Credentials />} />
                  <Route path="/account/organization/integrations/investec/waitlist" element={<InvestecWaitlist />} />
                  <Route path="/notifications" element={<Notifications />} />
                  {/* <Route path="/account/organization/alerts" element={<NotificationSettings />} /> */}
                  {/* the investec bank accounts integrated */}
                  <Route path="/account/organization/settings" element={<OrgSetup />} /> 
                  <Route path="/account/organization/audit-logs" element={<AuditLogs />} /> 
                  {/* <Route path="/subscriptions" element={<Subscriptions hideTransactions hideSearch hideSuggestions hideCardNameColumn={false}/>} /> */}
                  <Route path="/subscriptions" element={<AllSubscriptions hideTransactions hideSearch hideSuggestions hideCardNameColumn={false}/>} />
                  <Route path="/subscriptions/:card_id" element={<Subscriptions />} />
                  <Route path="/account/organization/bank_accounts_and_cards" element={<Cards />} />
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/account/user/settings" element={<UserSettings />} />
                  <Route path="/account/user/data" element={<ManageMyData />} />
                  <Route path="/account/user/api-keys" element={<ManageApiKeys />} />
                  <Route path="/account/user/notifications" element={<Notifications />} />
                  <Route path="/account/user/feedback" element={<FeedbackForm />} />
                  <Route path="/account/user/support" element={<SupportForm />} />
                  <Route path="/reports/monthly_expenses" element={<Overview />} />
                  <Route path="/reports/cash_plan" element={<CashPlan />} />
                  
                  {/* your subtrack account */}
                  <Route path="/account/organization/members" element={<AccountOrganizationMembers />} />
                  <Route path="/account/organization/cardholders" element={<ManageCards />} />
                  <Route path="/account/organization/teams" element={<ManageTeams />} />
                  <Route path="/privacy" element={<PrivacyPolicy />} />
                  <Route path="/account/user/manage_access" element={<AuthActions />} />
                </Routes>
              </LayoutContainer>
            </> 
          )
        ) : (
          <UnauthedRoutesContainer />
        )
      }
    </>
  );
};

export default App;
