import React, { useEffect, useState } from "react";
import { Modal, Button, Radio, List, message, Divider } from "antd";
import { useAuth } from "./AuthProvider";
import OrgSettingsForm from "./components/OrgSetup/OrgSettingsForm"; // Import the form component
import InviteForm from "./components/OrgSetup/InviteForm"; // Import the form component
import CredentialsForm from "./components/OrgSetup/CredentialsForm"; // Your existing Credentials component
import { updateOrgName } from "./services/api"; // Import the API service
import { Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const { Text, Title } = Typography;

const OnboardingWizard = ({visible = false, onClose}) => {
  const [newOrgName, setNewOrgName] = useState(null);
  const [newOrgDomain, setNewOrgDomain] = useState("");
  const [mainAccountHolder, setMainAccountHolder] = useState(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { isAuthenticated, currentUser, user, setStaticUserData } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(visible);

  const handleNavigation = (path) => {
    onClose();
    navigate(path);
  };

  const handleOrgNameChange = async () => {
    try {
      const { success } = await updateOrgName(
        currentUser.organization.id,
        newOrgName,
        newOrgDomain
      );
      if (success) {
        setStaticUserData(prevState => ({
          ...prevState,
          organization: {
            ...prevState.organization,
            name: newOrgName,
            domain: newOrgDomain
          }
        }));
        nextStep();
      } else {
        // Handle failure case, maybe show a message to the user
      }
    } catch (error) {
      console.error('Error updating organization name:', error);
      // Optionally show an error message to the user
    }
  };
  
  useEffect(() => {
    if (currentStep === 0 && currentUser?.invites?.length === 0) {
      setCurrentStep(1);
    }
  }, [currentStep, currentUser]);

  useEffect(() => {
    if (currentUser?.organization?.id) {
      setNewOrgName(currentUser.organization.name || "Private");
    }
  }, [currentUser]);

  useEffect(() => {
    setIsModalVisible(visible);
  }, [visible]);

  const closeModal = () => {
    // Reset state variables and close modal
    setCurrentStep(0);
    onClose();
    // Add other resets here if needed
  };

  const handleAcceptInvite = (token) => {
    // console.log("Accepting invite with token:", token);
    
    axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/invites/accept_invite?token=${token}`,
        {},
        { withCredentials: true }
    ).then((response) => {
        // console.log(response.data.message);
        window.location.reload();
    }).catch((error) => {
        console.error("Failed to accept invite:", error.response ? error.response.data.error : error.message);
        message.error("Failed to join organisation. Invitation either invalid or expired.");
    });
  };


  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  return (
    <div>
      {((currentUser?.organization?.state === "onboarding" ) || isModalVisible) && currentStep <= 5  && (
        <Modal
          open={true}
          // onCancel={() => {setCurrentStep(6)}}
          onCancel={closeModal}
          title={
            [
              "Pending Invitations",
              "Welcome to Subtrack",
              "Step 1: Enter Organization Name",
              "Step 2: Invite your teammates",
              "Step 3: Connect to Investec Private Banking",
              "Thank you for setting up your account",
            ][currentStep]
          }
          footer={null}
        >
          {currentStep === 0 && currentUser?.invites?.length > 0 && (
            <>
              <List
                dataSource={currentUser.invites}
                renderItem={(invite) => (
                  <List.Item>
                    <Text>{invite.organization_name}</Text>
                    <Button
                      type="primary"
                      onClick={() => handleAcceptInvite(invite.token)}
                    >
                      Accept
                    </Button>
                  </List.Item>
                )}
              />
              <>
                <Text italic>*By accepting, you confirm that you are in fact the intended recipient.&nbsp;</Text>
                <Text>You can only be part of one organization at a time.</Text>
              </>
              <p></p>
              
              <Button type="primary" onClick={() => setCurrentStep(1)}>
                Skip
              </Button>
            </>
          )}
          {currentStep === 1 && currentUser.role == "admin" && (
            <div>
            <Text>To get started, we'll need to:</Text>
            <ol>
              <li>Configure your company name.</li>
              <li>Invite the user who can set up the data integration with Investec (main Account Holder).</li>
              <li>Connect the Investec integration so that you can start managing your subscriptions</li>
            </ol>
            <Button type="primary" onClick={() => setCurrentStep(2)}>
                Get started
              </Button>
          </div>
          )}
          {currentStep === 2 && currentUser.role == "admin" && (
            <>
              <OrgSettingsForm
                orgName={newOrgName}
                newOrgName={newOrgName}
                setNewOrgName={setNewOrgName}
                orgDomain={currentUser.organization.domain}
                newOrgDomain={newOrgDomain}
                setNewOrgDomain={setNewOrgDomain}
                handleOrgNameAndDomainChange={() => {
                  handleOrgNameChange();
                  setCurrentStep(2);
                }}
              />
              <Button type="secondary" onClick={() => setCurrentStep(1)}>
                Back
              </Button>
            </>
          )}
          {currentStep === 3 && currentUser.role == "admin" && (
            <>
              <p>
              To connect to Investec and start making use of Subtrack, the main Investec Account Holder needs to Authorize your account. The steps that follow will help guide you through getting connected.
              </p>
              <p>Are you the main Investec Account holder?</p>
              <Radio.Group
                onChange={(e) => setMainAccountHolder(e.target.value)}
                value={mainAccountHolder}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>

              {mainAccountHolder && (
                <div>
                  <p>
                    As the main Investec Account Holder for your company, you'll
                    need to authorize Subtrack to interact with your account.
                    This will be configured on the next page.
                  </p>
                </div>
              )}

              {mainAccountHolder === false && (
                <div>
                  <p>
                    The main Investec account holder for your business has access to link the
                    account details. Please invite them here:
                  </p>
                  <InviteForm />
                </div>
              )}
              <p></p>
              <Button type="primary" onClick={() => setCurrentStep(4)}>
                Next
              </Button>
              <Button type="secondary" onClick={() => setCurrentStep(2)}>
                Back
              </Button>
            </>
          )}
          {currentStep === 4 && currentUser.role == "admin" && (
            <>
              <CredentialsForm userId={currentUser.rotating_identifier} />
              <p></p>
              <Button type="primary" onClick={() => setCurrentStep(5)}>
                Finish
              </Button>
              <Button type="secondary" onClick={() => setCurrentStep(3)}>
                Back
              </Button>
            </>
          )}
          {currentStep === 5 && currentUser.role == "admin" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px"
              }}
            >
              <CheckCircleOutlined
                style={{
                  color: "green",
                  fontSize: "2rem",
                  marginBottom: "1rem",
                }}
              />
              <Title level={4} style={{ textAlign: "center" }}>
                Thank you for setting up your account
              </Title>
              <Text style={{ textAlign: "center", marginBottom: "1rem" }}>
                Your data should be streaming in shortly. Please allow a few minutes.
              </Text>
              <Divider />
              <Text style={{ textAlign: "center", marginBottom: "1rem", fontSize: "1rem" }}>
                What's next?
              </Text>
              <Button 
                type="primary" 
                style={{ marginBottom: '1rem', width: '80%', height: '40px', fontSize: '1rem' }} 
                onClick={() => { setCurrentStep(0); handleNavigation('/account/organization/integrations')}}
              >
                Go to Integrations
              </Button>
              <Text style={{ textAlign: "center", marginBottom: "1rem", fontSize: "1rem" }}>
                If you have configured the Slack integration, inviting team members is easier.
              </Text>
              <Button 
                type="default" 
                style={{ width: '80%', height: '40px', fontSize: '1rem' }} 
                onClick={() => {  setCurrentStep(0); handleNavigation('/account/organization/members') } }
              >
                Invite Team Members
              </Button>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default OnboardingWizard;
