import React, { useState, useEffect } from "react";
import { Checkbox, Card, Button, Form, Divider, Input, Row, Col, message, Modal } from "antd";
import { LockOutlined, UserOutlined, GoogleOutlined, WindowsOutlined } from "@ant-design/icons";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  OAuthProvider,
  sendPasswordResetEmail
} from "firebase/auth";
import { useNavigate } from 'react-router-dom';

function formatErrorCode(code) {
  let humanReadableMessage;
  switch(code) {
    case 'auth/wrong-password':
      humanReadableMessage = 'Incorrect credentials. Did you mean to Sign Up?';
      break;
    case 'auth/web-storage-unsupported':
      humanReadableMessage = 'You cannot authenticate in Private Browsing or Incognito mode.';
      break;
    case 'auth/account-exists-with-different-credential':
      humanReadableMessage = 'Please use the provider that you originally signed up with.';
      break;
    case 'auth/too-many-requests':
      humanReadableMessage = 'Too many failed login attempts. Please try again later or reset your password.';
      break;
    case 'auth/invalid-login-credentials':
      humanReadableMessage = 'Incorrect credentials. Did you mean to Sign Up?';
      break;
    default:
      humanReadableMessage = 'An unknown error occurred. Please try again.';
  }

  return humanReadableMessage;
}

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const [showSignUp, setShowSignUp] = useState(false);
  const [showEmailPasswordFields, setShowEmailPasswordFields] = useState(false);
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // This function checks if there's a redirect result available
    // when the component mounts. This would occur if a user is coming back
    // from the Google sign-in page.
    const checkRedirectResult = async () => {
      setLoading(true);
      try {
        const result = await getRedirectResult(auth);
        if (result && result.user) {
          // console.log('Signing in with:' , result.user.providerData[0].providerId);
          message.success("Signing in with " + result.user.providerData[0].providerId);
          navigate('/subscriptions');
        }
      } catch (error) {
        message.error(formatErrorCode(error.code));
      }
      setLoading(false);
    };

    checkRedirectResult();
  }, [auth]);

  const onFinish = async (values) => {
    setLoading(true);
    const { email, password } = values;
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/subscriptions');
    } catch (error) {
      message.error(formatErrorCode(error.code));
    }
    setLoading(false);
  };

  const handleForgotPassword = () => {
    setForgotPasswordEmail(email); // Set the email from login form
    setIsForgotPasswordModalVisible(true); // Open the modal
  };

  const resetForgottenPassword = async() => {
    try {
      await sendPasswordResetEmail(auth, forgotPasswordEmail);
      message.success("Password reset email sent. Check your inbox!");
      setIsForgotPasswordModalVisible(false); // Optionally close the modal on success
    } catch (error) {
      message.error("Failed to send password reset email: " + error.message);
    }
  };

  const handleOpenForgotPasswordModal = () => {
    setIsForgotPasswordModalVisible(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setIsForgotPasswordModalVisible(false);
  };

  const handleForgotPasswordInputChange = (e) => {
    setForgotPasswordEmail(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value); // Update the email state on change
  };
  

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithRedirect(auth, provider);
      // user signed in with Google
    } catch (error) {
      // handle error
      console.log(error.message);
    }
  };

  const handleMicrosoftSignUp = async () => {
    const provider = new OAuthProvider('microsoft.com');

    
    try {
      await signInWithRedirect(auth, provider);

    } catch (error) {
      console.log("Error with Microsoft sign up:", error.message);
    }
  };

  const handleSignInWithEmailClick = () => {
    setShowEmailPasswordFields(true);
  };

  return (
    <Row 
      justify="center" // This ensures the form is centered
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      style={{ marginTop: '5%', marginBottom: '5%' }} // Optional: Add some margin to the top and bottom for better layout
    >
      <Col 
        xs={{ span: 22 }}  // On extra small screens, it takes up almost the full width
        sm={{ span: 20 }}  // On small screens, it takes up most of the width
        md={{ span: 18 }}  // On medium screens, slightly less
        lg={{ span: 12 }}  // On large screens, half the width
        xl={{ span: 8 }}   // On extra-large screens, reduce further
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ maxWidth: '500px', margin: '0 auto' }} // Optional: Limit max width for very large screens and center it
        >
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Button 
                // type={showEmailPasswordFields ? "default" : "primary" }
                type="default"
                onClick={handleGoogleSignIn}
                className="login-form-button"
                loading={loading}
                style={{ width: '100%', marginBottom: '10px' }}
              >
                <GoogleOutlined /> Sign In with Google
              </Button>
            </Col>
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Button 
                type="default"
                onClick={handleMicrosoftSignUp} 
                className="login-form-button" 
                loading={loading}
                style={{ width: '100%', marginBottom: '10px' }}
              >
                <WindowsOutlined />Sign In with Microsoft
              </Button>
            </Col>
          </Row>
          {/* <Divider orientation="centre" plain>or</Divider>
          {showEmailPasswordFields && (
            <Card>
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Please input your email!" }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  onChange={handleEmailChange}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ offset: 0, span: 24 }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: '100%', marginBottom: '10px' }}
                >
                  Sign In
                </Button>
              </Form.Item>
              <Button type="link" onClick={handleForgotPassword} >Forgot Password?</Button>
            </Card>
          )} */}
          
          {/* {!showEmailPasswordFields && (
            <Button
              onClick={handleSignInWithEmailClick}
              style={{ width: '100%', marginBottom: '10px' }}
            >
              Sign In with Email
            </Button>
          )} */}
        </Form>
      </Col>
      <Modal
        title="Forgot Password"
        visible={isForgotPasswordModalVisible}
        onCancel={handleCloseForgotPasswordModal}
        footer={[
          <Button key="cancel" onClick={handleCloseForgotPasswordModal}>Cancel</Button>,
          <Button key="submit" type="primary" onClick={resetForgottenPassword}>Reset Password</Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Email">
            <Input value={forgotPasswordEmail} onChange={handleForgotPasswordInputChange} />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default SignIn;
